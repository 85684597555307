import React, { useState, useEffect } from "react"
import "./Home.scss"
import { useNavigate, useLocation } from "react-router-dom"
import HomeNav from "../HomeNav/HomeNav";
import Header from "../Header/Header";
import AllEvents from "./AllEvents/AllEvents";
import ManagedEvents from "./ManagedEvents/ManagedEvents";
import ReferredEvents from "./ReferredEvents/ReferredEvents";
import Privacy from "./Privacy/Privacy";
import AvailableSoon from "../AvailableSoon/AvailableSoon";
import ContactUS from "./ContactUs/ContactUs";
import Storage from "../Storage/Storage";
import Profile from "./Profile/Profile";
import ReferralId from "./ReferralId/ReferralId";
import Dashboard from './Dashboard/Dashboard'

const Home = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [pageIndex, setPageIndex] = useState(1)
  const [mainData, setMainData] = useState({})

  const pageKeys = {
    1: "Dashboard",
    2: "All Events",
    4: "Referred Events",
    5: "Referrals",
    6: "Profile",
    7: "Privacy",
    9: "Contact Us",
    10: "Storage",
  }

  useEffect(() => {
    const currentPath = location.pathname
    switch (currentPath) {
      case "/home/":
      case "/home":
        setPageIndex(1)
        break

      case "/home/dashboard":
        setPageIndex(1)
        break

      case "/home/all":
        setPageIndex(2)
        break

      case "/home/referred":
        setPageIndex(4)
        break

      case "/home/newref":
        setPageIndex(5)
        break

      case "/home/profile":
        setPageIndex(6)
        break

      case "/home/contact":
        setPageIndex(9)
        break

      case "/home/privacy":
        setPageIndex(7)
        break

      case "/home/storage":
        setPageIndex(10)
        break

      default:
        break
    }
  }, [location.pathname])

  useEffect(() => {
    const storedObjectString = localStorage.getItem("key")
    localStorage.removeItem("manageId")
    if (storedObjectString) {
      const storedObject = JSON.parse(storedObjectString)
      setMainData(storedObject.body)
    }
  }, [])

  const pageName = (data) => {
    setPageIndex(data)
  }

  return (
    <div className="box d-flex">
      <div className="navBar">
        <HomeNav pageInfo={pageName} />
      </div>
      <div className="header-box">
        <Header
          page={pageKeys[pageIndex]}
          userName={mainData.firstName}
          profilePhotoUrl={mainData.profilePhotoUrl}
        />
        {pageIndex === 1 && <Dashboard />}
        {pageIndex === 2 && <AllEvents />}
        {pageIndex === 4 && <ReferredEvents />}
        {pageIndex === 5 && <ReferralId />}
        {pageIndex === 6 && <Profile />}
        {pageIndex === 9 && <ContactUS />}
        {pageIndex === 7 && <Privacy />}
        {pageIndex === 10 && <Storage />}
      </div>
    </div>
  )
}

export default Home
