import Modal from "@mui/material/Modal"
import Box from "@mui/material/Box"
import React, { useState } from "react"
import { handleCopyClick } from "../../../utils/clipboard"
import "../ShareModal/ShareModel.scss"
import copy from "../../../assets/New/copy.svg"
import { getSubscriptionEventConfig, setPincode } from "../../../api/api"
import { toast } from "sonner"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "30%",
  bgcolor: "#1A1C21",
  border: "2px solid #000",
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
  color: "#fff",
  overflowY: "scroll",
}

const PincodeModal = ({
  modalOpen,
  handleClose,
  pincode,
  setConfigSubscription,
}) => {
  const [newpin, setNewpin] = useState("")

  const submitNewpin = async () => {
    const eventId = localStorage.getItem("eventId")
    const res = await setPincode({ eventId, pincode: newpin })
    if (res?.status === "OK") {
      toast.success("Pin Code set Successfully")
      handleClose()
      const res2 = await getSubscriptionEventConfig(eventId)
      if (res2?.status === "OK") {
        setConfigSubscription(res2?.body)
      }
    }
  }

  return (
    <div className="box d-flex" style={{ fontFamily: "Inter" }}>
      <Modal
        open={modalOpen}
        onClose={() => {
          handleClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex justify-content-center">
            <span className="title">Pin Code</span>
          </div>
          {pincode && pincode !== "" ? (
            <>
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "20px",
                    marginBottom: "5px",
                  }}
                >
                  Current Pin Code
                </p>
                <div style={{ position: "relative" }}>
                  <input
                    value={pincode}
                    disabled
                    type="text"
                    style={{
                      outline: "none",
                      background: "transparent",
                      border: "1px solid #706F6C",
                      borderRadius: "12px",
                      padding: "10px 16px",
                      color: "white",
                      fontSize: "16px",
                      width: "100%",
                    }}
                    max={4}
                    maxLength={4}
                  />
                  <img
                    src={copy}
                    alt=""
                    style={{
                      position: "absolute",
                      right: "7px",
                      top: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      handleCopyClick(pincode)
                      toast.success("Pin Code Copied")
                    }}
                  />
                </div>
              </div>
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "10px",
                    marginBottom: "5px",
                  }}
                >
                  New Pin Code
                </p>
                <input
                  type="text"
                  style={{
                    outline: "none",
                    background: "transparent",
                    border: "1px solid #706F6C",
                    borderRadius: "12px",
                    width: "100%",
                    padding: "10px 16px",
                    color: "white",
                    fontSize: "16px",
                  }}
                  onChange={(e) => setNewpin(e.target.value)}
                  value={newpin}
                  max={4}
                  maxLength={4}
                />
                <p
                  style={{
                    textAlign: "end",
                    fontSize: "14px",
                    marginTop: "4px",
                    marginRight: "3px",
                  }}
                >
                  {newpin?.length}/4
                </p>
              </div>
            </>
          ) : (
            <>
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    marginTop: "20px",
                    marginBottom: "5px",
                  }}
                >
                  Set New Pin
                </p>
                <input
                  type="text"
                  style={{
                    outline: "none",
                    background: "transparent",
                    border: "1px solid #706F6C",
                    borderRadius: "12px",
                    padding: "10px 16px",
                    width: "100%",
                    color: "white",
                    fontSize: "16px",
                  }}
                  onChange={(e) => setNewpin(e.target.value)}
                  value={newpin}
                  max={4}
                  maxLength={4}
                />
                <p
                  style={{
                    textAlign: "end",
                    fontSize: "14px",
                    marginTop: "4px",
                    marginRight: "3px",
                  }}
                >
                  {newpin?.length}/4
                </p>
              </div>
            </>
          )}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
              gap: "25px",
            }}
          >
            <button
              className={"create-album d-flex justify-content-center"}
              style={{ background: "transparent", width: "100%" }}
              onClick={() => handleClose()}
            >
              Cancel
            </button>
            <button
              className="create-event-btn"
              style={{ padding: "10px 35px", width: "100%" }}
              onClick={submitNewpin}
            >
              Save
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default PincodeModal
