import React, { useEffect, useState } from "react";
import backbtn from "../../../../assets/CreateEvent/Vector.svg";
import "./VendorDetails.scss";
import edit from "../../../../assets/Icons/PencilAlt.svg";
import ExtraDetails from "./ExtraDetails";
import { createMobileEvent, uploadCoverPhoto } from "../../../../api/api";
import { useNavigate } from "react-router-dom";
import Loader from "../../../Loader/Loader";

const VendorDetails = ({ prevBtn }) => {
  const navigate = useNavigate();
  const [allowMainTitleChange, setAllowMainTitleChange] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  const [mainDetails, setMainDetails] = useState({
    category: "Photography Company (Default)",
  });

  const [extraDetails, setExtraDetails] = useState([
    { key: 1, category: "Other Vendor Details" },
  ]);

  const [addExtraDetails, setAddExtraDetails] = useState(false);

  const handleInput = (e) => {
    setMainDetails({ ...mainDetails, [e.target.id]: e.target.value });
  };

  const handleExtraInput = (data, e) => {
    setExtraDetails((prev) =>
      prev?.map((p) =>
        p?.key === data?.key ? { ...p, [e.target.id]: e.target.value } : p
      )
    );
  };

  const retrieveFileAsFormData = (eventDetailsData) => {
    if (eventDetailsData && eventDetailsData.file) {
      const base64File = eventDetailsData.file;
      const byteString = atob(base64File.split(",")[1]); // Decode Base64 string
      const mimeString = base64File.split(",")[0].split(":")[1].split(";")[0]; // Extract MIME type

      const byteNumbers = new Array(byteString.length);
      for (let i = 0; i < byteString.length; i++) {
        byteNumbers[i] = byteString.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: mimeString });

      const fileName = eventDetailsData?.fileName;
      const file = new File([blob], fileName, { type: mimeString });

      const formData = new FormData();
      formData.append("file", file, fileName);

      return formData;
    }

    return null; 
  };

  const getItemFromStorage = (storage, key) => {
    const item = storage.getItem(key);
    return item ? JSON.parse(item) : null;
  };

  const handleNext = async () => {
    setApiCall(true);

    const eventPageDetails = getItemFromStorage(
      sessionStorage,
      "MOBILE_EVENT_DETAILS"
    );
    const userDetails = getItemFromStorage(localStorage, "key")?.body;
    const transactionId = getItemFromStorage(
      localStorage,
      "FOREVER_MOBILE_PATH_TRANSACTION_ID"
    );
    const planDetails = getItemFromStorage(
      localStorage,
      "SELECTED_FOREVER_PLAN"
    );

    if (!eventPageDetails || !userDetails || !transactionId || !planDetails) {
      setApiCall(false);
      switch (false) {
        case !!eventPageDetails:
          alert("Event details missing");
          break;
        case !!userDetails:
          alert("User details missing");
          break;
        case !!transactionId:
          alert("Transaction ID missing");
          break;
        case !!planDetails:
          alert("Plan details missing");
          break;
        default:
          alert("An unexpected error occurred");
          break;
      }
      return;
    }

    const vendorPageDetails = [mainDetails, ...extraDetails];
    const apiBody = {
      name: eventPageDetails?.name,
      description: eventPageDetails?.description,
      date: eventPageDetails?.date,
      hostId: userDetails?.id,
      amount: planDetails?.discountedPrice,
      transactionId,
      storageBought: planDetails?.storage,
      vendors: vendorPageDetails,
      type: "WEBAPP_INDEPENDENT_EVENT",
    };

    try {
      const res1 = await createMobileEvent(apiBody);
      console.log("res body 1.....................................................................",res1);

      if (res1?.status === "OK") {
        const formData = retrieveFileAsFormData(eventPageDetails);
        console.log("formData.....................................................................",formData);
        if (formData) {
          const coverApiRes = await uploadCoverPhoto(formData, res1?.body?.id);
          console.log("coverApiRes.....................................................................",coverApiRes);
          if (coverApiRes?.status === "OK") {
            sessionStorage.removeItem("MOBILE_EVENT_DETAILS");
            localStorage.removeItem("PAYMENT_STATUS_FOREVER");
            localStorage.removeItem("SELECTED_FOREVER_PLAN");
            localStorage.removeItem("FOREVER_MOBILE_PATH_TRANSACTION_ID");
            localStorage.removeItem("PAGINATED_STEP_FOREVER");
            sessionStorage.setItem("eventCreated", true);
            localStorage.setItem("eventId", res1?.body?.id);
            localStorage.setItem("eventName", res1?.body?.name);
            localStorage.setItem("eventDeeplink", res1?.body?.deeplink)
            localStorage.setItem("eventType", "WEBAPP_INDEPENDENT_EVENT")
            setApiCall(false);
            navigate("/event");
          } else {
            setApiCall(false);
            alert("Error uploading cover photo");
          }
        } else {
          setApiCall(false);
          alert("File data missing");
        }
      } else {
        setApiCall(false);
        alert("Error creating event");
      }
    } catch (error) {
      setApiCall(false);
      alert("An unexpected error occurred");
    }
  };

  useEffect(() => {
    const changeContinueBtn = () => {};
    changeContinueBtn();
  }, [mainDetails, extraDetails]);

  return (
    <div id="mobile-vendor">
      <div className="left-arrow" onClick={() => prevBtn()}>
        <img src={backbtn} alt="" />
      </div>

      <div className="head-main">
        <p className="heading">Vendor Details</p>
      </div>

      <div className="details-main">
        <div className="details">
          <div className="title">
            {allowMainTitleChange ? (
              <input
                type="text"
                className="main-title-input"
                value={mainDetails?.category || ""}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setAllowMainTitleChange(!allowMainTitleChange);
                  }
                }}
                onChange={(e) => {
                  setMainDetails((prev) => ({
                    ...prev,
                    category: e.target.value,
                  }));
                }}
              />
            ) : (
              <p>{mainDetails?.category}</p>
            )}
            <img
              src={edit}
              alt=""
              onClick={() => setAllowMainTitleChange(!allowMainTitleChange)}
            />
          </div>
          <div className="main">
            <label htmlFor="vendorName" className="name">
              Name
            </label>
            <input
              type="text"
              id="vendorName"
              value={mainDetails.vendorName || ""}
              onChange={handleInput}
              placeholder="Photo Studio"
            />
          </div>
          <div className="main">
            <label htmlFor="mobile" className="name">
              Mobile No.
            </label>
            <input
              type="number"
              id="mobile"
              value={mainDetails.mobile || ""}
              onChange={(e) => {
                if (e.nativeEvent.data === "e") return;
                handleInput(e);
              }}
              placeholder="982373101"
            />
          </div>
          <div className="main">
            <label htmlFor="url" className="name">
              URL
            </label>
            <input
              type="text"
              id="url"
              value={mainDetails.url || ""}
              onChange={handleInput}
              placeholder="www.photostudio.com"
            />
          </div>
          <div className="main">
            <label htmlFor="social" className="name">
              Instagram
            </label>
            <input
              type="text"
              id="insta"
              value={mainDetails.insta || ""}
              onChange={handleInput}
              placeholder="Photostudio.com"
            />
          </div>
        </div>

        <div className="other-details">
          {extraDetails?.map((ed) => (
            <div key={ed?.key} style={{ marginTop: "70px" }}>
              <div className="title">
                {allowMainTitleChange ? (
                  <input
                    type="text"
                    id="category"
                    autoComplete="false"
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        setAllowMainTitleChange(!allowMainTitleChange);
                      }
                    }}
                    className="main-title-input"
                    value={ed?.category || ""}
                    onChange={(e) => handleExtraInput(ed, e)}
                  />
                ) : (
                  <p>{ed?.category}</p>
                )}
                <img
                  src={edit}
                  alt=""
                  onClick={() => setAllowMainTitleChange(!allowMainTitleChange)}
                />
              </div>
              <div className="main">
                <label htmlFor="vendorName" className="name">
                  Name
                </label>
                <input
                  type="text"
                  id="vendorName"
                  value={ed.vendorName || ""}
                  onChange={(e) => handleExtraInput(ed, e)}
                  placeholder="Photo Studio"
                />
              </div>
              <div className="main">
                <label htmlFor="mobile" className="name">
                  Mobile No.
                </label>
                <input
                  type="number"
                  id="mobile"
                  value={ed.mobile || ""}
                  onChange={(e) => {
                    if (e.nativeEvent.data === "e") return;
                    handleExtraInput(ed, e);
                  }}
                  placeholder="982373101"
                />
              </div>
              <div className="main">
                <label htmlFor="url" className="name">
                  URL
                </label>
                <input
                  type="text"
                  id="url"
                  value={ed.url || ""}
                  onChange={(e) => handleExtraInput(ed, e)}
                  placeholder="www.photostudio.com"
                />
              </div>
              <div className="main">
                <label htmlFor="social" className="name">
                  Instagram
                </label>
                <input
                  type="text"
                  id="insta"
                  value={ed.insta || ""}
                  onChange={(e) => handleExtraInput(ed, e)}
                  placeholder="Photostudio.com"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="btns">
        <button
          className="btn-common-class btn-al-ac"
          onClick={() => setAddExtraDetails(!addExtraDetails)}
        >
          + Add More
        </button>
        <button className="btn-common-class btn-ev-se" onClick={handleNext}>
          {apiCall ? <Loader /> : "Next"}
        </button>
      </div>
      {addExtraDetails && (
        <ExtraDetails
          addExtraDetails={addExtraDetails}
          setAddExtraDetails={setAddExtraDetails}
          extraDetails={extraDetails}
          setExtraDetails={setExtraDetails}
        />
      )}
    </div>
  );
};

export default VendorDetails;
