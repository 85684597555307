import React, { useState } from "react"
import "./createEvent.scss"
import tick from "../../../assets/CreateEvent/CheckOutline.svg"
import arrow from "../../../assets/CreateEvent/Vector.svg"
import { useNavigate } from "react-router-dom"
import Radio from "@mui/material/Radio"

const CreateEvent = ({ selectTabFn, nextBtn, selectedTab, setSelectedTab }) => {
  const navigate = useNavigate()

  const [pathData, setPathData] = useState([
    {
      title: "Mobile App",
      features: [
        "Life long access of the album",
        "Guest can upload photos as well",
        "Search photos through AI face recognition",
        "Can give album access",
        "Can view whole event details",
        "Can share QR code to invite members",
      ],
    },
    {
      title: "Website Link",
      features: [
        "Temporary access of the album",
        "Can share website link to invite members",
        "Guest can view event photos",
      ],
    },
  ])

  return (
    <div id="create-event">
      <div className="left-arrow" onClick={() => navigate(-1)}>
        <img src={arrow} alt="" />
      </div>
      <div className="head-main">
        <p className="heading">Choose Your Event Category</p>
        <p className="head-content">
          Select the event category based on your client's preference.
        </p>
      </div>

      <div className="card-main">
        {pathData.map((pd, index) => (
          <div
            key={index}
            onClick={() => {
              setSelectedTab(pd?.title)
              selectTabFn(pd?.title)
            }}
            className={`${
              selectedTab === pd?.title ? "single-selected-card" : "single-card"
            }`}
          >
            <div className="card-title">
              <p>{pd.title}</p>
            </div>
            {/*<div*/}
            {/*  className={*/}
            {/*    selectedTab === pd?.title ? "select-triggered" : "select"*/}
            {/*  }*/}
            {/*>*/}
            {/*  <div*/}
            {/*    style={{*/}
            {/*      transform:*/}
            {/*        selectedTab === "Mobile App"*/}
            {/*          ? "translate(-0.5px, 0.5px)"*/}
            {/*          : "translate(0.5px, 0.5px)",*/}
            {/*    }}*/}
            {/*    className={`${selectedTab === pd?.title && "dot"}`}*/}
            {/*  ></div>*/}
            {/*</div>*/}

              <div style={{ display: "flex", justifyContent: "end" }}>
                  <Radio
                      checked={selectedTab === pd?.title}
                      name="radio-buttons"
                      sx={{
                          color: "#26272C",
                          "&.Mui-checked": {
                              color: "#FBCA7F",
                          },
                      }}
                  />
              </div>

            <div className="features">
              {pd.features.map((pdf, index) => (
                <div className="feature-data" key={index}>
                  <img src={tick} alt="" />
                  <p>{pdf}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="continue-btn-main">
        <p
          className="continue-btn"
          onClick={() => selectedTab && nextBtn()}
          style={{
            background:
              selectedTab && "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
            cursor: "pointer",
          }}
        >
          Continue
        </p>
      </div>
    </div>
  )
}

export default CreateEvent
