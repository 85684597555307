import { apiURL } from "./apiConstants"
import {toast} from "sonner";

export const loginSendOTP = async (body) => {
  const url = apiURL.loginSendOTP
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const authLoginOTP = async (body) => {
  const url = apiURL.authLoginOTP
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getEventsAbout = async (body) => {
  const url = apiURL.getEventsAbout + body
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getQRCodeOfEvent = async (eventId) => {
  const url = apiURL.getQRCodeOfEvent + eventId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getReferredEvents = async (body) => {
  const url =
    apiURL.getReferredEvents +
    body.id +
    "&page=" +
    body.page +
    "&limit=" +
    body.limit
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getAllEvents = async (body) => {
  const url =
    apiURL.getAllEvents +
    body.id +
    "&page=" +
    body.page +
    "&limit=" +
    body.limit
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getAllFilesOfEvents = async (eventId, userId) => {
  const url = apiURL.getAllFilesOfEvents + eventId + "&userId=" + userId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const uploadFiles = async (params, body) => {
  const url = params?.folderId
    ? apiURL.uploadFiles +
      "userId=" +
      params.userId +
      "&eventId=" +
      params.eventId +
      "&folderId=" +
      params.folderId
    : apiURL.uploadFiles +
      "userId=" +
      params.userId +
      "&eventId=" +
      params.eventId

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getPrivacyPolicy = async () => {
  const url = apiURL.getPrivacyPolicy
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getTermsAndConditions = async () => {
  const url = apiURL.getTermsAndConditions
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getRefundPolicy = async () => {
  const url = apiURL.getPrivacyPolicy
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getCancellationPolicy = async () => {
  const url = apiURL.getCancellationPolicy
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getPhysicalAlbum = async (eventId, userId) => {
  const url = apiURL.getPhysicalAlbum + eventId + "&userId=" + userId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const deleteFile = async (body) => {
  const url = apiURL.deleteFile + body.userId + "&fileId=" + body.fileId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const likeOrUnlikeFile = async (body) => {
  const url = apiURL.likeOrUnlikeFile + body.userId + "&fileId=" + body.fileId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const contactUs = async (body) => {
  const url = apiURL.contact
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const deleteFileList = async (body) => {
  const url = apiURL.deleteFileList
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const deleteNotification = async (notificationId) => {
  const url = `${apiURL.deleteNotification}${notificationId}`
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error deleting notification:", error)
    return null
  }
}

export const getSubscriptionDetails = async (body) => {
  const url = apiURL.getSubscriptionDetails + body.id
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data?.body
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const createProfile = async (body) => {
  const url = apiURL.createProfile
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const uploadProfilePhoto = async (file, phoneNumber) => {
  const url = `${apiURL.uploadProfilePhoto}${phoneNumber}`
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  const formData = new FormData()
  formData.append("file", file)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error uploading file:", error)
    return null
  }
}

export const createOrder = async (body) => {
  const url = apiURL.createOrder
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data?.body
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const createFolder = async (body) => {
  const url = apiURL.createFolder
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getFoldersOfEvent = async (eventId) => {
  const url = apiURL.getFoldersOfEvent + eventId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const updateFolderCoverPhoto = async (body, params) => {
  const url = apiURL.updateFolderCoverPhoto + params
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: body,
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    console.log(response)

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const deleteFolder = async (body) => {
  const url = apiURL.deleteFolder
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "DELETE",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const editFolder = async (body) => {
  const url = apiURL.editFolder
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getAllFilesOfFolder = async (userId, folderId) => {
  const url = apiURL.getAllFilesOfFolder + userId + "&folderId=" + folderId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const moveFiles = async (body) => {
  const url = apiURL.moveFiles
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}
export const confirmPayment = async (body) => {
  const url = apiURL.confirmPayment
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) throw new Error("Network response was not ok")

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const createEvent = async (body) => {
  const url = apiURL.createEvent
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const uploadCoverPhoto = async (body, id) => {
  const url = `${apiURL.uploadCoverPhoto}/${id}`
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body,
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getPaymentModes = async () => {
  const url = apiURL.getPaymentModes
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data?.body
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const createMobileEvent = async (body) => {
  const url = apiURL.createMobileEvent
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getAllPublicFilesOfEvent = async (eventId) => {
  const url = apiURL.getAllPublicFilesOfEvent + eventId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getGuestUploads = async (eventId) => {
  const url = apiURL.getGuestUploads + eventId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getUserDetails = async (userId) => {
  const url = apiURL.getUserDetails + userId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getEventGuests = async (eventId) => {
  const url = apiURL.getEventGuests + eventId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const downloadEventDataApi = async (eventId) => {
  const url = `${apiURL.downloadEventData}${eventId}`;
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`);

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const blob = await response.blob(); // Await blob conversion
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = "data.xlsx";
    document.body.appendChild(a);
    a.click();
    a.remove();

    // Clean up the object URL
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Error fetching data:", error);
    toast.error("Error downloading event data");
    return null;
  }
};

export const getUserByPhoneNumber = async (number) => {
  const url = apiURL.getUserByPhoneNumber + number
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const joinEvent = async (userId, eventId) => {
  const url =
    apiURL.joinEvent + "userId=" + userId + "&eventId=" + eventId

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const editUserRoleForEvent = async (userId, eventId, role) => {
  const url =
    apiURL.editUserRoleForEvent +
    "userId=" +
    userId +
    "&eventId=" +
    eventId +
    "&role=" +
    role

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const removeUserFromEvent = async (userId, eventId) => {
  const url =
    apiURL.removeUserFromEvent + "userId=" + userId + "&eventId=" + eventId

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const toggleFaceRecognition = async (userId, eventId, facePrivacy) => {
  const url =
    apiURL.toggleFaceRecognition +
    "userId=" +
    userId +
    "&eventId=" +
    eventId +
    "&enableFacePrivacy=" +
    facePrivacy

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const toggleGuestUpload = async (userId, eventId, guestUpload) => {
  const url =
    apiURL.toggleGuestUpload +
    "userId=" +
    userId +
    "&eventId=" +
    eventId +
    "&enableGuestUpload=" +
    guestUpload

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const deleteEvent = async (eventId) => {
  const url = apiURL.deleteEvent + eventId

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const deleteAccount = async (userId) => {
  const url = apiURL.deleteAccount + userId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getReferralId = async (userId) => {
  const url = apiURL.getReferralId + userId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const createReferralId = async (body) => {
  const url = apiURL.createReferralId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const editEventDetails = async (body) => {
  const url = apiURL.editEventDetails

  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getDashboard = async (userId) => {
  const url = apiURL.getDashboard + userId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const userRoleInEvent = async (userId, eventId) => {
  const url = apiURL.userRoleInEvent + userId + "&eventId=" + eventId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const moveToPhysicalAlbum = async (body) => {
  const url = apiURL.moveToPhysicalAlbum
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const getSubscriptionEventConfig = async (eventId) => {
  const url = apiURL.getSubscriptionEventConfig + eventId
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}

export const setPincode = async (body) => {
  const url = apiURL.setPincode
  const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)

  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        Authorization: `Basic ${encodedCredentials}`,
        "Content-Type": "application/json",
      },
    })

    if (!response.ok) {
      throw new Error("Network response was not ok")
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error("Error fetching data:", error)
    return null
  }
}
