import React, { useEffect } from "react"
import "./Payment.scss"
import backbtn from "../../../../assets/CreateEvent/Vector.svg"
import { confirmPayment, createOrder } from "../../../../api/api"

const Payment = ({ prevBtn, nextBtn }) => {
  useEffect(() => {
    // handle activate razorpay
    const displayRazorpay = async (amount, order_id, localUserData, itemId) => {
      const options = {
        key: "rzp_live_aPRLkFK27mq3Is",
        // key: "rzp_test_HMAjFSjTkhr0Wm",
        amount,
        currency: "INR",
        description: "It's Forever Storage",
        order_id,
        image: `${localUserData?.profilePhotoUrl}`,
        prefill: {
          name: `${localUserData?.firstName} ${localUserData?.lastName}`,
          email: `${localUserData?.email}`,
          contact: `${localUserData?.phoneNumber}`,
        },
        handler: async function (response) {
          const res = await confirmPayment({
            transactionId: order_id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            orderType: "ORDER_TYPE_EVENT_CONFIRMED",
          })

          if (res?.status === "OK") {
            localStorage.setItem("PAYMENT_STATUS_FOREVER", true)
            setTimeout(() => {
              nextBtn()
            }, 500)
          }
        },
        modal: {
          ondismiss: function () {
            if (window.confirm("Are you sure, you want to close the form?")) {
              window.Text = "You pressed OK!"
            } else {
              window.Text = "You pressed Cancel!"
            }
          },
        },
      }

      const rzp1 = new window.Razorpay(options)

      rzp1.on("payment.failed", function (response) {
        alert(response.error.description)
      })

      rzp1.open()
    }

    // handle buy
    const handleBuy = async () => {
      const localUserData = JSON.parse(localStorage.getItem("key"))?.body
      const localPlanData = JSON.parse(
        localStorage.getItem("SELECTED_FOREVER_PLAN")
      )

      const bodyData = {
        storageBought: localPlanData?.storage,
        // amount: 1,
        amount: localPlanData?.discountedPrice,
        orderType: "ORDER_TYPE_EVENT_PENDING",
        userId: localUserData?.id,
      }

      const res = await createOrder(bodyData)

      localStorage.setItem(
        "FOREVER_MOBILE_PATH_TRANSACTION_ID",
        JSON.stringify(res?.id)
      )

      if (res?.status === "created")
        displayRazorpay(res?.amount, res?.id, localUserData, localPlanData?.id)
    }

    handleBuy()
  }, [])

  return (
    <div id="mobile-payment">
      <div className="left-arrow" onClick={() => prevBtn()}>
        <img src={backbtn} alt="" />
      </div>

      <div className="head-main">
        <p className="heading">Payments</p>
      </div>

      {/* <div className="continue-btn-main">
        <p
          className="continue-btn"
          onClick={() => nextBtn()}
          style={{
            background: "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
            cursor: "pointer",
          }}
        >
          Continue
        </p>
      </div> */}
    </div>
  )
}

export default Payment
