import React, { useState, useEffect } from "react"
import "./Privacy.scss"
import {
  getPrivacyPolicy,
  getTermsAndConditions,
  getRefundPolicy,
} from "../../../api/api"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Typography from "@mui/material/Typography"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ThemeProvider, createTheme } from "@mui/material/styles"

const ManagedEvents = () => {
  const [privacyPolicy, setPrivacyPolicy] = useState()
  const [termsAndConditions, setTermsAndConditions] = useState()
  const [refundPolicy, setRefundPolicy] = useState()

  useEffect(() => {
    getPrivacyPolicy().then((response) => {
      if (!response.error) {
        setPrivacyPolicy(response.body)
      }
    })

    getTermsAndConditions().then((response) => {
      if (!response.error) {
        setTermsAndConditions(response.body)
      }
    })

    getRefundPolicy().then((response) => {
      if (!response.error) {
        setRefundPolicy(response.body)
      }
    })
  }, [])

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  })

  return (
    <div className="m-3 privacy-box" style={{ background: "#1A1C21" }}>
      <ThemeProvider theme={theme}>
        {privacyPolicy && (
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>Privacy Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{privacyPolicy}</Typography>
            </AccordionDetails>
          </Accordion>
        )}
        {termsAndConditions && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Terms And Conditions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{termsAndConditions}</Typography>
            </AccordionDetails>
          </Accordion>
        )}
        {refundPolicy && (
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2-content"
              id="panel2-header"
            >
              <Typography>Refund Policy</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{refundPolicy}</Typography>
            </AccordionDetails>
          </Accordion>
        )}
      </ThemeProvider>
    </div>
  )
}

export default ManagedEvents
