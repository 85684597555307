import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { createProfile, uploadProfilePhoto } from "../../api/api";
import "./CompleteProfile.scss";
import cameraIcon from "../../assets/Icons/camera.svg";
import defaultProfilePhoto from "../../assets/Icons/profile.svg";

const CompleteProfile = ({ setIsLoggedIn, setIsOtp }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [errorMessage, setErrorMessage] = useState(""); 
  const [isSubmitting, setIsSubmitting] = useState(false); 

  useEffect(() => {
    const phone = location.state?.phoneNumber;
    if (phone) {
      setPhoneNumber(phone);
    } else {
      const details = JSON.parse(localStorage.getItem("key")) || {};
      if (details.status === "OK") {
        setPhoneNumber(details.body.phoneNumber);
      }
    }
  }, [location.state]);

  const handleSubmit = async () => {
    if (isSubmitting) return; 

    setIsSubmitting(true);
    setErrorMessage(""); 

    try {
      if (!firstName || !lastName || !phoneNumber) {
        setErrorMessage("Please fill in all fields.");
        setIsSubmitting(false);
        return;
      }

      const profileResponse = await createProfile({ firstName, lastName, phoneNumber });
      if (!profileResponse || profileResponse.status === "ERROR") {
        setErrorMessage(profileResponse?.error || "Failed to create profile.");
        setIsSubmitting(false);
        return;
      }

      if (selectedPhoto) {
        const file = dataURLToFile(selectedPhoto, "profile-photo.heic");
        const photoResponse = await uploadProfilePhoto(file, phoneNumber);

        if (!photoResponse || photoResponse.status === "ERROR") {
          setErrorMessage(photoResponse?.error || "Failed to upload profile photo.");
          setIsSubmitting(false);
          return;
        }
      }

      const response = JSON.stringify(profileResponse);
      localStorage.setItem("key", response);
      setIsLoggedIn(true);
      setIsOtp(false);
      navigate("/home");
    } catch (error) {
      setErrorMessage("An unexpected error occurred. Please try again later.");
    } finally {
      setIsSubmitting(false); 
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const dataURLToFile = (dataURL, filename) => {
    const arr = dataURL.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  return (
    <div className="complete-profile-container">
      <div className="d-flex flex-column align-items-center p-3 bg-black" style={{ borderRadius: "20px", margin: '10px', width: "100%", height: "100%" }}>
        <div className="d-flex justify-content-center mt-4 text-white">
          <h1 className="title">Tell us about yourself</h1>
        </div>

        <div className="d-flex flex-column align-items-center mt-4">
          <div style={{ position: 'relative', display: 'inline-block' }}>
            <img
              src={selectedPhoto || defaultProfilePhoto}
              alt="Profile"
              className="profile-photo"
              style={{ width: '100px', height: '100px', borderRadius: '50%' }}
            />
            <label htmlFor="file-upload" style={{ position: 'absolute', bottom: '0', right: '0', cursor: 'pointer' }}>
              <img
                src={cameraIcon}
                alt="Edit"
                className="camera-edit"
                style={{ width: '30px', height: '30px', borderRadius: '50%' }}
              />
            </label>
            <input
              id="file-upload"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </div>
        </div>

        <div className="details-holder d-flex flex-column">
          <span className="titles mt-4">First Name</span>
          <input
            className="mt-2 input-text bg-transparent border"
            type="text"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
          />

          <span className="titles mt-4">Last Name</span>
          <input
            className="mt-2 input-text bg-transparent"
            type="text"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
          />

          <span className="titles mt-4">Phone Number</span>
          <input
            className="mt-2 input-text bg-transparent"
            type="text"
            value={phoneNumber}
            readOnly 
          />
        </div>

        {errorMessage && (
          <div className="error-message mt-4" style={{ color: "red" }}>
            {errorMessage}
          </div>
        )}

        <div className="d-flex justify-content-center mt-4">
          <button
            type="button"
            onClick={handleSubmit}
            disabled={isSubmitting}
            style={{
              minWidth: "8rem",
              background: "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
              borderRadius: "25px",
              color: "#000",
              outline: "none",
              border: "none",
              padding: "10px 53px",
              fontFamily: "Roboto",
              fontWeight: 500,
              fontSize: "18px",
              lineHeight: "21.09px",
              textAlign: "right",
              cursor: isSubmitting ? "not-allowed" : "pointer",
            }}
          >
            {isSubmitting ? "Submitting..." : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CompleteProfile;
