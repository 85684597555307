import React, { useState, useEffect, useRef } from "react"
import "./ReferralId.scss"
import {
  getReferralId as fetchReferralId,
  createReferralId as generateReferralId,
} from "../../../api/api"
import Pagination from "@mui/material/Pagination"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import NoData from "../../NoData/NoData"
import SkeletonEvents from "../../SkeletonLoader/SkeletonEvents"
import SkeletonTableHeader from "../../SkeletonLoader/SkeletonTableHeader"
import { Toaster, toast } from "sonner"
import Radio from "@mui/material/Radio"
import { handleCopyClick } from "../../../utils/clipboard"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#000",
    },
  },
})

const ReferralId = () => {
  const pageRef = useRef(null)
  const [fromTop, setFromTop] = useState(null)
  const [responseData, setResponseData] = useState([])
  const [showNoData, setShowNoData] = useState(false)
  const [loading, setLoading] = useState(true)
  const [selectedValue, setSelectedValue] = useState()
  const [referralCode, setReferralCode] = useState("")
  const [codeGen, setCodeGen] = useState(false)
  const [newCode, setNewCode] = useState("")
  const [showDiscountError, setShowDiscountError] = useState(false)
  const [showReferralCodeError, setShowReferralCodeError] = useState(false)

  useEffect(() => {
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id

    fetchReferralId(userId).then((res) => {
      if (!res?.error) {
        if (res.body.length === 0) {
          setShowNoData(true)
        }
        setResponseData(res.body)
        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
    })
  }, [])

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value)
  }

  const handleCreateReferralId = () => {
    const storedKey = localStorage.getItem("key")
    const userId = JSON.parse(storedKey).body.id
    if (selectedValue) {
      setShowDiscountError(false)
      setShowReferralCodeError(false)
      const body = {
        userId: userId,
        referralCode: referralCode,
        discountPercent: selectedValue,
      }
      generateReferralId(body).then((response) => {
        if (response?.error || response.body?.err) {
          setShowReferralCodeError(true)
        } else {
          setCodeGen(true)
          setNewCode(response.body.referralCode)
          window.location.reload()
        }
      })
    } else {
      setShowDiscountError(true)
    }
  }

  const updateMaxHeight_REFERRAL = () => {
    if (pageRef.current) {
      const distanceFromTop = pageRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 10
      pageRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  useEffect(() => {
    updateMaxHeight_REFERRAL()
    window.addEventListener("resize", updateMaxHeight_REFERRAL)

    return () => {
      window.removeEventListener("resize", updateMaxHeight_REFERRAL)
    }
  }, [])

  // handle height from top
  useEffect(() => {
    const storageElement = document.getElementById("referral-id-page")
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top)
    } else {
      console.error("Element with ID 'referral-id-page' not found.")
    }
  }, [])

  return (
    <div
      ref={pageRef}
      id="referral-id-page"
      style={{
        minHeight: `calc(100vh - ${fromTop}px -10px)`,
        margin: "10px",
        overflow: "scroll",
        position: "relative",
        borderRadius: "20px",
      }}
      className="bg-black pt-4 pb-5"
    >
      <div className="d-flex flex-column align-items-center">
        <span className="referral-head">Get super discount offer</span>
        <div className="d-flex align-items-center" style={{ margin: "15px 0" }}>
          <span className="referral-info">
            Redeem your 10-30% discount offer on our Referral Code Id.
          </span>
          <span className="referral-work" style={{ marginLeft: "4px" }}>
            See how it works
          </span>
        </div>
      </div>

      <div className="d-flex p-3 mt-3 justify-content-evenly">
        {/* 30% */}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedValue("30")}
          className={`referral-box d-flex flex-column justify-content-center p-2 ps-4 ${
            selectedValue === "30" ? "referral-box-selected-box" : ""
          }`}
        >
          <div className="d-flex justify-content-between">
            <span className="offer-rate">30% Off</span>
            <Radio
              checked={selectedValue === "30"}
              onChange={handleRadioChange}
              value="30"
              name="radio-buttons"
              sx={{
                color: "#26272C",
                "&.Mui-checked": {
                  color: "#FBCA7F",
                },
              }}
            />
          </div>
          <span className="commission-rate">with 0% commission</span>
        </div>
        {/* 20% */}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedValue("20")}
          className={`referral-box d-flex flex-column justify-content-center p-2 ps-4 ${
            selectedValue === "20" ? "referral-box-selected-box" : ""
          }`}
        >
          <div className="d-flex justify-content-between">
            <span className="offer-rate">20% Off</span>
            <Radio
              checked={selectedValue === "20"}
              onChange={handleRadioChange}
              value="20"
              name="radio-buttons"
              sx={{
                color: "#26272C",
                "&.Mui-checked": {
                  color: "#FBCA7F",
                },
              }}
            />
          </div>
          <span className="commission-rate">with 10% commission</span>
        </div>
        {/* 10% */}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedValue("10")}
          className={`referral-box d-flex flex-column justify-content-center p-2 ps-4 ${
            selectedValue === "10" ? "referral-box-selected-box" : ""
          }`}
        >
          <div className="d-flex justify-content-between">
            <span className="offer-rate">10% Off</span>
            <Radio
              checked={selectedValue === "10"}
              onChange={handleRadioChange}
              value="10"
              name="radio-buttons"
              sx={{
                color: "#26272C",
                "&.Mui-checked": {
                  color: "#FBCA7F",
                },
              }}
            />
          </div>
          <span className="commission-rate">with 20% commission</span>
        </div>
        {/* 0% */}
        <div
          style={{ cursor: "pointer" }}
          onClick={() => setSelectedValue("0")}
          className={`referral-box d-flex flex-column justify-content-center p-2 ps-4 ${
            selectedValue === "0" ? "referral-box-selected-box" : ""
          }`}
        >
          <div className="d-flex justify-content-between">
            <span className="offer-rate">0% Off</span>
            <Radio
              checked={selectedValue === "0"}
              onChange={handleRadioChange}
              value="0"
              name="radio-buttons"
              sx={{
                color: "#26272C",
                "&.Mui-checked": {
                  color: "#FBCA7F",
                },
              }}
            />
          </div>
          <span className="commission-rate">with 30% commission</span>
        </div>
      </div>

      <div className="d-flex flex-column align-items-center mb-4 mt-5">
        <span className="offer-info">
          Select your discount rate and create the code
        </span>
        <span
          className="offer-info"
          style={{
            fontWeight: 400,
            color: "#706F6C",
            fontSize: "16px",
            lineHeight: "18.75px",
            marginTop: "10px",
          }}
        >
          The code must be exactly 10 characters long.
        </span>
        <div className="d-flex align-items-baseline generate-code-box mt-4">
          <input
            className="mt-2 input-text me-3"
            type="text"
            max={10}
            maxLength={10}
            placeholder="Should be 10 characters long"
            value={referralCode}
            style={{ background: "transparent" }}
            onChange={(event) => setReferralCode(event.target.value)}
          />
          {!codeGen ? (
            <button
              type="button"
              title={!selectedValue ? "Please select the discount above" : null}
              className={`btn ${
                referralCode === "" || referralCode.length !== 10
                  ? "generate-btn"
                  : "generate-btn-active"
              }`}
              disabled={referralCode === "" || referralCode.length !== 10}
              onClick={handleCreateReferralId}
            >
              Generate Code
            </button>
          ) : (
            <button
              type="button"
              className="btn generate-btn-active"
              onClick={() => {
                handleCopyClick(newCode)
              }}
            >
              Copy Code
            </button>
          )}
        </div>
        {showDiscountError && (
          <span
            className="discount-error-msg mt-4"
            style={{ color: "#e1e1e1" }}
          >
            Please select your discount rate from the above options.
          </span>
        )}
        {showReferralCodeError && (
          <span
            className="discount-error-msg mt-4"
            style={{ color: "#e1e1e1" }}
          >
            Referral code already exists. Please use a different code.
          </span>
        )}
      </div>
      <div className="table-box" style={{ background: "black" }}>
        {loading && (
          <>
            <SkeletonTableHeader />
            <SkeletonEvents />
          </>
        )}
        {!loading && showNoData && <NoData />}
        {!loading && !showNoData && (
          <div
            className="row table-header m-0"
            style={{ background: "#1c1d21", borderRadius: "10px 10px 0 0" }}
          >
            <span className="col-1 align-items-center d-flex justify-content-center">
              S.No
            </span>
            <span className="col-6 align-items-center d-flex">Referral Id</span>
            <span className="col-3 align-items-center d-flex justify-content-center">
              Discount%
            </span>
            <span className="col-2 align-items-center d-flex justify-content-center">
              Share
            </span>
          </div>
        )}
        {!loading &&
          responseData.map((item, index) => (
            <div
              key={index}
              className="row table-contents m-0"
              style={{ background: "black" }}
            >
              <span
                className="col-1 align-items-center d-flex justify-content-center"
                style={{ color: "#706F6C" }}
              >
                #{index + 1}
              </span>
              <span className="col-6 align-items-center d-flex text-color-gray">
                {item.referralCode}
              </span>
              <span className="col-3 align-items-center d-flex text-color-gray justify-content-center">
                {item.discountPercent ? item.discountPercent : 0}%
              </span>
              <div className="col-2 align-items-center d-flex justify-content-center">
                <Toaster richColors />
                {!item.expired && (
                  <button
                    type="button"
                    className="btn share-btn"
                    onClick={() => {
                      handleCopyClick(item.referralCode)
                      toast.success("Code Copied")
                    }}
                  >
                    Copy Code
                  </button>
                )}
                {item.expired && (
                  <button type="button" disabled className="btn used-btn">
                    Used
                  </button>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}

export default ReferralId
