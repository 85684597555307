import React, { useEffect, useState } from "react"
import "./ChooseYourPlan.scss"
import backbtn from "../../../../assets/CreateEvent/Vector.svg"
import {
  getCancellationPolicy,
  getPaymentModes,
  getPrivacyPolicy,
  getRefundPolicy,
  getTermsAndConditions,
} from "../../../../api/api"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  createTheme,
  Modal,
  ThemeProvider,
  Typography,
} from "@mui/material"
import Loader from "../../../Loader/Loader"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "#26272C",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
}

const ChooseYourPlan = ({ prevBtn, nextBtn, jump, setPopup }) => {
  const [planData, setPlanData] = useState([])
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(null)
  const [cancellationPolicy, setCancellationPolicy] = useState()
  const [termsAndConditions, setTermsAndConditions] = useState()
  const [refundPolicy, setRefundPolicy] = useState()

  const theme = createTheme({
    palette: {
      mode: "dark",
    },
  })

  const handleOpen = (t) => {
    setPopup(true)
    setOpen(true)
    setType(t)
  }
  const handleClose = () => {
    setPopup(false)
    setOpen(false)
    setType(null)
  }

  const clickPlan = (planId) => {
    const clicked = planData?.filter((pd) => pd?.id === planId)[0]
    localStorage.setItem("SELECTED_FOREVER_PLAN", JSON.stringify(clicked))
    nextBtn()
  }

  useEffect(() => {
    getCancellationPolicy().then((response) => {
      if (!response.error) {
        setCancellationPolicy(response.body)
      }
    })

    getTermsAndConditions().then((response) => {
      if (!response.error) {
        setTermsAndConditions(response.body)
      }
    })

    getRefundPolicy().then((response) => {
      if (!response.error) {
        setRefundPolicy(response.body)
      }
    })
  }, [])

  useEffect(() => {
    const getDataForSelectedPlan = async () => {
      const res = await getPaymentModes()
      setPlanData(
        res?.map((r) => {
          return {
            title:
              r?.storageName === "Basic"
                ? "Minimony"
                : r?.storageName === "Silver"
                ? "Intimate Event"
                : r?.storageName === "Golden"
                ? "Grand Event"
                : r?.storageName === "Diamond"
                ? "The Big Fat Event"
                : null,
            storage: r?.storageSize,
            originalPrice: Math.round(
              (r?.price * 100) / (100 - r?.discountPercent)
            ),
            id: r?.id,
            discountedPrice: r?.price,
          }
        })
      )
    }
    getDataForSelectedPlan()
  }, [])

  useEffect(() => {
    const paymentStatus = JSON.parse(
      localStorage.getItem("PAYMENT_STATUS_FOREVER")
    )
    if (paymentStatus) jump(3)
  }, [])

  const Popup = () => {
    return (
      <div className="m-3 privacy-box" style={{ background: "#1A1C21" }}>
        <ThemeProvider theme={theme}>
          <Accordion defaultExpanded>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Typography>
                {type === "tnc"
                  ? "Terms & Conditions"
                  : type === "rp"
                  ? "Refund Policy"
                  : type === "cp"
                  ? "Cancellation Policy"
                  : null}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {type === "tnc"
                  ? termsAndConditions
                  : type === "rp"
                  ? refundPolicy
                  : type === "cp"
                  ? cancellationPolicy
                  : null}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </ThemeProvider>
      </div>
    )
  }

  return (
    <div id="choose-your-plan">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "-2px",
              right: "10px",
              color: "white",
              fontSize: "22px",
              cursor: "pointer",
            }}
          >
            x
          </div>
          <Popup />
        </Box>
      </Modal>
      <div className="left-arrow" onClick={() => prevBtn()}>
        <img src={backbtn} alt="" />
      </div>
      <div className="head-main">
        <p className="heading">Choose Your Plan</p>
        <p className="head-content">
          Choose the package that suits you best: Minimony , Intimate Event,
          Grand Event, or The Big Fat Event.
        </p>
      </div>

      <div className="card-main">
        {planData?.length > 0 ? (
          planData.map((pd, index) => (
            <div className="single-card" key={index}>
              {pd?.discountedPrice < pd?.originalPrice && (
                <div className="savings">
                  <p>
                    Save ₹{(pd?.originalPrice - pd?.discountedPrice).toFixed(0)}{" "}
                  </p>
                </div>
              )}
              {pd?.discountedPrice < pd?.originalPrice && (
                <div className="org-price">₹{pd?.originalPrice}</div>
              )}
              <div className="storage-price">
                <p className="storage">{pd?.storage}GB</p>
                <p className="discount">₹{(pd?.discountedPrice).toFixed(0)}</p>
              </div>
              <div className="card-title">
                <p>{pd?.title}</p>
              </div>
              <div className="services">
                <p>
                  Approx {(pd?.storage * 1000) / 25}+ photos & {pd?.storage}+
                  videos
                </p>
              </div>
              <div className="select-btn">
                <p onClick={() => clickPlan(pd?.id)} className="btn-title">
                  Get {pd?.title}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div style={{ scale: "3", margin: "50px 0" }}>
            <Loader />
          </div>
        )}
      </div>

      <div className="terms">
        <p>
          *By proceeding you have read and agree to the{" "}
          <span
            className="tnc"
            onClick={() => handleOpen("tnc")}
            style={{ cursor: "pointer" }}
          >
            Terms & Conditions
          </span>
          ,{" "}
          <span
            className="tnc"
            onClick={() => handleOpen("rp")}
            style={{ cursor: "pointer" }}
          >
            Refund Policy
          </span>
          ,{" "}
          <span
            className="tnc"
            onClick={() => handleOpen("cp")}
            style={{ cursor: "pointer" }}
          >
            Cancellation Policy.
          </span>
        </p>
      </div>
    </div>
  )
}

export default ChooseYourPlan
