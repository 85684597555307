import React, { useState } from "react"

const ExtraTab3 = ({ setAddExtraDetails, setExtraDetails, extraDetails }) => {
  const [data, setData] = useState({
    key: extraDetails[extraDetails?.length - 1]?.key + 1,
    category: "Other Vendor Details",
  })

  const handleInput = (e) => {
    setData((prev) => ({ ...prev, [e.target.id]: e.target.value }))
  }

  const handleSave = () => {
    setExtraDetails((prev) => [...prev, data])
    setAddExtraDetails(false)
  }
  return (
    <div className="add-extra-popup">
      <div className="extra-details-data" style={{ paddingBottom: "0" }}>
        <div className="title">
          <input
            type="text"
            id="category"
            className="main-title-input"
            value={data?.category || ""}
            onChange={handleInput}
            style={{ textAlign: "center" }}
          />
        </div>
        <div className="main">
          <label htmlFor="name" className="name">
            Name
          </label>
          <input
            type="text"
            id="name"
            value={data?.name || ""}
            onChange={handleInput}
            placeholder="Enter Venue name (Eg: BookEventz Venue )"
          />
        </div>
        <div className="main">
          <label htmlFor="phoneNumber" className="name">
            Mobile No.
          </label>
          <input
            type="number"
            id="phoneNumber"
            value={data?.phoneNumber || ""}
            onChange={(e) => {
              if (e.nativeEvent.data === "e") return
              handleInput(e)
            }}
            placeholder="982373101"
          />
        </div>
        <div className="main">
          <label htmlFor="website" className="name">
            URL
          </label>
          <input
            type="text"
            id="website"
            value={data?.website || ""}
            onChange={handleInput}
            placeholder="www.domainname.com"
          />
        </div>
        <div className="main">
          <label htmlFor="instagram" className="name">
            Instagram
          </label>
          <input
            type="text"
            id="instagram"
            value={data?.instagram || ""}
            onChange={handleInput}
            placeholder="instragram.com"
          />
        </div>

        <div className="btns">
          <button
            onClick={() => setAddExtraDetails(false)}
            className="btn-common-class btn-al-ac"
          >
            Cancel
          </button>
          <button onClick={handleSave} className="btn-common-class btn-ev-se">
            Add
          </button>
        </div>
      </div>
    </div>
  )
}

export default ExtraTab3
