import React from "react"
import comingSoon from "../../assets/BWComingSoon.png"

const AvailableSoon = () => {
  return (
    <div className="mt-4 d-flex justify-content-center">
      <img src={comingSoon} alt="Coming Soon!" style={{ width: "36rem" }} />
    </div>
  )
}

export default AvailableSoon
