import React, { useState, useEffect } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import "./ReferredEvents.scss"
import { getReferredEvents } from "../../../api/api"
import Pagination from "@mui/material/Pagination"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import NoData from "../../NoData/NoData"
import SkeletonEvents from "../../SkeletonLoader/SkeletonEvents"
import SkeletonTableHeader from "../../SkeletonLoader/SkeletonTableHeader"

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#000",
    },
  },
})

const ReferredEvents = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [responseData, setResponseData] = useState([])
  const [numberOfPages, setNumberOfPages] = useState(0)
  const [showNoData, setShowNoData] = useState(false)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const storedObjectString = localStorage.getItem("key")
    const storedObject = JSON.parse(storedObjectString)
    const queryParams = new URLSearchParams(location.search)
    let requestBody = {
      id: storedObject.body.id,
    }

    for (const [key, value] of queryParams.entries()) {
      requestBody[key] = value
    }

    getReferredEvents(requestBody).then((response) => {
      if (!response.error) {
        if (response.events.length === 0) {
          setShowNoData(true)
        }
        setResponseData(response.events)
        setNumberOfPages(response.numberOfPages)

        setTimeout(() => {
          setLoading(false)
        }, 1000)
      }
    })
  }, [location.search])

  const handleChange = (event, value) => {
    navigate("/home/referred?page=" + (value - 1) + "&limit=10")
  }

  return (
    <div className="mt-4">
      <div className="table-box pb-3">
        {loading && (
          <>
            <SkeletonTableHeader />
            <SkeletonEvents />
          </>
        )}
        {!loading && showNoData && <NoData />}
        {!loading && !showNoData && (
          <div className="row table-header m-0">
            <span className="col-3 align-items-center d-flex">Event Name</span>
            <span className="col-1 align-items-center d-flex">
              Referral Code
            </span>
            <span className="col-2 align-items-center d-flex">
              Mobile Number
            </span>
            <span className="col-1 align-items-center d-flex">Created On</span>
            <span className="col-1 align-items-center d-flex">Expire On</span>
            <span className="col-1 align-items-center d-flex">Host Name</span>
            <span className="col-1 align-items-center d-flex">
              Storage Used
            </span>
            <span className="col-2 align-items-center d-flex justify-content-center">
              Managed Events
            </span>
          </div>
        )}

        {!loading &&
          responseData.map((item, index) => (
            <div key={index} className="row table-contents m-0">
              <span className="col-3 align-items-center d-flex">
                {item.name}
              </span>
              <span className="col-1 align-items-center d-flex text-color-gray">
                {item.referralCode}
              </span>
              <span className="col-2 align-items-center d-flex text-color-gray">
                {item.hostPhoneNumber}
              </span>
              <span className="col-1 align-items-center d-flex text-color-gray">
                {item.date}
              </span>
              <span className="col-1 align-items-center d-flex text-color-gray">
                {item.expiryDate}
              </span>
              <span className="col-1 align-items-center d-flex text-color-gray">
                {item.hostFirstName}
              </span>
              <div className="col-1 align-items-center d-flex">
                <span className="gold-text">
                  {item.storageUsed.toFixed(2)}GB/
                </span>
                <span>{item.storageBought}</span>
              </div>
              <div className="col-2 align-items-center d-flex justify-content-center">
                <button
                  type="button"
                  className="btn manage-btn"
                  onClick={() => {
                    localStorage.setItem("eventId", item.id)
                    navigate("/event/about")
                  }}
                >
                  Manage
                </button>
              </div>
            </div>
          ))}

        {!loading && !showNoData && (
          <ThemeProvider theme={theme}>
            <div className="mt-3 d-flex justify-content-center">
              <Pagination
                count={numberOfPages}
                onChange={handleChange}
                color="primary"
                size="large"
              />
            </div>
          </ThemeProvider>
        )}
      </div>
    </div>
  )
}

export default ReferredEvents
