import React, { useEffect, useState } from "react"
import "./Pagination.scss"
import EventDetails from "./MobileApp/EventDetails/EventDetails"
import ChooseYourPlan from "./MobileApp/CYP/ChooseYourPlan"
import Payment from "./MobileApp/Payment/Payment"

import VendorDetails from "./MobileApp/VendorDetails/VendorDetails"
import CreateEvent from "./CreateEvent/CreateEvent"
import EventDetailsWebsiteLink from "./Website/EventDetailsWebsiteLink"
import Finish from "./Finish/Finish"
import StorageInfoPage from "./Website/StorageInfoPage"

const Pagination = () => {
  const [stepsCompleted, setStepsCompleted] = useState(0)
  const [selectedTab, setSelectedTab] = useState(null)
  const [selectedTabHome, setSelectedTabHome] = useState(null)
  const [steps, setSteps] = useState(null)
  const [popup, setPopup] = useState(false)

  const prevButton = () => {
    setStepsCompleted((prev) => prev - 1)
  }
  const nextButton = () => {
    setStepsCompleted((prev) => prev + 1)
  }

  const websiteTab = [
    {
      title: "Choose Category",
      component: (
        <CreateEvent
          selectTabFn={setSelectedTab}
          nextBtn={nextButton}
          selectedTab={selectedTabHome}
          setSelectedTab={setSelectedTabHome}
        />
      ),
    },
    // { title: "Finish Test", component: <Finish /> },
    {
      title: "Storage Details",
      component: <StorageInfoPage prevBtn={prevButton} nextBtn={nextButton} />,
    },
    {
      title: "Event Details",
      component: (
        <EventDetailsWebsiteLink prevBtn={prevButton} nextBtn={nextButton} />
      ),
    },
    { title: "Finish" },
  ]

  const mobileTab = [
    {
      title: "Choose Category",
      component: (
        <CreateEvent
          selectTabFn={setSelectedTab}
          nextBtn={nextButton}
          selectedTab={selectedTabHome}
          setSelectedTab={setSelectedTabHome}
        />
      ),
    },
    {
      title: "Choose Plan",
      component: (
        <ChooseYourPlan
          prevBtn={prevButton}
          nextBtn={nextButton}
          jump={setStepsCompleted}
          setPopup={setPopup}
        />
      ),
    },
    {
      title: "Payment",
      component: <Payment prevBtn={prevButton} nextBtn={nextButton} />,
    },
    {
      title: "Event Details",
      component: <EventDetails prevBtn={prevButton} nextBtn={nextButton} />,
    },
    {
      title: "Vendor Details",
      component: <VendorDetails prevBtn={prevButton} nextBtn={nextButton} />,
    },
    { title: "Finish", component: <Finish /> },
  ]

  // useEffect(() => {
  //   const res = JSON.parse(localStorage.getItem("PAYMENT_STATUS_FOREVER"))
  //   console.log(res)
  //   if (res) setStepsCompleted(3)
  // }, [])

  useEffect(() => {
    const setStepsData = () => {
      if (!selectedTab) return
      selectedTab === "Mobile App" ? setSteps(mobileTab) : setSteps(websiteTab)
    }
    setStepsData()
  }, [selectedTab])

  return (
    <div id="pagination" className="">
      {steps && (
        <div className="paginate" style={{ display: popup ? "none" : "flex" }}>
          {steps?.map((s, index) => (
            <div
              key={index}
              className="main-single-paginate-box"
              style={{ zIndex: 5000 }}
            >
              <div
                // onClick={() => setStepsCompleted(index)}
                style={{ cursor: "pointer" }}
                className="paginate-ball-text"
              >
                <div
                  className={`paginated-ball ${
                    index <= stepsCompleted && "golden-ball"
                  }`}
                ></div>
                <p className={`${index <= stepsCompleted && "golden-text"}`}>
                  {s?.title}
                </p>
              </div>
              {steps?.length > index + 1 && (
                <div
                  className={`paginate-line ${
                    index < stepsCompleted && "golden-ball"
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      )}

      {steps ? (
        <div className="paginated-component">
          {steps[stepsCompleted]?.component}
        </div>
      ) : (
        <CreateEvent
          selectTabFn={setSelectedTab}
          nextBtn={nextButton}
          selectedTab={selectedTabHome}
          setSelectedTab={setSelectedTabHome}
        />
      )}
    </div>
  )
}

export default Pagination
