// UploadProgressIndicator.js
import React from 'react';
import { useFileUpload } from './FileUploadContext';



const modalStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalContentStyles = {
  backgroundColor: "#fff",
  padding: "20px",
  borderRadius: "10px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  width: "90%",
  maxWidth: "500px",
  maxHeight: "90%",
  overflowY: "auto",
};

const uploadButtonStyles = {
  minWidth: "8rem",
  background: "#fbca7f",
  borderRadius: "25px",
  color: "#000",
  outline: "none",
  border: "none",
  padding: "10px 53px",
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "21.09px",
  textAlign: "right",
};

const fileInputStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "10px",
  padding: "15px 20px",
  marginTop: "25px",
  fontSize: "16px",
  borderRadius: "5px",
  border: "1px solid #ccc",
  background: "#f9f9f9",
  cursor: "pointer",
  textAlign: "center",
};

const hiddenFileInputStyles = {
  display: "none",
};

const uploadButtonModalStyles = {
  background: "#fbca7f",
  borderRadius: "25px",
  color: "#000",
  outline: "none",
  border: "none",
  padding: "10px 20px",
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "21.09px",
  textAlign: "center",
  marginTop: "20px",
};

const uploadProgressCircleStyles = {
  position: "relative",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "50%",
  border: "5px solid #000",
};

const fileListStyles = {
  listStyleType: "none",
  padding: 0,
  textAlign: "left",
  marginTop: "10px",
  maxHeight: "200px",
  overflowY: "auto",
};

const fileItemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "7px",
  marginBottom: "5px",
  border: "1px solid #ccc",
  borderRadius: "5px",
};

const removeButtonStyles = {
  background: "white",
  border: "1px solid #ccc",
  color: "black",
  fontSize: "16px",
  cursor: "pointer",
  borderRadius: "5px",
};

const uploadProgressStyles = {
  position: "fixed",
  bottom: "10px",
  right: "10px",
  width: "350px",
  maxHeight: "300px",
  overflowY: "scroll",
  backgroundColor: "#fff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  padding: "10px",
  zIndex: 1000,
  border: "1px solid #ccc",
};

const uploadProgressHeaderStyles = {
  margin: 0,
  marginBottom: "10px",
  fontSize: "14px",
  textAlign: "center",
  borderBottom: "1px solid #eee",
  paddingBottom: "5px",
};

const uploadProgressItemStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px",
  borderRadius: "4px",
  backgroundColor: "#f9f9f9",
  marginBottom: "5px",
  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
};

const fileNameStyles = {
  fontSize: "12px",
  fontWeight: "500",
  color: "#333",
};

const uploadProgressStatusStyles = {
  padding: "2px 6px",
  borderRadius: "12px",
  fontSize: "12px",
  color: "#fff",
};

const getStatusStyle = (status) => {
  switch (status) {
    case "success":
      return { ...uploadProgressStatusStyles };
    case "failed":
      return { ...uploadProgressStatusStyles };
    default:
      return { ...uploadProgressStatusStyles };
  }
};


const UploadProgressIndicator = () => {
  const { isUploading, fileUploadStatuses } = useFileUpload();

  if (!isUploading) return null;

  return (
      <div style={uploadProgressStyles}>
        {/* <h4 style={uploadProgressHeaderStyles}>Uploading Files</h4> */}
        {/* {fileUploadStatuses.map((fileStatus, index) => (
            <div key={index} style={uploadProgressItemStyles}>
              <span style={fileNameStyles}>{fileStatus.name}</span>
              <span style={getStatusStyle(fileStatus.status)}>
          {fileStatus.status === "success"
              ? "✔️"
              : fileStatus.status === "failed"
                  ? "❌"
                  : "⏳"}
        </span>
            </div>
        ))} */}
      </div>
  )}

export default UploadProgressIndicator;