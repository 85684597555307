import React from "react"
import LOADER from "../../assets/loader.svg"

const Loader = () => {
  return (
    <div >
      <img
        src={LOADER}
        alt="loader"
      />
    </div>
  )
}

export default Loader
