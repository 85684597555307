import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"

const PopEventId = () => {
  const location = useLocation()

  useEffect(() => {
    const clearEventKey = () => {
      if (location.pathname.includes("event")) return
      localStorage.removeItem("eventId")
      localStorage.removeItem("eventName")
      localStorage.removeItem("eventType")
    }
    clearEventKey()
  }, [location.pathname])

  return <div style={{ display: "none" }}>PopEventId</div>
}

export default PopEventId
