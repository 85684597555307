import React, { useState, useEffect, useRef, useContext } from "react";
import "./AllFiles.scss";
import {
  getAllFilesOfEvents,
  deleteFile,
  likeOrUnlikeFile,
  deleteFileList,
  getFoldersOfEvent,
  deleteFolder,
  getAllFilesOfFolder,
  moveFiles,
  getAllPublicFilesOfEvent,
  getGuestUploads,
  uploadFiles,
  moveToPhysicalAlbum,
} from "../../../api/api";
import tick from "../../../assets/CreateEvent/CheckOutline.svg";
import Box from "@mui/material/Box";
import Loader from "../../Loader/Loader";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
  IconButton,
} from "@mui/material";
import {
  Close,
  ArrowCircleLeft,
  ArrowCircleRight,
  RotateLeft,
  DeleteForever,
  FavoriteBorder,
  Favorite,
  Download,
  Window,
  Image,
  ViewList,
  Videocam,
  MoreVert,
  Info,
  Edit,
  Delete,
  DriveFileMove,
  ArrowBack,
  Folder,
  Lock,
  LockOpen,
  CellTower,
  AdminPanelSettings,
} from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import HighlightOffSharpIcon from "@mui/icons-material/HighlightOffSharp";
import { checkVideo } from "../../../utils/ckeckVideo";
import Checkbox from "@mui/material/Checkbox";
import JSZip, { folder } from "jszip";
import { Toaster, toast } from "sonner";
import CreateAlbumModal from "../CreateAlbumModal/CreateAlbumModal";
import downArrow from "../../../assets/Icons/downArrow.svg";
import albumView from "../../../assets/Icons/album.svg";
import download from "../../../assets/Icons/download.svg";
import deleteAlbum from "../../../assets/Icons/delete_album.svg";
import folderMove from "../../../assets/Icons/move_folder.svg";
import albumViewActive from "../../../assets/Icons/album_active.svg";
import downloadActive from "../../../assets/Icons/download_active.svg";
import deleteAlbumActive from "../../../assets/Icons/delete_album_active.svg";
import folderMoveActive from "../../../assets/Icons/move_folder_active.svg";
import defaultFolder from "../../../assets/Gallery/defaultFolder.svg";
import { useFileUpload } from "../../Home/FileUploadContext";
import { Value } from "sass"
// import downArrow from '../../../assets/Icons/downArrow.svg'

const style = {
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  // transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100vh",
  bgcolor: "#000",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25%",
  // height: "30%",
  bgcolor: "#26272C",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const deleteBulkStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "30%",
  // height: "30%",
  bgcolor: "#26272C",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: 10,
};
const moveStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  // height: "70%",
  bgcolor: "#1A1C21",
  border: "2px solid #000",
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
  color: "#fff",
  overflowY: "scroll",
};
const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const closeButtonContainerStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  color: "#fff",
};

const closeButtonStyles = {
  cursor: "pointer",
  fontSize: "24px",
};

const modalStyles = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const uploadStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "40%",
  // height: "40%",
  bgcolor: "#26272C",
  border: "2px solid #000",
  boxShadow: 24,
  p: 5,
  borderRadius: 10,
};

const modalContentStyles = {
  background: "#1A1C21",
  padding: "20px",
  borderRadius: "25px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  width: "90%",
  maxWidth: "500px",
  maxHeight: "90%",
  overflowY: "auto",
};

const uploadButtonStyles = {
  minWidth: "8rem",
  background: "#fbca7f",
  borderRadius: "25px",
  color: "#000",
  outline: "none",
  border: "none",
  padding: "10px 53px",
  fontFamily: "Roboto",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "21.09px",
  textAlign: "right",
};

const fileInputStyles = {
  display: "block",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "20px",
  padding: "25px 30px",
  marginTop: "25px",
  fontSize: "16px",
  borderRadius: "25px",
  border: "1px solid #ccc",
  background: "#f9f9f9",
  cursor: "pointer",
  textAlign: "center",
};

const hiddenFileInputStyles = {
  display: "none",
};

const fileListStyles = {
  listStyleType: "none",
  padding: 10,
  textAlign: "left",
  marginTop: "10px",
  maxHeight: "200px",
  overflowY: "auto",
  borderRadius: "10px",
  background: "#26272C80",
};

const fileItemStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "7px",
  marginBottom: "5px",
  fontSize: "14px",
  // border: "1px solid #000",
  borderRadius: "10px",
  color: "#fff",
};

const removeButtonStyles = {
  background: "#1A1C21",
  border: "1px solid #000",
  color: "white",
  fontSize: "14px",
  cursor: "pointer",
  borderRadius: "5px",
};

const uploadProgressStyles = {
  position: "fixed",
  bottom: "10px",
  right: "10px",
  width: "350px",
  maxHeight: "300px",
  overflowY: "scroll",
  backgroundColor: "#fff",
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  borderRadius: "8px",
  // padding: "10px",
  zIndex: 1000,
  border: "1px solid #ccc",
};

const uploadProgressHeaderStyles = {
  margin: 0,
  marginBottom: "10px",
  fontSize: "14px",
  textAlign: "center",
  // borderBottom: "1px solid #eee",
  paddingBottom: "5px",
};

const uploadProgressItemStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px",
  borderRadius: "4px",
  backgroundColor: "#f9f9f9",
  marginBottom: "5px",
  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
};

const fileNameStyles = {
  fontSize: "12px",
  fontWeight: "500",
  color: "#333",
};

const uploadProgressStatusStyles = {
  padding: "2px 6px",
  borderRadius: "12px",
  fontSize: "12px",
  color: "#fff",
};

const getStatusStyle = (status) => {
  switch (status) {
    case "success":
      return { ...uploadProgressStatusStyles };
    case "failed":
      return { ...uploadProgressStatusStyles };
    default:
      return { ...uploadProgressStatusStyles };
  }
};

const pageSize = 20;
const AllFiles = ({
  rootLocation,
  setRootLocation,
  setInsideGalleryFolder,
}) => {
  const [fromTop, setFromTop] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [responseData, setResponseData] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [fullScreen, setFullScreen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [rotateAngle, setRotateAngle] = useState(0);
  const [open, setOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [likeUnlikeRes, setLikeUnlikeRes] = useState(null);
  const [deleteBulkOpen, setDeleteBulkOpen] = useState(false);
  const [createAlbumModalOpen, setCreateAlbumModalOpen] = useState(false);
  const [deleteFolderOpen, setDeleteFolderOpen] = useState(false);
  const [uploadFilesOpen, setUploadFilesOpen] = useState(false)
  const [selectedFolder, setSelectedFolder] = useState([]);
  const [albumTitle, setAlbumTitle] = useState("");
  const [moveFileModal, setMoveFileModal] = useState(false);
  const [gridView, setGridView] = useState(true);
  const [listView, setListView] = useState(false);
  const [sortView, setSortView] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [uploadMessage, setUploadMessage] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  // const [fileUploadStatuses, setFileUploadStatuses] = useState([]);
  const [uploadingFilesApi, setUploadingFilesApi] = useState(false);
  const [selectedImageIds, setSelectedImageIds] = useState([]);  
  const [bulkApiCall, setBulkApiCall] = useState(false);
  // const [isUploading, setUploading] = useState(false);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [totalFiles, setTotalFiles] = useState(0);
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
  const [lastShiftSelectedIndex, setLastShiftSelectedIndex] = useState(null);
  const [isShiftPressed, setIsShiftPressed] = useState(false);
  const [noFiles, setNoFiles] = useState(false);
  const [uploadQueue, setUploadQueue] = useState([]);
  const [showUploadData, setShowUploadData] = useState(true)
  const [displayCompressAndUpload, setDisplayCompressAndUpload] = useState(false)
  const [eventType, setEventType] = useState("")

  const imageRef = useRef(null);
  const containerRef = useRef(null);
  const fileInputRef = useRef(null);

  useEffect(() => {
    if(rootLocation) setSelectedFolder([])
  }, [rootLocation])

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (containerRef.current && !containerRef.current.contains(event.target)) {
  //       deselectAllImages();
  //     }
  //   };
  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);

  const {
    isUploading,
    fileUploadStatuses,
    setUploading,
    setFileUploadStatuses,
  } = useFileUpload();

  const handleImageIds = (id) => {
    const isPresent = selectedImageIds?.find((item) => item === id);
    if (isPresent)
      setSelectedImageIds((prev) => prev?.filter((item) => item !== id));
    else setSelectedImageIds((prev) => [...prev, id]);
  };

  const toggleDrawer = (isOpen) => () => {
    setDrawerOpen(isOpen);
  };

  const handleRemoveFile = (index) => {
    setUploadedFiles((prevFiles) => {
      const updatedFiles = Array.from(prevFiles);
      updatedFiles.splice(index, 1);
      return updatedFiles;
    });
  };

  const handleUpload = async () => {
    setUploadFilesOpen(true)
    // fileInputRef.current.click()
  };

  const handleFileChange = (event) => {
    const files = event.target.files;
    if (!files) {
      setUploadMessage("Please select files to upload.");
      return;
    }

    setUploadedFiles(files);
    setTotalFiles(files.length);
  };

  const updateFileStatus = (index, status) => {
    setFileUploadStatuses((prevStatuses) => {
      const newStatuses = [...prevStatuses];
      newStatuses[index].status = status;
      return newStatuses;
    });
  };

  const processQueue = async (currentQueueItem) => {
    if(isUploading) return;
    console.log("Processing queue item:", currentQueueItem);
    setUploading(true)

    let successfulUploads = 0
    let onQueue = currentQueueItem.files
    let params = currentQueueItem.params

    setFileUploadStatuses([])
    setFileUploadStatuses(
        onQueue.map((file) => ({ name: file.name, status: "pending" }))
    )
    console.log(fileUploadStatuses)

    for (let i = 0; i < onQueue.length; i++) {
      console.log(`Uploading file ${i + 1}:`, onQueue[i].name);
      const singleFileFormData = new FormData()
      singleFileFormData.append("files", onQueue[i])
      params.folder = folder
      try {
        const res = await uploadFiles(params, singleFileFormData)
        // console.log(`Upload response for file ${i + 1}:`, res);
        if (res?.status === "OK") {
          successfulUploads++
          updateFileStatus(i, "success")
          setUploadedCount(successfulUploads)
          // console.log(`Successfully uploaded file ${i + 1}`)
        } else {
          updateFileStatus(i, "failed")
          console.error("Error uploading file", onQueue[i])
        }
      } catch (error) {
        updateFileStatus(i, "failed")
        console.error("Error uploading file", onQueue[i], error)
      }
    }

    if (successfulUploads === onQueue.length) {
      toast.success("All files uploaded successfully!")
      console.log("All files uploaded successfully")
    } else {
      setUploadMessage(
          `${successfulUploads} out of ${uploadedFiles.length} files uploaded successfully`
              `${successfulUploads} out of ${onQueue.length} files uploaded successfully`
      )
    }
  }

  useEffect(() => {
    const userInfo = localStorage.getItem("key")
    const eventType = localStorage.getItem("eventType") || ""
    setEventType(eventType)
    if ((JSON.parse(userInfo)?.body?.id === "41096a4b-7785-4454-8caf-d1f506c0a600" || JSON.parse(userInfo)?.body?.id === "a3143ced-f58e-4d10-a878-5984af4d3890") && JSON.parse(eventType) === "WEBAPP_SUBSCRIPTION_EVENT") {
      setDisplayCompressAndUpload(true)
    }
  },[])

  const resizeImageToHalf = (file) => {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        // Create a canvas to draw the image at half size
        const canvas = document.createElement('canvas');
        canvas.width = img.width / 2;
        canvas.height = img.height / 2;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Convert the canvas back to a Blob and then to a File
        canvas.toBlob((blob) => {
          if (blob) {
            const resizedFile = new File([blob], file.name, { type: file.type });
            resolve(resizedFile);
          } else {
            reject(new Error("Canvas conversion to blob failed"));
          }
        }, file.type);
      };
      img.onerror = (error) => reject(error);
    });
  };

  const compressAndUploadFiles = async () => {
    try {
      const newFilesArray = Array.from(uploadedFiles);
      const resizedFiles = await Promise.all(
          newFilesArray.map(async (file) => {
            const fileExtension = file.name.split('.').pop().toLowerCase();
            const validExtensions = ["jpg", "png", "jpeg"];
            if (validExtensions.includes(fileExtension)) {
              console.log("Resizing image");
              const resizedFile = await resizeImageToHalf(file);
              return resizedFile;
            }
            return file;
          })
      );

      // Update the uploadedFiles state with resized files
      setUploadedFiles(resizedFiles);
      finallyUploadCompressedFiles(resizedFiles);

    } catch (error) {
      console.error("Error during compression and upload process", error);
    }
  };

  const finallyUploadCompressedFiles = (resizedFiles) => {
    try{

      const storedObjectString = localStorage.getItem("key")
      const storedObject = JSON.parse(storedObjectString)
      const storedEventId = localStorage.getItem("eventId")

      let params = {
        userId: storedObject.body.id,
        eventId: storedEventId,
        folderId: selectedFolder?.id,
      }

      let folderId = params.folderId
      setUploadQueue(prevQueue => [...prevQueue, {
        folder: params.folderId,
        files: resizedFiles,
        params
      }]);

      setUploadedFiles([])
      setUploadFilesOpen(false)

    } catch(error){
      console.error("Error during file upload process",error)
    }
  }


  // finally uploading file
  const finallyUploadFiles = () => {
    try{

      const storedObjectString = localStorage.getItem("key")
      const storedObject = JSON.parse(storedObjectString)
      const storedEventId = localStorage.getItem("eventId")

      let params = {
        userId: storedObject.body.id,
        eventId: storedEventId,
        folderId: selectedFolder?.id,
      }

      let folderId = params.folderId
      const newFilesArray = Array.from(uploadedFiles);
      setUploadQueue(prevQueue => [...prevQueue, {
        folder: params.folderId,
        files: newFilesArray,
        params
      }]);

      setUploadedFiles([])
      setUploadFilesOpen(false)

    } catch(error){
      console.error("Error during file upload process",error)
    }
  }

  const handleOpen = (index) => {
    setSelectedItem(responseData[index]);
    setSelectedImageIndex(index);
    setLikeUnlikeRes(responseData[index].liked);
    setOpen(true);
    setFullScreen(true);
  };
  const handleClose = () => {
    setFullScreen(false);
    setOpen(false);
    setRotateAngle(0);
  };

  const handleDeleteOpen = () => {
    setDeleteOpen(true);
  };
  const handleDeleteClose = () => {
    setDeleteOpen(false);
  };

  const handleDeleteBulkOpen = () => {
    setDeleteBulkOpen(true);
  };

  const handleDeleteBulkClose = () => {
    setDeleteBulkOpen(false);
  };
  const handleDeleteFolderOpen = () => {
    setDeleteFolderOpen(true);
  };

  const handleMoveClose = () => {
    setMoveFileModal(false);
  };
  const handleMoveOpen = () => {
    setMoveFileModal(true);
  };

  const handleDeleteFolderClose = () => {
    setDeleteFolderOpen(false);
    setSelectedFolder(null);
    handleCloseOption();
  };

  const handlePrevImage = () => {
    let newIndex = selectedImageIndex - 1;
    while (
      newIndex >= 0 &&
      sortView === 1 &&
      checkVideo(responseData[newIndex]?.url)
    ) {
      newIndex--;
    }
    while (
      newIndex >= 0 &&
      sortView === 2 &&
      !checkVideo(responseData[newIndex]?.url)
    ) {
      newIndex--;
    }
    if (newIndex >= 0) {
      setSelectedImageIndex(newIndex);
      setSelectedItem(responseData[newIndex]);
      setLikeUnlikeRes(responseData[newIndex].liked);
    }
  };

  const handleNextImage = () => {
    let newIndex = selectedImageIndex + 1;
    while (
      newIndex < responseData.length &&
      sortView === 1 &&
      checkVideo(responseData[newIndex]?.url)
    ) {
      newIndex++;
    }
    while (
      newIndex < responseData.length &&
      sortView === 2 &&
      !checkVideo(responseData[newIndex]?.url)
    ) {
      newIndex++;
    }
    if (newIndex < responseData.length) {
      setSelectedImageIndex(newIndex);
      setSelectedItem(responseData[newIndex]);
      setLikeUnlikeRes(responseData[newIndex].liked);
    }
  };

  const handleChangeView = () => {
    if (gridView) {
      viewList();
    } else {
      viewGrid();
    }
  };

  const updateMaxHeight_GALLERy = () => {
    if (containerRef.current) {
      const distanceFromTop = containerRef.current.getBoundingClientRect().top;
      const screenHeight = window.innerHeight;
      const maxHeight = screenHeight - distanceFromTop - 16;
      containerRef.current.style.maxHeight = `${maxHeight}px`;
    }
  };

  useEffect(() => {
    updateMaxHeight_GALLERy();
    window.addEventListener("resize", updateMaxHeight_GALLERy);

    return () => {
      window.removeEventListener("resize", updateMaxHeight_GALLERy);
    };
  }, []);

  // useEffect(() => {
  //   const storedId = localStorage.getItem('eventId');
  //   const storedKey = localStorage.getItem('key');
  //   const userId = JSON.parse(storedKey).body.id;
  //   if (storedId && currentPage === 1) {
  //     setLoading(true);
  //     getAllFilesOfEvents(storedId, userId).then((response) => {
  //       if (!response.error) {
  //         setResponseData(response.body);
  //       }
  //       setLoading(false);
  //     });
  //   }
  // }, [currentPage]);

  // handle height from top
  useEffect(() => {
    const storageElement = document.getElementById("all-files-event");
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top);
    } else {
      console.error("Element with ID 'album-access' not found.");
    }
  }, []);

  useEffect(() => {
    const storedId = localStorage.getItem("eventId");
    localStorage.removeItem("folderId");
    if (storedId && currentPage === 1) {
      setLoading(true);
      getFoldersOfEvent(storedId).then((response) => {
        if (!response?.error) {
          setFolderData(response?.body);
        }
        setLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (
        containerRef.current &&
        containerRef.current.scrollHeight - containerRef.current.scrollTop ===
          containerRef.current.clientHeight &&
        !loading
      ) {
        setCurrentPage((prevPage) => prevPage + 1);
      }
    };

    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loading]);

  useEffect(() => {
    const resizeHandler = () => {
      if (imageRef.current) {
        const containerWidth = imageRef.current.offsetWidth;
        imageRef.current.style.maxWidth = `${containerWidth}px`;
      }
    };

    if (imageRef.current) {
      window.addEventListener("resize", resizeHandler);
    }

    return () => {
      if (imageRef.current) {
        window.removeEventListener("resize", resizeHandler);
      }
    };
  }, []);

  const handleDelete = () => {
    const storedKey = localStorage.getItem("key");
    const userId = JSON.parse(storedKey).body.id;
    const fileId = selectedItem.id;
    const body = {
      userId: userId,
      fileId: fileId,
    };

    handleDeleteClose();

    let nextIndex = selectedImageIndex;

    while (
      nextIndex + 1 < responseData.length &&
      sortView === 1 &&
      checkVideo(responseData[nextIndex + 1]?.url)
    ) {
      nextIndex++;
    }
    while (
      nextIndex < responseData.length &&
      sortView === 2 &&
      !checkVideo(responseData[nextIndex]?.url)
    ) {
      nextIndex++;
    }

    if (nextIndex + 1 < responseData.length) {
      setSelectedImageIndex(nextIndex + 1);
      setSelectedItem(responseData[nextIndex + 1]);
      setLikeUnlikeRes(responseData[nextIndex + 1].liked);
      // return;
    }

    let prevIndex = selectedImageIndex - 1;

    while (
      prevIndex >= 0 &&
      sortView === 1 &&
      checkVideo(responseData[prevIndex]?.url)
    ) {
      prevIndex--;
    }
    while (
      prevIndex >= 0 &&
      sortView === 2 &&
      !checkVideo(responseData[prevIndex]?.url)
    ) {
      prevIndex--;
    }

    if (prevIndex > 0) {
      setSelectedImageIndex(prevIndex);
      setSelectedItem(responseData[prevIndex]);
      setLikeUnlikeRes(responseData[prevIndex].liked);
    }

    toast.success("File deleted successfully")

    deleteFile(body).then((response) => {
      if (!response.error) {
        setResponseData(
          responseData.filter((item) => item.id !== response.body.id)
        );
      }
    });
  };

  const handleLikeOrUnlike = () => {
    const storedKey = localStorage.getItem("key");
    const userId = JSON.parse(storedKey).body.id;
    const fileId = selectedItem.id;
    const body = {
      userId: userId,
      fileId: fileId,
    };
    likeOrUnlikeFile(body).then((response) => {
      console.log(response.body);
      responseData[selectedImageIndex] = {
        ...responseData[selectedImageIndex],
        liked: response.body.liked,
      };
      setLikeUnlikeRes(response.body.liked);
    });
  };

  const handleDownload = () => {
    if (selectedItem) {
      const anchor = document.createElement("a");
      anchor.href = selectedItem.url;
      anchor.download = selectedItem.name;
      anchor.click();
    }
  };

  const viewGrid = () => {
    setGridView(true);
    setListView(false);
  };

  const viewList = () => {
    setGridView(false);
    setListView(true);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getUTCDate()).padStart(2, "0");
    const month = String(date.getUTCMonth() + 1).padStart(2, "0");
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const extractType = (fileName) => {
    const parts = fileName.split(".");
    const afterDot = parts[1];
    return afterDot;
  };

  useEffect(() => {
    const detectKeyDown = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(true);
      }
    };

    const detectKeyUp = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    document.addEventListener("keydown", detectKeyDown, true);
    document.addEventListener("keyup", detectKeyUp, true);

    return () => {
      document.removeEventListener("keydown", detectKeyDown, true);
      document.removeEventListener("keyup", detectKeyUp, true);
    };
  }, []);

  useEffect(() => {
    const detectKeyDown = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(true);
      }
    };

    const detectKeyUp = (e) => {
      if (e.key === "Shift") {
        setIsShiftPressed(false);
      }
    };

    document.addEventListener("keydown", detectKeyDown, true);
    document.addEventListener("keyup", detectKeyUp, true);

    return () => {
      document.removeEventListener("keydown", detectKeyDown, true);
      document.removeEventListener("keyup", detectKeyUp, true);
    };
  }, []);

  useEffect(() => {
    const processNextQueueItem = async () => {
      if (!isUploading && uploadQueue.length > 0) {
        const nextQueueItem = uploadQueue[0];
        await processQueue(nextQueueItem);
        setUploadQueue(prevQueue => prevQueue.slice(1));
        setUploading(false);
      }
    };
    processNextQueueItem();
  }, [isUploading, uploadQueue]);

  const handleSelect = (index, event) => {
    const imageId = responseData[index].id;

    if (isShiftPressed && lastSelectedIndex !== null) {
      const start = Math.min(lastSelectedIndex, index);
      const end = Math.max(lastSelectedIndex, index);

      const rangeSelectedItems = Array.from(
        { length: end - start + 1 },
        (_, i) => start + i
      );

      setSelectedItems((prevSelectedItems) => {
        const newSelection = new Set([
          ...prevSelectedItems,
          ...rangeSelectedItems,
        ]);
        return Array.from(newSelection);
      });

      const rangeImageIds = responseData
        .slice(start, end + 1)
        .map((item) => item.id);
      setSelectedImageIds((prev) => [...new Set([...prev, ...rangeImageIds])]);
    } else {
      setSelectedItems((prevSelectedItems) => {
        if (prevSelectedItems.includes(index)) {
          return prevSelectedItems.filter((item) => item !== index);
        } else {
          return [...prevSelectedItems, index];
        }
      });

      if (selectedImageIds.includes(imageId)) {
        setSelectedImageIds((prev) => prev.filter((id) => id !== imageId));
      } else {
        setSelectedImageIds((prev) => [...prev, imageId]);
      }
    }

    if (!isShiftPressed) {
      setLastSelectedIndex(index);
    }
  };

  const handleSelectAll = (event) => {
    setSelectAllChecked(event.target.checked);
    if (event.target.checked) {
      const allIndices = responseData.map((_, index) => index);
      setSelectedItems(allIndices);
    } else {
      setSelectedItems([]);
    }
  };

  const selectAllImages = () => {
    const allImageIds = responseData.map((item) => item.id);
    setSelectedImageIds(allImageIds);
    const allIndexes = responseData.map((_, index) => index);
    setSelectedItems(allIndexes);
  };

  const deselectAllImages = () => {
    setSelectedImageIds([]);
    setSelectedItems([]);
  };

  const anyImagesSelected = selectedItems.length > 0;

  const handleBulkDownload = () => {
    if (selectedItems.length > 0) {
      const zip = new JSZip();
      let imagesProcessed = 0;

      selectedItems.forEach(async (selectedIndex) => {
        const url = responseData[selectedIndex]?.url;
        const name = responseData[selectedIndex]?.name;
        fetch(url)
          .then((response) => response.blob())
          .then((blob) => {
            const anchor = document.createElement("a");
            anchor.href = URL.createObjectURL(blob);
            anchor.download = name;
            anchor.click();
          })
          .catch((error) => {
            console.error("Error downloading image:", error);
            imagesProcessed++;
          });
      });
    }
  };

  const handleDeleteBulk = () => {
    setBulkApiCall(true);
    const storedKey = localStorage.getItem("key");
    const userId = JSON.parse(storedKey).body.id;
    // let fileIdArray = []
    // if (selectedItems.length > 0) {
    //   selectedItems.forEach((selectedIndex) => {
    //     fileIdArray = [...fileIdArray, responseData[selectedIndex]?.id]
    //   })
    // }
    const body = {
      userId: userId,
      fileIds: selectedImageIds,
    };
    
    deleteFileList(body).then((response) => {
      if (!response?.error) {
        let modifyData = responseData?.filter(
          (item) => !selectedImageIds?.includes(item?.id)
        );
        setResponseData(modifyData);
        setSelectedItems([]);
        setSelectedImageIds([]);
        setBulkApiCall(false);
        handleDeleteBulkClose();
        handleDeleteClose()
      }
      toast.success("File deleted successfully")
    });
  };

  const handleMoveToPhysicalAlbumBulk = () => {
    setBulkApiCall(true);
    const storedKey = localStorage.getItem("key");
    const userId = JSON.parse(storedKey).body.id;
    const body = {
      userId: userId,
      fileIds: selectedImageIds,
      addToPhysicalAlbum: true,
    };
    moveToPhysicalAlbum(body).then((response) => {
      if (!response.error) {
        // let modifyData = responseData.filter(
        //   (item) => !selectedImageIds.includes(item.id)
        // );
        // setResponseData(modifyData);
        setSelectedItems([]);
        setSelectedImageIds([]);
        setBulkApiCall(false);
        handleDeleteBulkClose();
      }
      toast.success("File/s moved to Physical Album")
    });
  };

  const handleClickOpenOption = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseOption = () => {
    setAnchorEl(null);
    setSelectedFolder([]);
  };

  const handleDeleteFolder = () => {
    const storedKey = localStorage.getItem("key");
    const userId = JSON.parse(storedKey).body.id;
    let requestBody = {
      id: selectedFolder.id,
      userId: userId,
      eventId: selectedFolder.eventId,
    };
    deleteFolder(requestBody).then((responseData) => {
      if (responseData?.error == null) {
        handleDeleteFolderClose();
        setFolderData(responseData?.body);
      }
    });
  };

  const openFolder = (folderData) => {
    setNoFiles(false);
    setRootLocation(false);
    const storedKey = localStorage.getItem("key");
    const userId = JSON.parse(storedKey).body.id;
    localStorage.setItem("folderId", folderData.id);
    setSelectedItems([]);
    getAllFilesOfFolder(userId, folderData.id).then((res) => {
      console.log("folder photos::", res.body);
      if (res.body.length === 0) setNoFiles(true);
      setResponseData(res.body);
    });
  };

  const openAllPublicFilesOfEvent = () => {
    setNoFiles(false);
    setRootLocation(false);
    const storedId = localStorage.getItem("eventId");
    setSelectedItems([]);
    getAllPublicFilesOfEvent(storedId).then((response) => {
      console.log("folder photos::", response.body);
      if (response.body.length === 0) setNoFiles(true);
      setResponseData(response.body);
    });
  };

  const openGuestUploads = () => {
    setNoFiles(false);
    setRootLocation(false);
    const storedId = localStorage.getItem("eventId");
    setSelectedItems([]);
    getGuestUploads(storedId).then((response) => {
      if (response.body.length === 0) setNoFiles(true);
      setResponseData(response.body);
    });
  };

  const handleMove = (folderData) => {
    setBulkApiCall(true);
    const storedKey = localStorage.getItem("key");
    const userId = JSON.parse(storedKey).body.id;
    let requestBody = {
      userId: userId,
      folderId: folderData?.id,
      fileIds: selectedImageIds,
    };
    moveFiles(requestBody).then((response) => {
      if (response.error == null) {
        handleMoveClose();
        let modifyData = responseData.filter(
          (item) => !selectedImageIds.includes(item.id)
        );
        setResponseData(modifyData);
        setSelectedItems([]);
        setSelectedImageIds([]);
        setBulkApiCall(false);
      }
      toast.success("File/s moved successfully!")
    });
  };

  // clear folder photos
  useEffect(() => {
    if(rootLocation )
    setResponseData([])
  }, [rootLocation])

  // set no files

  useEffect(() => {
    if(responseData?.length > 0) return
    setNoFiles(true)
  }, [responseData])  

  return (
    <div
      id="all-files-event"
      // style={{
      //   minHeight: `calc(100vh - ${fromTop}px - 16px)`,
      // }}
    >
      {/* delete bulk modal */}
      <Modal
        open={deleteBulkOpen}
        onClose={handleDeleteBulkClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteBulkStyle}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-end">
              <Close
                className="action-button"
                onClick={handleDeleteBulkClose}
              />
            </div>
            <div className="d-flex flex-column mt-3">
              <span className="delete-head">Delete Items?</span>
              <span className="delete-text mt-2">
                Deleting an Item is permanent. Do you want to delete all the
                selected Items?
              </span>
              <div className="d-flex mt-5">
                <button
                  type="button"
                  className="btn delete-btn me-4"
                  onClick={handleDeleteBulk}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="btn cancel-btn"
                  onClick={handleDeleteBulkClose}
                  style={{ background: "black" }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {/* delete folder modal */}
      <Modal
        open={deleteFolderOpen}
        onClose={handleDeleteFolderClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={deleteBulkStyle}>
          <div className="d-flex flex-column">
            <div className="d-flex flex-column mt-3">
              <span className="delete-head">Delete Folder</span>
              <span className="delete-text mt-2">
                Are you sure you want to delete this folder permanently?
              </span>
              <div className="d-flex mt-5" style={{ justifyContent: "center" }}>
                <button
                  type="button"
                  className="cancel-btn me-3 d-flex justify-content-center align-items-center"
                  style={{ background: "transparent" }}
                  onClick={handleDeleteFolderClose}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="delete-btn me-3 d-flex justify-content-center align-items-center"
                  onClick={handleDeleteFolder}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={moveFileModal}
        onClose={handleMoveClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={moveStyle}>
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-center">
              <span className="move-folder-title mb-4">Move to Folder</span>
            </div>
            <span className="move-folder-name mb-4">
              Current Folder: {selectedFolder.name}
            </span>
            {folderData?.map((item, index) => (
              <div
                key={index}
                className="d-flex move-folder-container justify-content-between align-items-center px-2"
                onClick={() => {
                  handleMove(folderData[index]);
                }}
              >
                <span className="move-folder-name">{item.name}</span>
                <DriveFileMove className="action-button" />
              </div>
            ))}
          </div>
        </Box>
      </Modal>

      {/* Upload Files Modal */}
      <Modal
        open={uploadFilesOpen}
        onClose={()=>uploadedFiles?.length > 0 ? finallyUploadFiles() : setUploadFilesOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={uploadStyle}>
            <div style={closeButtonContainerStyles}>
              <HighlightOffSharpIcon
                onClick={() => {
                  setUploadFilesOpen(false);
                  setUploadedFiles([]);
                  setTotalFiles(0);
                }}
                style={closeButtonStyles}
              />
            </div>

            <div className="file-upload-container">
              <label
                htmlFor="file-upload"
                className="file-upload-label"
                style={fileInputStyles}
              >
                <div>
                  <CloudUploadIcon />
                </div>
                Click to select files or drag and drop
                <input
                  id="file-upload"
                  type="file"
                  multiple
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  style={hiddenFileInputStyles}
                  accept="image/*, video/*"
                />
              </label>
            </div>
            {uploadedFiles.length > 0 && (
              <div>
                <span
                  style={{
                    color: "#fff",
                    fontSize: "24px",
                    fontWeight: "600",
                  }}
                >
                  Selected Files:
                </span>
                <ul style={fileListStyles}>
                  {Array.from(uploadedFiles)?.map((file, index) => (
                    <li key={index} style={fileItemStyles}>
                      {file.name}
                      <button
                        type="button"
                        style={removeButtonStyles}
                        onClick={() => {
                          setUploadedFiles((prev) =>
                            Array.from(prev).filter((_, i) => i !== index)
                          );
                          setFileUploadStatuses((prevStatuses) =>
                            prevStatuses.filter((_, i) => i !== index)
                          );
                        }}
                      >
                        &times;
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            )}

            <button
              className="upload-btn-modal"
              style={{
                padding: "10px 35px",
                cursor:
                  uploadedFiles.length === 0 ? "not-allowed" : "pointer",
                  borderRadius: "25px",
                  width: '100%'
              }}
              onClick={finallyUploadFiles}
              type="button"
              disabled={uploadedFiles.length === 0}
            >
              Upload
            </button>
            {displayCompressAndUpload && (
              <button
                  className="upload-btn-modal"
                  style={{
                    marginTop: "10px",
                    padding: "10px 35px",
                    cursor: uploadedFiles.length === 0 ? "not-allowed" : "pointer",
                    borderRadius: "25px",
                    width: '100%'
                  }}
                  onClick={compressAndUploadFiles}
                  type="button"
                  disabled={uploadedFiles.length === 0}
              >
                Compress & Upload <br></br> WARNING! ORIGINAL FILES WILL NOT BE UPLOADED
              </button>
            )}
        </Box>
      </Modal>

      {fullScreen && (
        <>
          <Drawer
            anchor="right"
            open={drawerOpen}
            onClose={toggleDrawer(false)}
            className="custom-drawer"
            style={{ zIndex: 1500 }}
            sx={{
              "& .MuiDrawer-paper": {
                width: "30%",
                backgroundColor: "#26272C",
              },
            }}
          >
            <div className="d-flex flex-column p-5 drawer-box">
              <div className="d-flex justify-content-between">
                <span className="drawer-header">File Information</span>
                <Close
                  className="icon-color-gold pointer"
                  fontSize="large"
                  onClick={toggleDrawer(false)}
                />
              </div>
              <div className="photo-card mt-5">
                {selectedItem && checkVideo(selectedItem.url) ? (
                  <video
                    src={selectedItem.url}
                    controls
                    className="cover-photo"
                  />
                ) : selectedItem ? (
                  <img
                    src={selectedItem.url}
                    alt="compressedUrl"
                    className="cover-photo"
                  />
                ) : null}
              </div>
              <div className="d-flex flex-column mt-4">
                <span className="drawer-info-header">File Name</span>
                <span className="drawer-info-content">{selectedItem.name}</span>

                <span className="drawer-info-header">File Type</span>
                <span className="drawer-info-content">
                  {extractType(selectedItem.name)}
                </span>

                {selectedItem.date && (
                  <>
                    <span className="drawer-info-header mt-2">
                      Updated Date
                    </span>
                    <span className="drawer-info-content">
                      {formatDate(selectedItem.date)}
                    </span>
                  </>
                )}
              </div>
            </div>
          </Drawer>

          {/* show picture and video */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{...style,}}>
              <div className="d-flex justify-content-end">
                <div className="d-flex justify-content-center align-items-center action-button-box me-3">
                  <DeleteForever
                    className="action-button"
                    onClick={handleDeleteOpen}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center action-button-box me-3">
                  {/* {likeUnlikeRes === true ? (
                    <Favorite
                      className="action-button action-button-heart"
                      onClick={handleLikeOrUnlike}
                    />
                  ) : (
                    <FavoriteBorder
                      className="action-button"
                      onClick={handleLikeOrUnlike}
                    />
                  )} */}
                  <img
                    title="Move To"
                    onClick={ async() =>{
                      setSelectedImageIds([selectedItem?.id]);
                      handleMoveOpen();
                    }}
                    src={folderMoveActive}
                    alt="active"
                    style={{ cursor: "pointer", height: '24px', width: '24px' }}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center action-button-box me-3">
                  <Info
                    className="action-button"
                    onClick={toggleDrawer(true)}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center action-button-box me-3">
                  <Download
                    className="action-button"
                    onClick={handleDownload}
                  />
                </div>
                <div className="d-flex justify-content-center align-items-center action-button-box">
                  <Close className="action-button" onClick={handleClose} />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between custom-carousel">
                <ArrowCircleLeft className="arrow" onClick={handlePrevImage} style={{marginRight: '20px', scale: '1.5'}} />
                {/* all */}
                {sortView === 0 ? (
                  <div
                    className="selected-image-box"
                    style={{ transform: `rotate(${rotateAngle}deg)`, height: '97%', alignItems:'normal' }}
                  >
                    {selectedItem && checkVideo(selectedItem.url) ? (
                      <video
                        ref={imageRef}
                        src={selectedItem.compressedUrl ? selectedItem.compressedUrl :selectedItem.url}
                        controls
                        className="selected-video"
                      />
                    ) : selectedItem ? (
                      <img
                        ref={imageRef}
                        src={selectedItem.compressedUrl ? selectedItem.compressedUrl :selectedItem.url}
                        alt="compressedUrl"
                        className="selected-video"
                      />
                    ) : null}
                  </div>
                ) : null}
                {/* photo */}
                {sortView === 1 && !checkVideo(selectedItem.url) ? (
                  <div
                    className="selected-image-box"
                    style={{ transform: `rotate(${rotateAngle}deg)` }}
                  >
                    {selectedItem ? (
                      <img
                        ref={imageRef}
                        src={selectedItem.compressedUrl ? selectedItem.compressedUrl :selectedItem.url}
                        alt="compressedUrl"
                        className="selected-image"
                      />
                    ) : null}
                  </div>
                ) : null}

                {/* video */}
                {sortView === 2 && checkVideo(selectedItem.url) ? (
                  <div
                    className="selected-image-box"
                    style={{ transform: `rotate(${rotateAngle}deg)` }}
                  >
                    {selectedItem ? (
                      <video
                        ref={imageRef}
                        src={selectedItem.compressedUrl ? selectedItem.compressedUrl :selectedItem.url}
                        controls
                        className="selected-video"
                      />
                    ) : null}
                  </div>
                ) : null}
                <ArrowCircleRight className="arrow" onClick={handleNextImage} style={{marginLeft: '20px', scale: '1.5'}} />
              </div>
            </Box>
          </Modal>

          {/* delete one item modal */}
          <Modal
            open={deleteOpen}
            onClose={handleDeleteClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={deleteBulkStyle}>
              <div className="d-flex flex-column">
                <div className="d-flex justify-content-end">
                  <Close
                    className="action-button"
                    onClick={handleDeleteClose}
                  />
                </div>
                <div className="d-flex flex-column">
                  <span className="delete-head">Delete Item?</span>
                  <span className="delete-text mt-2">
                    Deleting an item is permanent. Do you want to proceed with
                    this action?
                  </span>
                  <div className="d-flex mt-5" style={{ justifyContent: "center" }}>
                    <button
                      type="button"
                  className="cancel-btn me-3 d-flex justify-content-center align-items-center"
                  style={{ background: "transparent" }}
                      onClick={handleDeleteClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                  className="delete-btn me-3 d-flex justify-content-center align-items-center"
                      onClick={() =>{
                        handleDelete()
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </Box>
          </Modal>
        </>
      )}

      {!rootLocation && (
        <div
          className="filter-list d-flex align-items-center justify-content-between"
          style={{ margin: "10px" }}
          ref={containerRef}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            {uploadMessage && <span className="pe-2">{uploadMessage}up</span>}
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <button
              onClick={handleUpload}
              type="button"
              style={{
                minWidth: "8rem",
                background: "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
                borderRadius: "25px",
                color: "#000",
                outline: "none",
                border: "none",
                padding: "10px 53px",
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "21.09px",
                textAlign: "right",
              }}
            >
              Upload
            </button>

            {anyImagesSelected && (
              <button
                onClick={selectAllImages}
                type="button"
                style={{
                  minWidth: "8rem",
                  background:
                    "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
                  borderRadius: "25px",
                  color: "#000",
                  outline: "none",
                  border: "none",
                  marginLeft: "10px",
                  padding: "10px 53px",
                  fontFamily: "Roboto",
                  fontWeight: 500,
                  fontSize: "18px",
                  lineHeight: "21.09px",
                  textAlign: "right",
                }}
              >
                Select All
              </button>
            )}

            {anyImagesSelected && (
              <button
                onClick={deselectAllImages}
                type="button"
                style={{
                  border: "none",
                  background: "none",
                }}
              >
                <Tooltip title="Deselect All Images">
                  <IconButton>
                    <CancelIcon className="text-white" />
                  </IconButton>
                </Tooltip>
              </button>
            )}
            {/* {uploadedFiles?.length > 0 && (
              <button
                style={{
                  background: "#26272c",
                  borderRadius: "25px",
                  outline: "none",
                  border: "0.5px solid #fbca7f",
                  padding: "3px 15px",
                  marginLeft: "10px",
                }}
                onClick={finallyUploadFiles}
              >
                {uploadingFilesApi ? (
                  <Loader />
                ) : (
                  <img style={{ scale: "0.75" }} src={tick} alt="done" />
                )}
              </button>
            )} */}
            {/* {isModalOpen && (
              <div className="modal" style={modalStyles}>
                <div className="modal-content" style={modalContentStyles}>
                  <div style={closeButtonContainerStyles}>
                    <HighlightOffSharpIcon
                      onClick={() => {
                        setModalOpen(false);
                        setUploadedFiles([]);
                        setTotalFiles(0);
                      }}
                      style={closeButtonStyles}
                    />
                  </div>

                  <div className="file-upload-container">
                    <label
                      htmlFor="file-upload"
                      className="file-upload-label"
                      style={fileInputStyles}
                    >
                      <div>
                        <CloudUploadIcon />
                      </div>Click to select files or drag and drop
                      
                      <input
                        id="file-upload"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={hiddenFileInputStyles}
                        accept="image/*, video/*"
                      />
                    </label>
                  </div>
                  {uploadedFiles.length > 0 && (
                    <div>
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        Selected Files:
                      </span>
                      <ul style={fileListStyles}>
                        {Array.from(uploadedFiles)?.map((file, index) => (
                          <li key={index} style={fileItemStyles}>
                            {file.name}
                            <button
                              type="button"
                              style={removeButtonStyles}
                              onClick={() => {
                                setUploadedFiles((prev) =>
                                  Array.from(prev).filter((_, i) => i !== index)
                                );
                                setFileUploadStatuses((prevStatuses) =>
                                  prevStatuses.filter((_, i) => i !== index)
                                );
                              }}
                            >
                              &times;
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <button
                    className="create-event-btn"
                    style={{
                      padding: "10px 35px",
                      cursor:
                        uploadedFiles.length === 0 ? "not-allowed" : "pointer",
                    }}
                    onClick={finallyUploadFiles}
                    type="button"
                    disabled={uploadedFiles.length === 0}
                  >
                    Upload
                  </button>
                </div>
              </div>
            )} */}

            {isUploading && (
              <div style={{...uploadProgressStyles, padding: showUploadData ? '10px': '10px 10px 0 10px'}}>
                <div onClick={() => setShowUploadData(!showUploadData)} style={{display:'flex', alignItems:'center', justifyContent:'space-between', padding: "0 10px", borderBottom: showUploadData? "1px solid #eee" : "none", cursor:'pointer'}}>
                  <h4 style={{...uploadProgressHeaderStyles, }}>Uploading Files</h4>
                  <img style={{transform: showUploadData && 'translateY(-5px)', rotate: !showUploadData && "180deg"}} src={downArrow} alt="" />
                </div>
                <div style={{height: showUploadData ? "" : "0"}}>
                  {fileUploadStatuses?.map((fileStatus, index) => (
                    <div key={index} style={uploadProgressItemStyles}>
                      <span style={fileNameStyles}>{fileStatus.name}</span>
                      <span style={getStatusStyle(fileStatus.status)}>
                        {fileStatus.status === "success"
                          ? "✔️"
                          : fileStatus.status === "failed"
                          ? "❌"
                          : "⏳"}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Toaster richColors />
          </div>
          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {gridView ? (
              <Window
                className=" grid-list-btn-selected"
                style={{ cursor: "default", transform: "translateY(-1px)" }}
              />
            ) : (
              <ViewList
                className=" grid-list-btn-selected"
                style={{ cursor: "default", transform: "translateY(-1px)" }}
              />
            )}
            <p
              className="me-3 grid-list-text"
              onClick={handleChangeView}
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "23.44px",
                fontFamily: "Roboto",
                cursor: "pointer",
                marginBottom: 0,
              }}
            >
              {gridView ? "Grid View" : "List View"}
              <img src={downArrow} alt="" style={{ marginLeft: "5px" }} />
            </p>
            <div>
              {bulkApiCall ? (
                <Loader />
              ) : anyImagesSelected ? (
                <div
                  style={{ display: "flex", gap: "30px", padding: "0 16px" }}
                >
                  <img
                    title="Add To Physical Album"
                    onClick={handleMoveToPhysicalAlbumBulk}
                    src={albumViewActive}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    title="Download"
                    src={downloadActive}
                    onClick={handleBulkDownload}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    onClick={handleDeleteBulk}
                    title="Delete"
                    src={deleteAlbumActive}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    title="Move To"
                    onClick={handleMoveOpen}
                    src={folderMoveActive}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <div
                  style={{ display: "flex", gap: "30px", padding: "0 16px" }}
                >
                  <img
                    title="Add To Physical Album"
                    src={albumView}
                    alt="Add To Physical Album Disabled"
                    style={{ cursor: "not-allowed" }}
                  />
                  <img
                    title="Download"
                    src={download}
                    alt="Download Disabled"
                    style={{ cursor: "not-allowed" }}
                  />
                  <img
                    title="Delete"
                    src={deleteAlbum}
                    alt="Delete Disabled"
                    style={{ cursor: "not-allowed" }}
                  />
                  <img
                    title="Move To"
                    src={folderMove}
                    alt="Move To Disabled"
                    style={{ cursor: "not-allowed" }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {rootLocation && (
        <div
          className="filter-list d-flex align-items-center justify-content-between"
          style={{ margin: "10px" }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              type="button"
              className={"create-album me-3 d-flex justify-content-center"}
              onClick={() => {
                setCreateAlbumModalOpen(true);
                setAlbumTitle("Create");
              }}
              style={{ background: "black" }}
            >
              Create Album
            </button>
            {uploadMessage && <span className="pe-2">{uploadMessage}</span>}
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            {/* <button
              onClick={handleUpload}
              type="button"
              className="upload-btn"
              style={{
                minWidth: "8rem",
                borderRadius: "25px",
                outline: "none",
                padding: "10px 53px",
                fontFamily: "Roboto",
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "21.09px",
                textAlign: "right",
              }}
            >
              Upload
            </button> */}
            {/* {isModalOpen && (
              <div className="modal" style={modalStyles}>
                <div className="modal-content" style={modalContentStyles}>
                  <div style={closeButtonContainerStyles}>
                    <HighlightOffSharpIcon
                      onClick={() => {
                        setModalOpen(false);
                        setUploadedFiles([]);
                        setTotalFiles(0);
                      }}
                      style={closeButtonStyles}
                    />
                  </div>

                  <div className="file-upload-container">
                    <label
                      htmlFor="file-upload"
                      className="file-upload-label"
                      style={fileInputStyles}
                    >
                      <div style={{ marginRight: "10px" }}>
                        <CloudUploadIcon />
                      </div>
                      Click to select files or drag and drop
                      <input
                        id="file-upload"
                        type="file"
                        multiple
                        onChange={handleFileChange}
                        ref={fileInputRef}
                        style={hiddenFileInputStyles}
                        accept="image/*, video/*"
                      />
                    </label>
                  </div>
                  {uploadedFiles.length > 0 && (
                    <div>
                      <span
                        style={{
                          color: "#fff",
                          fontSize: "24px",
                          fontWeight: "600",
                        }}
                      >
                        Selected Files:
                      </span>
                      <ul style={fileListStyles}>
                        {Array.from(uploadedFiles)?.map((file, index) => (
                          <li key={index} style={fileItemStyles}>
                            {file.name}
                            <button
                              type="button"
                              style={removeButtonStyles}
                              onClick={() => {
                                setUploadedFiles((prev) =>
                                  Array.from(prev).filter((_, i) => i !== index)
                                );
                                setFileUploadStatuses((prevStatuses) =>
                                  prevStatuses.filter((_, i) => i !== index)
                                );
                              }}
                            >
                              &times;
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}

                  <button
                    className="create-event-btn"
                    style={{
                      padding: "10px 35px",
                      cursor:
                        uploadedFiles.length === 0 ? "not-allowed" : "pointer",
                    }}
                    onClick={finallyUploadFiles}
                    type="button"
                    disabled={uploadedFiles.length === 0}
                  >
                    Upload
                  </button>
                </div>
              </div>
            )} */}
          </div>
          {isUploading && (
            <div style={uploadProgressStyles}>
              <h4 style={uploadProgressHeaderStyles}>Uploading Files</h4>
              {fileUploadStatuses?.map((fileStatus, index) => (
                <div key={index} style={uploadProgressItemStyles}>
                  <span style={fileNameStyles}>{fileStatus.name}</span>
                  <span style={getStatusStyle(fileStatus.status)}>
                    {fileStatus.status === "success"
                      ? "✔️"
                      : fileStatus.status === "failed"
                      ? "❌"
                      : "⏳"}
                  </span>
                </div>
              ))}
            </div>
          )}
          <Toaster richColors />

          <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
            {gridView ? (
              <Window
                className=" grid-list-btn-selected"
                style={{ cursor: "default", transform: "translateY(-1px)" }}
              />
            ) : (
              <ViewList
                className=" grid-list-btn-selected"
                style={{ cursor: "default", transform: "translateY(-1px)" }}
              />
            )}
            <p
              className="me-3 grid-list-text"
              onClick={handleChangeView}
              style={{
                color: "white",
                fontSize: "20px",
                fontWeight: 600,
                lineHeight: "23.44px",
                fontFamily: "Roboto",
                cursor: "pointer",
                marginBottom: 0,
              }}
            >
              {gridView ? "Grid View" : "List View"}
              <img src={downArrow} alt="" style={{ marginLeft: "5px" }} />
            </p>
            {/* <div>
              {bulkApiCall ? (
                <Loader />
              ) : selectedImageIds?.length > 0 ? (
                <div
                  style={{ display: "flex", gap: "30px", padding: "0 16px" }}
                >
                  <img
                    title="Add To Physical Album"
                    src={albumViewActive}
                    onClick={handleMoveToPhysicalAlbumBulk}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    title="Download"
                    src={downloadActive}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    onClick={handleDeleteBulk}
                    title="Delete"
                    src={deleteAlbumActive}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                  <img
                    title="Move To"
                    onClick={handleMoveOpen}
                    src={folderMoveActive}
                    alt="active"
                    style={{ cursor: "pointer" }}
                  />
                </div>
              ) : (
                <div
                  style={{ display: "flex", gap: "30px", padding: "0 16px" }}
                >
                  <img
                    title="Add To Physical Album"
                    src={albumView}
                    alt=""
                    style={{ cursor: "not-allowed" }}
                  />
                  <img
                    title="Download"
                    src={download}
                    alt=""
                    style={{ cursor: "not-allowed" }}
                  />
                  <img
                    title="Delete"
                    src={deleteAlbum}
                    alt=""
                    style={{ cursor: "not-allowed" }}
                  />
                  <img
                    title="Move To"
                    src={folderMove}
                    alt=""
                    style={{ cursor: "not-allowed" }}
                  />
                </div>
              )}
            </div> */}
          </div>
        </div>
      )}

      {gridView ? (
        <div
          className="d-flex flex-wrap bg-black"
          style={{
            borderRadius: "20px",
            minHeight: `calc(100vh - ${fromTop}px - 120px)`,
            overflowY: "scroll",
            position: "relative",
            margin: "10px",
          }}
          ref={containerRef}
        >
          {rootLocation && (
            <>
              <div className="d-flex flex-column p-3">
                <div
                  className="d-flex flex-wrap mt-4"
                  style={{ gap: "47px", marginBottom: "20px" }}
                >
                  {/* all files folder */}
                  <div
                    className="photo-card"
                    onClick={() => {
                      setInsideGalleryFolder("All Files");
                    }}
                  >
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <img
                        className="cover-photo"
                        src={defaultFolder}
                        alt="Folder"
                        onClick={() => {
                          openAllPublicFilesOfEvent();
                        }}
                      />
                      <div
                        className="folder-name d-flex justify-content-center"
                        onClick={() => {
                          openAllPublicFilesOfEvent();
                        }}
                      >
                        <span>All files</span>
                      </div>
                    </div>
                  </div>
                  {/* guest upload */}
                  { eventType != "\"WEBAPP_SUBSCRIPTION_EVENT\"" && (
                      <div
                        className="photo-card"
                        onClick={() => {
                          setInsideGalleryFolder("Guest uploads");
                        }}
                      >
                        <div
                            className="image-container"
                            style={{ position: "relative" }}
                        >
                          <img
                              className="cover-photo"
                              src={defaultFolder}
                              alt="Folder"
                              onClick={() => {
                                openGuestUploads();
                              }}
                          />
                          <div
                              className="folder-name d-flex justify-content-center"
                              onClick={() => {
                                openGuestUploads();
                              }}
                          >
                            <span>Guest uploads</span>
                          </div>
                        </div>
                    </div>
                  )}
                </div>
                {folderData && folderData.length > 0 && (
                  <>
                    <span className="d-flex mt-4 more-album">More Folders</span>
                    <div className="d-flex flex-wrap mt-4">
                      {folderData.map((item, index) =>
                        sortView === 0 ? (
                          <div
                            onClick={() => setInsideGalleryFolder(item?.name)}
                            key={index}
                            className="photo-card"
                            style={{ padding: "12px", width: "280px" }}
                          >
                            <div
                              className="image-container"
                              style={{ position: "relative" }}
                            >
                              <button
                                type="button"
                                className="btn album-more-action d-flex justify-content-center align-items-center"
                                onClick={(event) => {
                                  handleClickOpenOption(event);
                                  setSelectedFolder(folderData[index]);
                                }}
                              >
                                <MoreVert />
                              </button>
                              <div>
                                <Menu
                                  id="long-menu"
                                  anchorEl={anchorEl}
                                  keepMounted
                                  open={Boolean(anchorEl)}
                                  onClose={handleCloseOption}
                                >
                                  <MenuItem
                                    onClick={() => {
                                      setAlbumTitle("Edit");
                                      setCreateAlbumModalOpen(true);
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Edit />
                                    </ListItemIcon>
                                    <ListItemText primary="Edit" />
                                  </MenuItem>
                                  <MenuItem
                                    onClick={() => {
                                      handleDeleteFolderOpen();
                                    }}
                                  >
                                    <ListItemIcon>
                                      <Delete />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete" />
                                  </MenuItem>
                                </Menu>
                              </div>
                              <img
                                className="cover-photo"
                                style={{objectFit:'cover'}}
                                src={
                                  item.compressedUrl
                                    ? item.compressedUrl
                                    : item.coverPhotoUrl
                                }
                                alt="Folder"
                                onClick={() => {
                                  openFolder(folderData[index]);
                                  setSelectedFolder(folderData[index]);
                                }}
                              />
                              <div
                                className="folder-name d-flex justify-content-center"
                                onClick={() => {
                                  openFolder(folderData[index]);
                                  setSelectedFolder(folderData[index]);
                                }}
                              >
                                <span
                                  className="d-flex align-items-center"
                                  style={{ fontSize: "19px" }}
                                >
                                  {item.openToPublic !== true && (
                                    <span
                                      style={{
                                        scale: "0.8",
                                        transform: "translateY(-2px)",
                                      }}
                                    >
                                      <Lock />
                                    </span>
                                  )}
                                  &nbsp;{item.name}
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}

          {!rootLocation && (
            <>
              {/* all */}
              {responseData?.length > 0 ? (
                responseData?.map(
                  (item, index) =>
                    sortView === 0 && (
                      <div key={index} className="col-3 photo-card mb-3 p-2">
                        <div
                          className="image-container"
                          style={{ position: "relative" }}
                        >
                          <Checkbox
                            sx={{
                              position: "absolute",
                              top: 5,
                              right: 5,
                              zIndex: 1,
                              "& .MuiSvgIcon-root": { fontSize: 28 },
                            }}
                            className="select-check"
                            onChange={(event) => handleSelect(index, event)}
                            checked={selectedItems.includes(index)}
                          />
                          {checkVideo(item.url) ? (
                            <video
                              className="cover-photo"
                              controls
                              muted
                              onClick={() => handleSelect(index)}
                              onDoubleClick={() => handleOpen(index, item.id)}
                            >
                              <source src={item.url} type="video/mp4" />
                              <source src={item.url} type="video/avi" />
                              <source src={item.url} type="video/flv" />
                              <source src={item.url} type="video/wmv" />
                              <source src={item.url} type="video/webm" />
                              <source src={item.url} type="video/mov" />
                              <source src={item.url} type="video/mkv" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <img
                              className="cover-photo"
                              src={
                                item.compressedUrl
                                  ? item.compressedUrl
                                  : item.url
                              }
                              alt="compressedUrl"
                              onClick={() => handleSelect(index)}
                              onDoubleClick={() => handleOpen(index, item.id)}
                            />
                          )}
                        </div>
                      </div>
                    )
                )
              ) : noFiles ? (
                <div
                  style={{
                    color: "white",
                    textAlign: "center",
                    margin: "50px",
                    fontSize: "22px",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  No images in this folder :(
                </div>
              ) : (
                Array.from({ length: 8 }, (_, index) => index + 1).map(() => (
                  <div className="col-3 photo-card mb-3 p-2">
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <div
                        className="shimmer"
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "20px",
                          position: "absolute",
                          top: 0,
                          bottom: 0,
                          left: 0,
                          right: 0,
                          background:
                            "linear-gradient(90deg, #b0b0b0 0%, #808080 50%, #b0b0b0 100%)",
                        }}
                      />
                    </div>
                  </div>
                ))
              )}
              {/* photo */}
              {responseData?.map((item, index) =>
                sortView === 1 && !checkVideo(item.url) ? (
                  <div key={index} className="col-3 mb-3 photo-card p-2">
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          zIndex: 1,
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                        }}
                        className="select-check"
                        onChange={() => handleSelect(index)}
                        checked={selectedItems.includes(index)}
                      />
                      <img
                        className="cover-photo"
                        src={item.compressedUrl ? item.compressedUrl : item.url}
                        alt="compressedUrl"
                        onClick={() => handleSelect(index)}
                        onDoubleClick={() => handleOpen(index, item.id)}
                      />
                    </div>
                  </div>
                ) : null
              )}
              {/* video */}
              {responseData?.map((item, index) =>
                sortView === 2 && checkVideo(item.url) ? (
                  <div key={index} className="col-3 mb-3 photo-card p-2">
                    <div
                      className="image-container"
                      style={{ position: "relative" }}
                    >
                      <Checkbox
                        sx={{
                          position: "absolute",
                          top: 5,
                          right: 5,
                          zIndex: 1,
                          "& .MuiSvgIcon-root": { fontSize: 28 },
                        }}
                        className="select-check"
                        onChange={() => handleSelect(index)}
                        checked={selectedItems.includes(index)}
                      />
                      <video
                        className="cover-photo"
                        controls
                        muted
                        onClick={() => handleSelect(index)}
                        onDoubleClick={() => handleOpen(index, item.id)}
                      >
                        <source src={item.url} type="video/mp4" />
                        <source src={item.url} type="video/avi" />
                        <source src={item.url} type="video/flv" />
                        <source src={item.url} type="video/wmv" />
                        <source src={item.url} type="video/webm" />
                        <source src={item.url} type="video/mov" />
                        <source src={item.url} type="video/mkv" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                ) : null
              )}
            </>
          )}
        </div>
      ) : (
        <div
          className="list-box m-3"
          style={{
            borderRadius: "20px",
            minHeight: `calc(100vh - ${fromTop}px - 120px)`,
          }}
        >
          {rootLocation && (
            <>
              <div className="row list-header mb-3">
                <span className="col-1 align-items-center d-flex">
                  Folder
                </span>
                <span className="col-4 align-items-center d-flex">
                  Folder Name
                </span>
                <span className="col-4 align-items-center d-flex">
                  Folder Size
                </span>
                <span className="col-3 align-items-center d-flex">
                  More Actions
                </span>
              </div>
              {/* All files folder */}
              <div className="row file-row">
                <div className="col-1 d-flex align-items-center">
                  <Folder
                    className="icon-color-gold pointer"
                    onClick={() => {
                      openAllPublicFilesOfEvent();
                    }}
                  />
                </div>
                <div
                  className="col-4 align-items-center d-flex pointer"
                  onClick={() => {
                    openAllPublicFilesOfEvent();
                  }}
                >
                  <span className="file-name">All files</span>
                </div>
                <span className="col-4 align-items-center d-flex pointer">
                  Size
                </span>
              </div>
              {/* guest upload */}
              { eventType != "\"WEBAPP_SUBSCRIPTION_EVENT\"" && (
                <div className="row file-row">
                  <div className="col-1 d-flex align-items-center">
                    <Folder
                      className="icon-color-gold pointer"
                      onClick={() => {
                        openGuestUploads();
                      }}
                    />
                  </div>
                  <div
                    className="col-4 align-items-center d-flex pointer"
                    onClick={() => {
                      openGuestUploads();
                    }}
                  >
                    <span className="file-name">Guest uploads</span>
                  </div>
                  <span className="col-4 align-items-center d-flex pointer">
                    Size
                  </span>
                </div>
              )}

              {folderData?.map((item, index) => (
                <div key={index} className="row file-row">
                  <div className="col-1 d-flex align-items-center">
                    <Folder
                      className="icon-color-gold pointer"
                      onClick={() => {
                        openFolder(folderData[index]);
                        setSelectedFolder(folderData[index]);
                      }}
                    />
                  </div>
                  <div
                    className="col-4 align-items-center d-flex pointer"
                    onClick={() => {
                      openFolder(folderData[index]);
                      setSelectedFolder(folderData[index]);
                    }}
                  >
                    <span className="file-name">
                      {item.name}&nbsp;
                      {item.openToPublic !== true && <Lock />}
                    </span>
                  </div>
                  <span className="col-4 align-items-center d-flex pointer">
                    Size
                  </span>
                  <div className="col-3 align-items-center d-flex">
                    <button
                      type="button"
                      className="btn list-more-action d-flex align-items-center"
                      onClick={(event) => {
                        handleClickOpenOption(event);
                        setSelectedFolder(folderData[index]);
                      }}
                    >
                      <MoreVert />
                    </button>
                    <div>
                      <Menu
                        id="long-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleCloseOption}
                      >
                        <MenuItem
                          onClick={() => {
                            setAlbumTitle("Edit");
                            setCreateAlbumModalOpen(true);
                          }}
                        >
                          <ListItemIcon>
                            <Edit />
                          </ListItemIcon>
                          <ListItemText primary="Edit" />
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            handleDeleteFolderOpen();
                          }}
                        >
                          <ListItemIcon>
                            <Delete />
                          </ListItemIcon>
                          <ListItemText primary="Delete" />
                        </MenuItem>
                      </Menu>
                    </div>
                  </div>
                </div>
              ))}
            </>
          )}

          {!rootLocation && (
            <>
              <div className="row list-header mt-3 mb-3">
                <span className="col-2 align-items-center d-flex">
                  File Type
                </span>
                <span className="col-5 align-items-center d-flex">
                  File Name
                </span>
                <span className="col-3 align-items-center d-flex">
                  File Size
                </span>
                {/* <span className="col-2 align-items-center d-flex justify-content-center">
                  File Location
                </span>
                <span className="col-2 align-items-center d-flex justify-content-center">
                  More Actions
                </span> */}
              </div>
              {/* All */}
              {responseData?.map((item, index) =>
                sortView === 0 ? (
                  <div key={index} className="mt-4 m-0 row file-row pointer" onClick={() => handleSelect(index)}>
                    <div className="col-2 d-flex align-items-center">
                      {checkVideo(item.url) ? (
                        <Videocam
                          className="icon-color-gold pointer"
                          // onClick={() => handleSelect(index)}
                        />
                      ) : (
                        <Image
                          className="icon-color-gold pointer"
                          // onClick={() => handleSelect(index)}
                        />
                      )}
                    </div>
                    <div className="col-5 align-items-center d-flex">
                      <span
                        className="file-name pointer"
                        // onClick={() => handleSelect(index)}
                        onDoubleClick={() => handleOpen(index, item.id)}
                      >
                        {item.name}
                      </span>
                    </div>
                    <span
                      className="col-3 align-items-center d-flex pointer"
                      // onClick={() => handleSelect(index)}
                    >
                      Size
                    </span>
                    {/* <span
                      className="col-2 align-items-center d-flex justify-content-center pointer"
                      onClick={() => handleSelect(index)}
                    >
                      File Location
                    </span>
                    <div className="col-2 align-items-center d-flex justify-content-center pointer">
                      <MoreVert className="more-action icon-color-white" />
                    </div> */}
                    <div className="col-1 align-items-center d-flex">
                      <div onClick={() => handleImageIds(item?.id)}>
                        <Checkbox
                          className="select-check"
                          // onChange={() => handleSelect(index)}
                          checked={selectedItems.includes(index)}
                        />
                      </div>
                    </div>
                  </div>
                ) : null
              )}

              {/* photo */}
              {responseData?.map((item, index) =>
                sortView === 1 && !checkVideo(item.url) ? (
                  <div key={index} className="mt-4 m-0 row file-row">
                    <div className="col-2 d-flex align-items-center">
                      <Image
                        className="icon-color-gold pointer"
                        onClick={() => handleSelect(index)}
                      />
                    </div>
                    <div
                      className="col-5 align-items-center d-flex pointer"
                      onClick={() => handleSelect(index)}
                    >
                      <span
                        className="file-name"
                        onDoubleClick={() => handleOpen(index, item.id)}
                      >
                        {item.name}
                      </span>
                    </div>
                    <span
                      className="col-3 align-items-center d-flex pointer"
                      onClick={() => handleSelect(index)}
                    >
                      Size
                    </span>
                    {/* <span
                      className="col-2 align-items-center d-flex justify-content-center pointer"
                      onClick={() => handleSelect(index)}
                    >
                      File Location
                    </span>
                    <div className="col-2  align-items-center d-flex justify-content-center">
                      <MoreVert className="more-action icon-color-white" />
                    </div> */}
                    <div className="col-1 align-items-center d-flex">
                      <Checkbox
                        className="select-check"
                        onChange={() => handleSelect(index)}
                        checked={selectedItems.includes(index)}
                      />
                    </div>
                  </div>
                ) : null
              )}

              {/* video */}
              {responseData?.map((item, index) =>
                sortView === 2 && checkVideo(item.url) ? (
                  <div key={index} className="mt-4 m-0 row file-row">
                    <div className="col-2 d-flex align-items-center">
                      <Videocam
                        className="icon-color-gold pointer"
                        onClick={() => handleSelect(index)}
                      />
                    </div>
                    <div
                      className="col-5 align-items-center d-flex pointer"
                      onClick={() => handleSelect(index)}
                    >
                      <span
                        className="file-name pointer"
                        onDoubleClick={() => handleOpen(index, item.id)}
                      >
                        {item.name}
                      </span>
                    </div>
                    <span
                      className="col-3 align-items-center d-flex pointer"
                      onClick={() => handleSelect(index)}
                    >
                      Size
                    </span>
                    {/* <span
                      className="col-2 align-items-center d-flex justify-content-center pointer"
                      onClick={() => handleSelect(index)}
                    >
                      File Location
                    </span>
                    <div className="col-2  align-items-center d-flex justify-content-center">
                      <MoreVert className="more-action icon-color-white" />
                    </div> */}
                    <div className="col-1 align-items-center d-flex">
                      <Checkbox
                        className="select-check"
                        onChange={() => handleSelect(index)}
                        checked={selectedItems.includes(index)}
                      />
                    </div>
                  </div>
                ) : null
              )}
            </>
          )}
        </div>
      )}
      {createAlbumModalOpen && (
        <div>
          <CreateAlbumModal
            modalOpen={createAlbumModalOpen}
            handleClose={() => setCreateAlbumModalOpen(false)}
            handleCloseOption={() => {
              handleCloseOption();
            }}
            albumTitle={albumTitle}
            name={selectedFolder.name}
            openToPublic={selectedFolder.openToPublic}
            folderId={selectedFolder.id}
            eventId={selectedFolder.eventId}
            coverPhoto={selectedFolder.coverPhotoUrl}
            setFolderData={setFolderData}
          />
        </div>
      )}
    </div>
  );
};

export default AllFiles;
