import React, { useEffect, useRef } from "react"

const CompanyPolicy = ({ title, desc }) => {
  const pageRef = useRef(null)

  const updateMaxHeight_CONTACTUS = () => {
    if (pageRef.current) {
      const distanceFromTop = pageRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 10
      pageRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  useEffect(() => {
    updateMaxHeight_CONTACTUS()
    window.addEventListener("resize", updateMaxHeight_CONTACTUS)

    return () => {
      window.removeEventListener("resize", updateMaxHeight_CONTACTUS)
    }
  }, [])
  return (
      <div style={{ margin: '10px' }}>
      <div
        ref={pageRef}
        id="home-dashboard-contact-us"
        style={{
          background: "#000",
          borderRadius: "20px",
          overflow: "scroll",
          position: "relative",
          padding: "0 16px 0 16px",
          fontFamily: "Roboto",
          fontWeight: "400",
          fontSize: "1rem",
          lineHeight: "1.5",
          letterSpacing: "0.01em",
          color: "#ffffff",
          display: "flex",
          flexDirection: "column",
          gap: "4px",
        }}
      >
        <p
          style={{
            background: "#08090b",
            width: "100%",
            position: "sticky",
            top: 0,
            padding: "16px 0",
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "0.03em",
          }}
        >
          {title}
        </p>
        <p>{desc}</p>
      </div>
    </div>
  )
}

export default CompanyPolicy
