import React, { useState, useEffect, useRef } from "react"
import "./Otp.scss"
import itsForever from "../../assets/Logos/ife.png"
import itsForeverLogo from "../../assets/Logos/itsForeverLogo.svg"
import rings from "../../assets/rings.jpg"
import loginWedding from "../../assets/login-wedding.svg"
import { useNavigate, useLocation } from "react-router-dom"
import { authLoginOTP, loginSendOTP } from "../../api/api"
import Loader from "../Loader/Loader"

const Otp = ({ setIsOtp, setIsLoggedIn }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const phoneNumber = location.state.phone
  const [verificationCode, setVerificationCode] = useState("")
  const [codeSendMessage, setCodeSendMessage] = useState(false)
  const inputRef = useRef([])
  const [resendAllow, setResendAllow] = useState(false)
  const [allowResendAfter, setAllowResendAfter] = useState(30)
  const [otp, setOtp] = useState(new Array(6).fill(""))
  const [apiCall, setApiCall] = useState(false)
  const [wrongOTP, setWrongOTP] = useState("")

  const redirectHome = () => {
    setApiCall(true)
    let reqBody = {
      phoneNumber: phoneNumber,
      otp: verificationCode,
    }
  
    authLoginOTP(reqBody)?.then((response) => {
      setApiCall(false);
    
      if (response?.status === "OK") {
        if (response?.body === null) {
          navigate("/CompleteProfile", { state: { phoneNumber } });
        } else {
          localStorage.setItem("key", JSON.stringify(response));
          localStorage.removeItem("otp");
          setIsLoggedIn(true);
          setIsOtp(false);
          navigate("/home", { state: { response } });
        }
      } else {
        setVerificationCode("");
        setOtp(new Array(6).fill(""));
        setWrongOTP("Invalid OTP");
        inputRef?.current[0]?.focus();
      }
    });
    
  };

  const resendCode = () => {
    let reqBody = {
      phoneNumber: phoneNumber,
    }
    loginSendOTP(reqBody).then((response) => {
      if (response) {
        setCodeSendMessage(true)
      }
    })
    setResendAllow(false)
    setAllowResendAfter(30)
  }

  const moveToNextInput = (nextInputId, value) => {
    let currentInput = document.activeElement
    let currentIndex = parseInt(currentInput.id.substring(5))
    otp[currentIndex - 1] = value
    setOtp([...otp])

    if (currentIndex === 6) {
      let verificationCode = otp.join("")
      setVerificationCode(verificationCode)
    } else if (currentIndex < 6) {
      document.getElementById(nextInputId).focus()
    }
  }

  const moveToPreviousInput = () => {
    let currentInput = document.activeElement
    let currentIndex = parseInt(currentInput.id.substring(5))

    if (currentIndex > 1) {
      otp[currentIndex - 1] = ""
      setOtp([...otp])
      document.getElementById("input" + (currentIndex - 1)).focus()
    } else {
      currentInput.value = ""
    }
  }

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      e.preventDefault()
      moveToPreviousInput()
    }
  }

  const handleChange = (e, index) => {
    const val = e.target.value.replace(/[^0-9]/g, "")
    if (val.length > 0) {
      moveToNextInput(`input${index + 2}`, val)
    }
  }

  useEffect(() => {
    if (wrongOTP === "") return
    setTimeout(() => {
      setWrongOTP("")
    }, 3000)
  }, [wrongOTP])

  useEffect(() => {
    if (inputRef.current[0]) inputRef.current[0].focus()
  }, [])

  useEffect(() => {
    if (allowResendAfter > 0) {
      const intervalId = setInterval(() => {
        setAllowResendAfter((prev) => (prev > 0 ? prev - 1 : 0))
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [allowResendAfter])

  useEffect(() => {
    if (allowResendAfter === 0) {
      setResendAllow(true)
    }
  }, [allowResendAfter])

  return (
    <div id="hidden" className="wrapper d-flex">
      <img
        src={loginWedding}
        alt="wedding"
        style={{ width: "50vw", objectFit: "cover" }}
      />
      <div className="data">
        <div className="d-flex flex-column align-items-center">
          <div style={{ width: "350px" }}>
            <img
              src={itsForeverLogo}
              alt="itsForeverLogo"
              className="logo"
              style={{ position: "absolute", top: "50px" }}
            />
            <div className="mt-4 d-flex flex-column">
              <span className="text-info-white">Verification Code</span>
              <span className="verify-info-gray">
                Please, enter 6 digit verifiction code we sent to your mobile **
                {phoneNumber.slice(-2)}
              </span>
            </div>
            <div className="mt-4">
              {[...Array(6).keys()].map((_, index) => (
                <input
                  key={index}
                  id={`input${index + 1}`}
                  className="verification-code-input"
                  style={{ background: "#08090B", color: "#e1e1e1" }}
                  ref={(el) => (inputRef.current[index] = el)}
                  type="text"
                  maxLength="1"
                  value={otp[index]}
                  onFocus={() => setCodeSendMessage(false)}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && index === 5) {
                      return redirectHome()
                    }
                    handleKeyDown(e, index)
                  }}
                  onChange={(e) => handleChange(e, index)}
                />
              ))}
            </div>
            {codeSendMessage && (
              <div className="mt-3">
                <span className="send-code">New Code has been sent!</span>
              </div>
            )}

            <p
              className="mt-2"
              style={{
                color: "#FF5757",
              }}
            >
              {wrongOTP !== "" && wrongOTP}
            </p>

            <div style={{ marginTop: "28px" }}>
              <button
                type="button"
                className="btn code-button w-100"
                disabled={verificationCode.length < 6}
                onClick={redirectHome}
                style={{
                  background: verificationCode.length === 6 ? "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)" : "#26272C",
                  color: verificationCode.length === 6 ? "#08090B" : "white",
                  borderRadius: "27.91px",
                  border: "none",
                  outline: "none",
                  cursor:
                    verificationCode.length === 6 ? "pointer" : "not-allowed",
                }}
              >
                {apiCall ? <Loader /> : "Confirm"}
              </button>
              <button
                disabled={!resendAllow}
                type="button"
                className="btn w-100"
                onClick={resendCode}
                style={{
                  background:
                    "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)",
                  color: "black",
                  borderRadius: "27.91px",
                  border: "none",
                  outline: "none",
                  marginTop: "15px",
                  fontFamily: "Inter",
                  cursor: resendAllow ? "pointer" : "not-allowed",
                }}
              >
                Resend Code
              </button>
              {!resendAllow && (
                <p
                  style={{
                    marginTop: "10.5px",
                    fontFamily: "Inter",
                    fontWeight: "400",
                    fontSize: "15px",
                    lineHeight: "23.4px",
                    color: "#706F6C",
                  }}
                >
                  Didn't receive the code? Retry after{" "}
                  <span style={{ color: "#F6C67C" }}>{allowResendAfter}s</span>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Otp
