import React, { useEffect, useState } from "react";
import "./Storage.scss";
import { getSubscriptionDetails } from "../../api/api";
import { useNavigate } from "react-router-dom";

const BtnDetails = () => {
  const navigate = useNavigate();

  const [storageLimitError, setStorageLimitError] = useState(false);
  const [storageValues, setStorageValues] = useState({
    storageUsed: 0,
    storageBought: 0
  });
  const [usedPercentage, setUsedPercentage] = useState(0);

  useEffect(() => {
    const setPercentage = () => {
      if (storageValues.storageBought === 0) return setUsedPercentage(0);

      const percentage = (storageValues.storageUsed / storageValues.storageBought) * 100;
      setUsedPercentage(Math.max(0, Math.min(percentage, 100)));
    };
    setPercentage();
  }, [storageValues]);

  useEffect(() => {
    const checkStorageLimit = () => {
      if (storageValues.storageUsed > storageValues.storageBought) {
        setStorageLimitError(true);
      }
    };
    checkStorageLimit();
  }, [storageValues]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        const storedObjectString = localStorage.getItem("key");
        const storedObject = JSON.parse(storedObjectString);
        let requestBody = {
          id: storedObject?.body?.id,
        };

        const response = await getSubscriptionDetails(requestBody);
        sessionStorage.setItem(
          "FOREVER_AVAILABLE_STORAGE",
          parseInt(response?.storageBought) || 0
        );
        setStorageValues({
          storageUsed: parseFloat(response?.storageUsed) || 0,
          storageBought: parseFloat(response?.storageBought) || 0,
        });
      } catch (error) {
        console.error("Failed to fetch storage details:", error);
      }
    };
    getDetails();
  }, []);

  return (
    <>
      {!storageLimitError && (
        <div id="storage-btn">
          <div className="storage-slide-container">
            <div className="slider-box">
              <div
                className="inner-storage-set"
                style={{
                  background:
                    "linear-gradient(270deg, #fbca7f 0%, #a48346 100%)",
                  width: `${usedPercentage}%`,
                  transition: "width 0.5s ease-out"
                }}
              ></div>
            </div>
          </div>
          <div>
            <p className="storage-btn-text">
              {(storageValues.storageUsed.toFixed(2))} GB used out of{" "}
              {(storageValues.storageBought.toFixed(2))} GB
            </p>
          </div>
          <div className="buy-btn-box">
            <button
              onClick={() => navigate("/buy-storage")}
              className="buy-storage-btn"
            >
              Buy Storage
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default BtnDetails;
