import React, { useState, useEffect } from "react"
import "./HomeNav.scss"
import itsForever from "../../assets/Logos/itsForeverLogo.svg"
import { useNavigate, useLocation } from "react-router-dom"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import { Close } from "@mui/icons-material"
import BtnDetails from "../Storage/BtnDetails"
import dashOn from "../../assets/Dashboard Sidebar/active/dash(active).svg"
import eventOn from "../../assets/Dashboard Sidebar/active/Calendar.svg"
import referralOn from "../../assets/Dashboard Sidebar/active/referaalId.svg"
import profileOn from "../../assets/Dashboard Sidebar/active/Useractive.svg"
import storageOn from "../../assets/Dashboard Sidebar/active/storage.svg"
import dashOff from "../../assets/Dashboard Sidebar/notActive/dash.svg"
import eventOff from "../../assets/Dashboard Sidebar/notActive/Calendar.svg"
import referralOff from "../../assets/Dashboard Sidebar/notActive/referralID.svg"
import profileOff from "../../assets/Dashboard Sidebar/notActive/user.svg"
import storageOff from "../../assets/Dashboard Sidebar/notActive/storage.svg"
import logout from "../../assets/Dashboard Sidebar/notActive/logout.svg"
import contactOff from "../../assets/Dashboard Sidebar/notActive/contact.svg"
import contactOn from "../../assets/Dashboard Sidebar/active/contact.svg"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "#1A1C21",
  border: "none",
  boxShadow: 24,
  p: 6,
  borderRadius: "42px",
}

const HomeNav = ({ pageInfo }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [active, setActive] = useState(1)
  const [hovered, setHovered] = useState(null)

  const pageKeys = {
    1: "dashboard",
    2: "all?page=0&limit=10",
    4: "referred?page=0&limit=10",
    5: "newref",
    6: "profile",
    7: "privacy",
    8: "logout",
    9: "contact",
    10: "storage",
  }

  useEffect(() => {
    const currentPath = location.pathname
    switch (currentPath) {
      case "/home/":
      case "/home/dashboard":
        setActive(1)
        break

      case "/home/all":
        setActive(2)
        break

      case "/home/referred":
        setActive(4)
        break

      case "/home/newref":
        setActive(5)
        break

      case "/home/profile":
        setActive(6)
        break

      case "/home/contact":
        setActive(9)
        break

      case "/home/privacy":
        setActive(7)
        break

      case "/home/logout":
        setActive(8)
        break

      case "/home/storage":
        setActive(10)
        break

      default:
        break
    }
  }, [location.pathname])

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const navClick = (pageIndex) => {
    setActive(pageIndex)
    pageInfo(pageIndex)

    navigate("/home/" + pageKeys[pageIndex])
  }

  return (
    <div id="home-sidebar" className="nav-box d-flex ">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          // paddingBottom: "20px",
          height: "100vh",
        }}
      >
        <div className="box-contents w-100">
          <img
            src={itsForever}
            alt="itsForever"
            className="itsforever-logo"
            style={{ padding: "10px"}}
            onClick={() => {
              navClick(1)
            }}
          />
          <button
            type="button"
            className={
              active === 1
                ? "btn home-nav-button home-nav-button-selected"
                : "btn home-nav-button"
            }
            onClick={() => {
              navClick(1)
            }}
            onMouseEnter={() => setHovered(1)}
            onMouseLeave={() => setHovered(null)}
            style={{ paddingLeft: "24px" }}
          >
            <img
              src={active === 1 || hovered === 1 ? dashOn : dashOff}
              alt=""
              style={{ marginRight: "10px", height: "27px", width: "30px" }}
            />
            Dashboard
          </button>
          <button
            type="button"
            className={
              active === 2
                ? "btn home-nav-button home-nav-button-selected"
                : "btn home-nav-button"
            }
            onClick={() => {
              navClick(2)
            }}
            onMouseEnter={() => setHovered(2)}
            onMouseLeave={() => setHovered(null)}
            style={{ paddingLeft: "24px" }}
          >
            <img
              src={active === 2 || hovered === 2 ? eventOn : eventOff}
              alt=""
              style={{ marginRight: "10px", height: "30px", width: "30px" }}
            />
            Events
          </button>
          {/* <button
          type="button"
          className={
            active === 4
              ? "btn home-nav-button home-nav-button-selected"
              : "btn home-nav-button"
          }
          onClick={() => {
            navClick(4)
          }}
        >
          Referred Events
        </button> */}
          {/* <button
            type="button"
            className={
              active === 5
                ? "btn home-nav-button home-nav-button-selected"
                : "btn home-nav-button"
            }
            onClick={() => {
              navClick(5)
            }}
            onMouseEnter={() => setHovered(5)}
            onMouseLeave={() => setHovered(null)}
            style={{ paddingLeft: "24px" }}
          >
            <img
              src={active === 5 || hovered === 5 ? referralOn : referralOff}
              alt=""
              style={{ marginRight: "10px", height: "37px", width: "32px" }}
            />
            Referrals
          </button> */}
          <button
            type="button"
            className={
              active === 6
                ? "btn home-nav-button home-nav-button-selected"
                : "btn home-nav-button"
            }
            onClick={() => {
              navClick(6)
            }}
            onMouseEnter={() => setHovered(6)}
            onMouseLeave={() => setHovered(null)}
            style={{ paddingLeft: "24px" }}
          >
            <img
              src={active === 6 || hovered === 6 ? profileOn : profileOff}
              alt=""
              style={{ marginRight: "10px", height: "28px", width: "32px" }}
            />
            Profile
          </button>
          {/* <button
            type="button"
            className={
              active === 7
                ? "btn home-nav-button home-nav-button-selected"
                : "btn home-nav-button"
            }
            onClick={() => {
              navClick(7)
            }}
          >
            <img
              src={active === 7 ? dashOn : dashOff}
              alt=""
              style={{ marginRight: "16px", height: "30px", width: "30px" }}
            />
            Privacy Policy
          </button> */}
          <div>
            <button
              type="button"
              className={
                active === 10
                  ? "btn home-nav-button home-nav-button-selected"
                  : "btn home-nav-button"
              }
              onClick={() => {
                navClick(10)
              }}
              onMouseEnter={() => setHovered(10)}
              onMouseLeave={() => setHovered(null)}
              style={{ paddingLeft: "24px" }}
            >
              <img
                src={active === 10 || hovered === 10 ? storageOn : storageOff}
                alt=""
                style={{ marginRight: "10px", height: "30px", width: "32px" }}
              />
              Storage
            </button>
            <BtnDetails />
          </div>
        </div>
        <div className="box-contents w-100">
          <button
            type="button"
            className={
              active === 9
                ? "btn home-nav-button home-nav-button-selected"
                : "btn home-nav-button"
            }
            onClick={() => {
              navClick(9)
            }}
            onMouseEnter={() => setHovered(9)}
            onMouseLeave={() => setHovered(null)}
            style={{ paddingLeft: "24px" }}
          >
            <img
              src={active === 9 || hovered === 9 ? contactOn : contactOff}
              alt=""
              style={{
                marginRight: "10px",
                width: "32px",
                height: active === 9 ? "30px" : "25px",
              }}
            />
            Contact Us
          </button>
          <button
            type="button"
            className={
              active === 8
                ? "btn home-nav-button home-nav-button-selected"
                : "btn home-nav-button"
            }
            onClick={handleOpen}
            style={{ paddingLeft: "24px" }}
          >
            <img
              src={logout}
              alt=""
              style={{ marginRight: "10px", height: "30px", width: "32px" }}
            />
            Logout
          </button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div className="d-flex flex-column align-items-center">
            <p
              style={{
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "28px",
                lineHeight: "33.89px",
              }}
              className="logout-text-highlighted"
            >
              Logout Account
            </p>
            <div style={{ margin: "10px 0" }}>
              <span
                style={{
                  color: "#e1e1e1",
                  fontFamily: "Inter",
                  fontWeight: "600",
                  fontSize: "20px",
                  lineHeight: "29px",
                }}
              >
                Are you sure you want to Logout?
              </span>
            </div>
            <div className="d-flex mt-4">
              <button
                type="button"
                className="btn me-4"
                style={{
                  border: "1px solid #FBCA7F",
                  color: "#FBCA7F",
                  borderRadius: "42px",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "23.44px",
                  padding: "10px 50px",
                }}
                onClick={handleClose}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn"
                onClick={() => {
                  window.location.reload(true)
                  localStorage.removeItem("key")
                }}
                style={{
                  border: "1px solid #FBCA7F",
                  color: "#1A1C21",
                  borderRadius: "42px",
                  fontSize: "20px",
                  fontWeight: "500",
                  lineHeight: "23.44px",
                  background:
                    "linear-gradient(180deg, #FBCA7F 0%, #997B3F 100%)",
                  padding: "10px 50px",
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default HomeNav
