import React from 'react';
import { Skeleton } from '@mui/material';

const SkeletonEvents = () => {
  const skeletonItems = Array.from({ length: 5 }).map((_, index) => (
    <div key={index} className='row table-contents m-0'>
      <span className='col-1 align-items-center d-flex justify-content-center'>
        <Skeleton variant="circular" width={40} height={40} />
      </span>
      <span className='col-3 align-items-center d-flex'>
        <Skeleton variant="text" width={150} />
      </span>
      <span className='col-2 align-items-center d-flex text-color-gray'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-1 align-items-center d-flex text-color-gray'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-1 align-items-center d-flex text-color-gray'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-1 align-items-center d-flex text-color-gray'>
        <Skeleton variant="text" width={100} />
      </span>
      <div className='col-1 align-items-center d-flex'>
        <Skeleton variant="text" width={100} />
      </div>
      <div className='col-2 align-items-center d-flex justify-content-center'>
        <Skeleton variant="rectangular" width={100} height={36} />
      </div>
    </div>
  ));

  return <>{skeletonItems}</>;
};

export default SkeletonEvents;
