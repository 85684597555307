import React, { useEffect, useState } from "react"
import { getSubscriptionDetails } from "../../../api/api"
import './websiteEvents.scss'
import { useNavigate } from "react-router-dom"
import backbtn from "../../../assets/CreateEvent/Vector.svg"

const StorageInfoPage = ({ prevBtn, nextBtn }) => {
  const navigate = useNavigate()
  const [data, setData] = useState(null)
  const [usageData, setUsageData] = useState(null)

  // handle api call
  useEffect(() => {
    const getDetails = async () => {
      const storedObjectString = localStorage.getItem("key")
      const storedObject = JSON.parse(storedObjectString)
      let requestBody = {
        id: storedObject?.body?.id,
      }

      const response = await getSubscriptionDetails(requestBody)

      setData({
        endDate: response?.endDate,
        storageUsed: response?.storageUsed,
        storageBought: response?.storageBought,
        usage: [
          {
            count: parseInt(response?.numberOfEvents),
            title: "Total Events",
          },
          {
            count: parseInt(response?.numberOfPhotos),
            title: "Total Photos",
          },
          {
            count: parseInt(response?.numberOfVideos),
            title: "Total Videos",
          },
        ],
      })
    }
    getDetails()
  }, [])

  // handle usageData setup
  useEffect(() => {
    const setUsage = () => {
      if (!data) return

      const usedTotalPercentage =
        data?.storageBought === 0
          ? 0
          : (data?.storageUsed / data?.storageBought) * 100

      const svgCommons = 200 / 2

      const strokeDashoffset =
        85 * Math.PI * 2 - (85 * Math.PI * 2 * usedTotalPercentage) / 100

      setUsageData({
        svgCommons,
        strokeDashoffset,
      })
    }
    setUsage()
  }, [data])

  return (
      <div id="website-events">
          { usageData && (
              <>
                  <div className="left-arrow" onClick={() => prevBtn()}>
                    <img src={backbtn} alt="" />
                  </div>

                  <div className="head-main" style={{ paddingTop: '180px' }}>
                    <p className="heading">Your Current Plan</p>
                  </div>

                  <div id="circular-stats">
                    <svg width={"250"} height={"250"} viewBox={`0 0 200 200`}>
                      <defs>
                        <linearGradient id="GradientColor">
                          <stop offset="0%" stop-color="#8540B6" />
                          <stop offset="100%" stop-color="#FBCA7F" />
                        </linearGradient>
                      </defs>
                      <circle
                        cx={usageData?.svgCommons}
                        cy={usageData?.svgCommons}
                        strokeWidth="14px"
                        r="90"
                        className="circle-background"
                      />
                      <circle
                        cx={usageData?.svgCommons}
                        cy={usageData?.svgCommons}
                        strokeWidth="14px"
                        r="85"
                        className="circle-progress"
                        style={{
                          strokeDasharray: 85 * Math.PI * 2,
                          strokeDashoffset: usageData?.strokeDashoffset,
                        }}
                        transform={`rotate(-310 ${usageData?.svgCommons} ${usageData?.svgCommons})`}
                      />
                      {data?.storageBought === 0 ? (
                        <>
                          <svg
                            width=""
                            height="60"
                            viewBox="0 0 80 95"
                            fill="none"
                            y={"25%"}
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M40.1456 10.4478C32.0457 10.4478 25.4541 17.0393 25.4541 25.1392C25.4541 26.1133 25.8411 27.0475 26.5299 27.7363C27.2187 28.4251 28.1529 28.8121 29.127 28.8121C30.1011 28.8121 31.0353 28.4251 31.7241 27.7363C32.4129 27.0475 32.7998 26.1133 32.7998 25.1392C32.7998 21.0942 36.1005 17.7935 40.1456 17.7935C44.1906 17.7935 47.4913 21.0942 47.4913 25.1392C47.4913 28.3371 46.8939 30.1245 46.2474 31.2607C45.5765 32.4458 44.6754 33.2685 43.3042 34.4095L43.0545 34.6152C41.7812 35.6681 40.0574 37.0981 38.745 39.2136C37.2758 41.579 36.4727 44.5026 36.4727 48.4007V50.8493C36.4727 51.8234 36.8597 52.7576 37.5485 53.4464C38.2373 54.1352 39.1715 54.5222 40.1456 54.5222C41.1197 54.5222 42.0539 54.1352 42.7427 53.4464C43.4315 52.7576 43.8184 51.8234 43.8184 50.8493V48.4007C43.8184 45.5653 44.3865 44.052 44.9889 43.0873C45.6549 42.0148 46.5364 41.2754 48.0055 40.0511C49.3914 38.8953 51.2376 37.3429 52.6333 34.8944C54.0535 32.3968 54.837 29.2871 54.837 25.1392C54.837 17.0393 48.2455 10.4478 40.1456 10.4478ZM40.1456 70.4379C41.4444 70.4379 42.69 69.922 43.6084 69.0036C44.5268 68.0852 45.0427 66.8396 45.0427 65.5408C45.0427 64.242 44.5268 62.9963 43.6084 62.0779C42.69 61.1595 41.4444 60.6436 40.1456 60.6436C38.8468 60.6436 37.6012 61.1595 36.6828 62.0779C35.7644 62.9963 35.2484 64.242 35.2484 65.5408C35.2484 66.8396 35.7644 68.0852 36.6828 69.0036C37.6012 69.922 38.8468 70.4379 40.1456 70.4379Z"
                              fill="#FB3838"
                            />
                          </svg>

                          {/* </text> */}
                          <text
                            x={"50%"}
                            y={"70%"}
                            dy={"0.3rem"}
                            textAnchor="middle"
                            className="out-of"
                            style={{
                              color: "#FB3838",
                              fontSize: "28px",
                              letterSpacing: "0.22px",
                              fontWeight: "600",
                              fontFamily: "Roboto",
                            }}
                            fill="#FB3838"
                          >
                            0 GB
                          </text>
                        </>
                      ) : (
                        <>
                          <text
                            x={"50%"}
                            y={"46%"}
                            dy={"0.3rem"}
                            textAnchor="middle"
                            className="gb"
                            fill="white"
                          >
                            {(data?.storageUsed ?? 0).toFixed(2)} GB
                          </text>
                          <text
                            x={"50%"}
                            y={"59%"}
                            dy={"0.3rem"}
                            textAnchor="middle"
                            className="out-of"
                            fill="#e1e1e1"
                          >
                            Used out of {(data?.storageBought ?? 0).toFixed(2)} GB
                          </text>
                        </>
                      )}
                    </svg>
                  </div>

                  <div
                    style={{
                      marginTop: "70px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {!data || data?.storageBought === 0 ? (
                      <button
                        onClick={() => navigate("/buy-storage")}
                        style={{
                          outline: "none",
                          border: "none",
                          background: "linear-gradient(180deg, #FBCA7F 0%, #997B3F 100%)",
                          borderRadius: "42px",
                          color: "#08090B",
                          fontFamily: "Roboto",
                          fontWeight: "500",
                          fontSize: "20px",
                          padding: "16px 90px",
                          width: "360px",
                        }}
                      >
                        Buy Storage
                      </button>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: "30px",
                        }}
                      >
                        <button
                          onClick={nextBtn}
                          style={{
                            outline: "none",
                            // border: "none",
                            background: "transparent",
                            borderRadius: "42px",
                            color: "#FBCA7F",
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "20px",
                            padding: "16px 60px",
                            border: "1px solid #F6C67C",
                          }}
                        >
                          Continue With Current Plan
                        </button>
                        <button
                          onClick={() => navigate("/buy-storage")}
                          style={{
                            outline: "none",
                            border: "none",
                            background: "linear-gradient(180deg, #FBCA7F 0%, #997B3F 100%)",
                            borderRadius: "42px",
                            color: "#08090B",
                            fontFamily: "Roboto",
                            fontWeight: "500",
                            fontSize: "20px",
                            padding: "16px 90px",
                            width: "360px",
                          }}
                        >
                          Buy Storage
                        </button>
                      </div>
                    )}
                  </div>
              </>
            )}
      </div>
  )
}

export default StorageInfoPage
