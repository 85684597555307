import React, { useState, useEffect } from "react"
import "./EventNav.scss"
import { useNavigate, useLocation } from "react-router-dom"
import backBtn from "../../assets/Icons/Backbtn(event).svg"
import { getEventsAbout } from "../../api/api"
import BtnDetails from "../Storage/BtnDetails"

const EventNav = ({ pageInfo, setInsideGalleryFolder, backFromGalleryFolder }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [active, setActive] = useState(1)
  const [data, setData] = useState()

  const pageKeys = {
    1: "about",
    2: "all",
    3: "physical",
    4: "folder",
    5: "album-access",
    6: "album-settings",
  }

  useEffect(() => {
    const currentPath = location.pathname
    switch (currentPath) {
      case "/event":
      case "/event/about":
        setActive(1)
        break

      case "/event/all":
        setActive(2)
        break

      case "/event/physical":
        setActive(3)
        break

      case "/event/folder":
        setActive(4)
        break

      case "/event/album-access":
        setActive(5)
        break

      case "/event/album-settings":
        setActive(6)
        break

      default:
        break
    }
  }, [location.pathname])

  useEffect(() => {
    const storedId = localStorage.getItem("eventId")
    if (storedId) {
      getEventsAbout(storedId).then((response) => {
        if (!response.error) {
          setData(response.body)
        }
      })
    }
  }, [])

  const navClick = (pageIndex) => {
    setActive(pageIndex)
    pageInfo(pageIndex)

    navigate("/event/" + pageKeys[pageIndex])
  }

  return (
    <div id="event-nav" className="nav-box d-flex">
      <div className="box-contents w-100">
        {/* <img
          src={itsForever}
          alt="itsForever"
          className="itsforever-logo mt-3"
          onClick={() => {
            navigate("/home/dashboard")
          }}
        /> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "25px",
          }}
        >
          <button
              onClick={() => {
                navigate("/home/all?page=0&limit=15");
                setInsideGalleryFolder("");
                backFromGalleryFolder(true);
              }}
            style={{
              background: "none",
              color: "white",
              outline: "none",
              border: "none",
              fontFamily: "Roboto",
              fontSize: "18px",
              fontWeight: 500,
              lineHeight: "28.13px",
              letterSpacing: "0.20000000298023224px",
              textAlign: "left",
              padding: 0,
            }}
          >
            <img src={backBtn} alt="" style={{ marginRight: "8px" }} />
            Back
          </button>
        </div>
        <button
          type="button"
          className={
            active === 1
              ? "btn event-nav-button event-nav-button-selected"
              : "btn event-nav-button"
          }
          onClick={() => {
              backFromGalleryFolder(true);
              setInsideGalleryFolder("")
              navClick(1)
          }}
        >
          About Event
        </button>
        <button
          type="button"
          className={
            active === 2
              ? "btn event-nav-button event-nav-button-selected"
              : "btn event-nav-button"
          }
          onClick={() => {
              backFromGalleryFolder(true);
              setInsideGalleryFolder("")
              navClick(2)
          }}
        >
          Gallery
        </button>
        <button
            type="button"
            className={
              active === 3
                  ? "btn event-nav-button event-nav-button-selected"
                  : "btn event-nav-button"
            }
            onClick={() => {
                setInsideGalleryFolder("")
                backFromGalleryFolder(true);
                navClick(3)
            }}
        >
          Physical Album
        </button>
        {data?.type !== "WEBAPP_SUBSCRIPTION_EVENT" ? (
          <div>
            <button
              type="button"
              className={
                active === 5
                  ? "btn event-nav-button event-nav-button-selected"
                  : "btn event-nav-button"
              }
              onClick={() => {
                  setInsideGalleryFolder("")
                  backFromGalleryFolder(true);
                  navClick(5)
              }}
            >
              Album Access
            </button>
            <button
              type="button"
              className={
                active === 6
                  ? "btn event-nav-button event-nav-button-selected"
                  : "btn event-nav-button"
              }
              onClick={() => {
                  setInsideGalleryFolder("")
                  backFromGalleryFolder(true);
                  navClick(6)
              }}
            >
              Album Settings
            </button>
          </div>
        ) : (
          <div style={{ marginLeft: "-25px" }}>
            <BtnDetails />
          </div>
        )}
      </div>
    </div>
  )
}

export default EventNav
