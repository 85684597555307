import Box from "@mui/material/Box"
import SliderDesign from "./SliderDesign"

const SliderStorage = ({ data, setData }) => {
  function valuetext(value) {
    return `${value}GB`
  }

  return (
    <div
      className="my-20 px-20 text-white max-2xl:px-10"
      style={{
        paddingLeft: "5rem",
        paddingRight: "5rem",
        marginTop: "5rem",
        marginBottom: "5rem",
        color: "#ffffff",
      }}
    >
      <Box sx={{ width: window.innerWidth * 0.5, position: "relative" }}>
        <SliderDesign
          defaultValue={500}
          value={data?.totalStorage}
          prev={data?.availableStoage}
          onChange={(e) =>
            setData((prev) => ({
              ...prev,
              totalStorage: e.target.value,
              extraAdded: e.target.value - prev?.availableStoage,
            }))
          }
          getAriaValueText={valuetext}
          aria-label="Slider"
          step={50}
          min={data?.availableStoage === 0 ? 500 : data?.availableStoage}
          max={5120}
        />
        <div
          className="absolute -top-4 -left-5 font-bold"
          style={{
            position: "absolute",
            top: "-12px",
            left: "-70px",
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          {data?.availableStoage} GB
        </div>
        <div
          className="absolute -top-4 -right-6 font-bold"
          style={{
            position: "absolute",
            fontSize: "20px",
            top: "-12px",
            right: "-70px",
            fontWeight: 700,
          }}
        >
          5 TB
        </div>
      </Box>
    </div>
  )
}

export default SliderStorage
