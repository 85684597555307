import React from "react"
import img from "../../assets/small-image.png"
import "./SmallScreen.scss"

const SmallSreen = () => {
  return (
    <div id="small-screen-page">
      <img src={img} alt="" />
      <div className="content-holder">
        <p className="p1">This page isn't available on mobile.</p>
        <p className="p2">
          To access it, try using your desktop or laptop. We're working hard to
          make our product work for everyone, everywhere.
        </p>
      </div>
    </div>
  )
}

export default SmallSreen
