import React, { useEffect, useState } from "react"
import backbtn from "../../../../assets/CreateEvent/Vector.svg"
import "./EventDetails.scss"
import info from "../../../../assets/CreateEvent/ri_information-line.svg"
import { FileUploader } from "react-drag-drop-files"
import { MobileDatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

const fileTypes = ["JPG", "PNG", "GIF"]

const EventDetails = ({ prevBtn, nextBtn }) => {
  const [inputChange, setInputChange] = useState({})
  const [imgUrl, setImgUrl] = useState(null)
  const [allowContinue, setAllowContinue] = useState(false)

  const handleChange = (file) => {
    setInputChange({ ...inputChange, file })

    const reader = new FileReader()
    reader.onloadend = () => {
      setImgUrl({ file: reader.result })
    }
    reader.readAsDataURL(file)
  }

  const handleInput = (e) => {
    if (e?.target?.id === "about") {
      if (e?.target?.value?.split(" ")?.length === 101) return
    }
    setInputChange({ ...inputChange, [e.target.id]: e.target.value })
  }

  const addZeroToDate = (date) => {
    const [year, month, day] = date.split("-")

    const formattedMonth = month.length === 1 ? `0${month}` : month
    const formattedDay = day.length === 1 ? `0${day}` : day
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`

    return formattedDate
  }

  const handleNext = async () => {
    if (!allowContinue) return
    const file = inputChange?.file

    const fileReader = new FileReader()

    fileReader.onloadend = () => {
      const base64File = fileReader.result

      const eventDetailsData = {
        name: inputChange?.eventName,
        description: inputChange?.about,
        date: addZeroToDate(inputChange?.date),
        file: base64File,
        fileName: inputChange?.file?.name,
      }

      sessionStorage.setItem(
        "MOBILE_EVENT_DETAILS",
        JSON.stringify(eventDetailsData)
      )

      nextBtn()
    }

    fileReader.readAsDataURL(file)
  }

  useEffect(() => {
    const setDataFromState = () => {
      const res = JSON.parse(sessionStorage.getItem("MOBILE_EVENT_DETAILS"))
      if (!res) return

      setInputChange({
        photographerName: res?.photographerName,
        eventName: res?.name,
        about: res?.description,
        date: res?.date,
        file: res?.file,
      })

      setImgUrl({ file: res?.file })
    }
    setDataFromState()
  }, [])

  useEffect(() => {
    const checkAndRemoveOverflowStyle = () => {
      const body = document.body
      if (body.style.overflow === "hidden") {
        body.style.overflow = ""
      }
    }

    checkAndRemoveOverflowStyle()
    window.addEventListener("resize", checkAndRemoveOverflowStyle)

    return () => {
      window.removeEventListener("resize", checkAndRemoveOverflowStyle)
    }
  }, [])

  useEffect(() => {
    const changeContinueBtn = () => {
      if (
        inputChange?.eventName &&
        inputChange?.about &&
        inputChange?.date &&
        inputChange?.file &&
        inputChange?.file !== null
      )
        setAllowContinue(true)
      else setAllowContinue(false)
    }
    changeContinueBtn()
  }, [inputChange])

  return (
    <div id="mobile-events">
      <div className="left-arrow" onClick={() => prevBtn()}>
        <img src={backbtn} alt="" />
      </div>

      <div className="head-main">
        <p className="heading">Fill your event details</p>
      </div>

      <div className="inputs-main">
        <div className="event-main">
          <label htmlFor="event-name" className="event-title">
            Name of the Event
          </label>
          <input
            type="text"
            id="eventName"
            value={inputChange?.eventName || ""}
            onChange={handleInput}
            placeholder="Name of the event"
          />
        </div>

        <div className="about-main">
          <div className="abt">
            <label htmlFor="about" className="about-title">
              About Event
            </label>
            {/* <img className="abt-img" title="something" src={info} alt="" /> */}
          </div>

          <textarea
            name="about-event"
            id="about"
            value={inputChange.about || ""}
            onChange={handleInput}
            placeholder="Add description of the event"
            rows={7}
          ></textarea>
          <p className="char-limiter">
            {inputChange?.about?.split(" ")?.length || 0}/100
          </p>
        </div>

        <div className="date-selection">
          <p className="event-date">Date of the Event</p>
          <div>
            <MobileDatePicker
              className="picker"
              id="date"
              value={inputChange.date ? dayjs(inputChange.date) : dayjs()}
              onChange={(val) =>
                setInputChange({
                  ...inputChange,
                  // date: val,
                  date: `${val?.$y}-${val?.$M + 1}-${val?.$D}`,
                })
              }
              label="MM/DD/YYYY"
            />
          </div>
        </div>

        <div className="upload">
          <div
            style={{
              display: "flex",
              justifyContent: inputChange?.file ? "center" : "start",
              gap: "40px",
              alignItems: "center",
              marginLeft: inputChange?.file && "-350px",
            }}
          >
            <p className="uploadTitle">Upload Cover Image</p>
            {inputChange?.file && (
              <button
                style={{
                  height: "max-content",
                  outline: "none",
                  marginTop: "20px",
                  border: "none",
                  background: "transparent",
                  color: "red",
                }}
                onClick={() =>
                  setInputChange((prev) => ({ ...prev, file: null }))
                }
              >
                Remove
              </button>
            )}
          </div>

          {!inputChange?.file ? (
            <div className="uploadFile">
              <FileUploader
                handleChange={handleChange}
                name="file"
                types={fileTypes}
                hoverTitle="Drop Here"
                classes="dnd"
                children=<p>
                  Drag and drop or <span className="browse">Browse</span>
                </p>
              />
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "10px",
              }}
            >
              <img
                style={{
                  border: "1px solid #4d4f57",
                  borderRadius: "12px",
                }}
                className="dnd-img"
                src={imgUrl?.file}
                alt=""
              />
            </div>
          )}
        </div>
      </div>

      <div className="continue-btn-main">
        <p
          className="continue-btn"
          onClick={handleNext}
          style={{
            background: allowContinue
              ? "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)"
              : "transparent",
            color: allowContinue ? "#000000" : "#fbca7f",
            border: !allowContinue && "1px solid #fbca7f",
            cursor: allowContinue ? "pointer" : "not-allowed",
          }}
        >
          Continue
        </p>
      </div>
    </div>
  )
}

export default EventDetails
