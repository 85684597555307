import React, { useEffect, useState } from "react"
import arrow from "../../assets/storage/backArrow.svg"
import { useNavigate } from "react-router-dom"
import plus from "../../assets/storage/plus.svg"
import minus from "../../assets/storage/minux.svg"
import StorageSlider from "./StorageSlider"
import { confirmPayment, createOrder } from "../../api/api"
import Loader from "../Loader/Loader"
import SliderStorage from "./Slider/SliderStorage"

const BuyStorage = () => {
  const navigate = useNavigate()

  const [apiCall, setApiCall] = useState(false)
  const [data, setData] = useState({
    availableStoage: 0,
    extraAdded: 500,
    totalStorage: 500,
    pricePerGB: 15,
  })

  // handle updating availablestorage from session storage
  useEffect(() => {
    const setAvailableStorage = () => {
      const res = sessionStorage.getItem("FOREVER_AVAILABLE_STORAGE")
      setData((prev) => ({
        ...prev,
        availableStoage: parseInt(res),
        totalStorage: parseInt(res) + 500,
      }))
    }
    setAvailableStorage()
  }, [])

  // handle pricePerGB
  useEffect(() => {
    const setPrice = () => {
      const currentMonth = new Date(Date.now()).getMonth()
      console.log(currentMonth)
      const pricePerGb = (25000 / (12 * 1000)) * (12 - currentMonth)
      setData((prev) => ({ ...prev, pricePerGB: pricePerGb.toFixed(2) }))
    }
    setPrice()
  }, [])

  //  handle activate razorpay
  const displayRazorpay = async (amount, order_id) => {
    const localUserData = await JSON.parse(localStorage.getItem("key"))?.body

    const options = {
      key: "rzp_live_aPRLkFK27mq3Is",
      // key: "rzp_test_HMAjFSjTkhr0Wm",
      amount,
      currency: "INR",
      description: "It's Forever Storage",
      order_id,
      image: `${localUserData?.profilePhotoUrl}`,
      prefill: {
        name: `${localUserData?.firstName} ${localUserData?.lastName}`,
        email: `${localUserData?.email}`,
        contact: `${localUserData?.phoneNumber}`,
      },
      handler: async function (response) {
        const res = await confirmPayment({
          transactionId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          orderType: "ORDER_TYPE_SUBSCRIPTION_CONFIRMED",
        })

        if (res?.status === "OK") {
          setApiCall(false)
          navigate("/home")
        } else {
          setApiCall(false)
        }
      },
      modal: {
        ondismiss: function () {
          setApiCall(false)
          if (window.confirm("Are you sure, you want to close the form?")) {
            window.Text = "You pressed OK!"
          } else {
            window.Text = "You pressed Cancel!"
          }
        },
      },
    }

    const rzp1 = new window.Razorpay(options)

    rzp1.on("payment.failed", function (response) {
      alert(response.error.description)
    })

    rzp1.open()
  }

  // handle Buying storage
  const handleBuy = async () => {
    setApiCall(true)
    const bodyData = {
      storageBought: data?.extraAdded,
      amount: Math.floor(data?.extraAdded * data?.pricePerGB),
      orderType: "ORDER_TYPE_SUBSCRIPTION_PENDING",
      userId: JSON.parse(localStorage.getItem("key"))?.body?.id,
    }
    const res = await createOrder(bodyData)

    if (res?.status === "created") {
      displayRazorpay(res?.amount, res?.id)
    } else {
      setApiCall(false)
    }
  }

  return (
    <div id="buy-storage-page">
      <div onClick={() => navigate(-1)} className="left-arrow">
        <img src={arrow} alt="" />
      </div>

      <div className="head-main">
        <p className="heading">Need More Storage?</p>
        <p className="head-content">
          Your storage expires on Dec 31st {new Date().getFullYear()}. The
          prices are prorated to this expiry date
        </p>
      </div>

      <div className="main-mid">
        <div className="storage-box" style={{ color: "#706F6C" }}>
          <p>Available Storage</p>
          <div>
            <p>{data?.availableStoage === 1 ? 0 : data?.availableStoage}GB</p>
          </div>
        </div>
        <div className="slider">
          {/* <input type="range" /> */}
          {/* <StorageSlider setData={setData} data={data} /> */}
          <SliderStorage setData={setData} data={data} />
        </div>
        <div className="storage-box" style={{ color: "#e1e1e1" }}>
          <p>Buy Storage</p>
          <div className="incre-decre-storage">
            <img
              onClick={() => {
                if (data?.totalStorage <= Math.max(data?.availableStoage, 500)) return

                setData((prev) => ({
                  ...prev,
                  extraAdded: prev?.extraAdded - 50,
                  totalStorage: prev?.totalStorage - 50,
                }))
              }}
              src={minus}
              alt="minus"
            />
            <p>{data?.extraAdded}GB</p>
            <img
              onClick={() => {
                if (data?.totalStorage === 5000) return

                setData((prev) => ({
                  ...prev,
                  extraAdded: prev?.extraAdded + 50,
                  totalStorage: prev?.totalStorage + 50,
                }))
              }}
              src={plus}
              alt="plus"
            />
          </div>
        </div>
      </div>

      <div className="final-bill">
        <div className="bill-box">
          <div className="details">
            <div className="single-detail">
              <p>Available Storage</p>
              <p>{data?.availableStoage === 1 ? 0 : data?.availableStoage}GB</p>
            </div>
            <div className="single-detail">
              <p>Extra Added</p>
              <p>{data?.extraAdded}GB</p>
            </div>
            <div className="single-detail">
              <p>Total Storage</p>
              <p>
                {data?.availableStoage === 1
                  ? data?.totalStorage - 1
                  : data?.totalStorage}
                GB
              </p>
            </div>
          </div>
          <div className="ending">
            <div className="to-pay">To Pay:</div>
            <div className="pricing">
              <p>₹{Math.floor(data?.extraAdded * data?.pricePerGB)}*</p>
              <p>{data?.extraAdded}GB</p>
            </div>
            <button onClick={handleBuy} className="buy-btn">
              {apiCall ? <Loader /> : "Buy Storage"}
            </button>
          </div>
        </div>
      </div>
      <p className="tnc">
        *By proceeding you have read and agree to the{" "}
        <span className="tnc-gold">Terms & Conditions.</span>
      </p>
      {/* <button id="rzp-button1">Pay with Razorpay</button> */}
    </div>
  )
}

export default BuyStorage
