import React, { createContext, useState, useContext } from 'react';
import UploadProgressIndicator from './UploadProgressIndicator';

const FileUploadContext = createContext();

export const FileUploadProvider = ({ children }) => {
  const [isUploading, setUploading] = useState(false);
  const [fileUploadStatuses, setFileUploadStatuses] = useState([]);

  return (
    <FileUploadContext.Provider value={{ isUploading, fileUploadStatuses, setUploading, setFileUploadStatuses }}>
        <UploadProgressIndicator />
      {children}
    </FileUploadContext.Provider>
  );
};

export const useFileUpload = () => useContext(FileUploadContext);
