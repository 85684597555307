import React from 'react';
import noDataAvailable from '../../assets/noData.webp';


const NoData = () => {
  const myStyle = {
    color: '#fff',
    fontSize: '22px',
    fontWeight: '600',
    letterSpacing: '0em',
    fontFamily: 'Inter'
  };

  return (
    <div className="mt-5 d-flex flex-column align-items-center">
      <img src={noDataAvailable} alt='No Data Available!' style={{ width: '200px', height: 'auto' }} />
      <span className='mb-4' style={myStyle}>No Data Available!</span>
    </div>
  );
}

export default NoData;