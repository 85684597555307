import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { editEventDetails, uploadCoverPhoto } from "../../../../api/api"
import edit from "../../../../assets/Icons/PencilAlt.svg"
import Loader from "../../../Loader/Loader"
import ExtraTab3 from "./ExtraTab3"

const Tab3 = ({ data, newSetOfData }) => {
  const navigate = useNavigate()
  const [mainDetails, setMainDetails] = useState({})
  const [addExtraDetails, setAddExtraDetails] = useState(false)
  const [extraDetails, setExtraDetails] = useState([])
  const [allowMainTitleChange, setAllowMainTitleChange] = useState(false)
  const [apiCallSave, setApiCallSave] = useState(false)

  const handleInput = (e) => {
    setMainDetails({ ...mainDetails, [e.target.id]: e.target.value })
  }

  const handleExtraInput = (data, e) => {
    setExtraDetails((prev) =>
      prev?.map((p) =>
        p?.key === data?.key ? { ...p, [e.target.id]: e.target.value } : p
      )
    )
  }

  const cancelEdit = () => {
    navigate("/event")
  }

  const handleSave = async () => {
    setApiCallSave(true)

    const updateExtraVendorDetails = extraDetails?.map((ed) => {
      return {
        ...ed,
        website: String(ed?.website).startsWith("http")
          ? ed?.website
          : `https://${ed?.website}`,
      }
    })

    const apiDataBody = {
      id: data?.id,
      name: newSetOfData?.name,
      description: newSetOfData?.description,
      date: newSetOfData?.date,
      facePrivacy: data?.facePrivacy,
      guestUpload: data?.guestUpload,
      hostId: data?.hostId,
      vendors: [
        {
          ...mainDetails,
          website: String(mainDetails?.website).startsWith("http")
            ? mainDetails?.website
            : `https://${mainDetails?.website}`,
        },
        ...updateExtraVendorDetails,
      ],
    }

    const res = await editEventDetails(apiDataBody)
    if (res.status === "OK") {
      if (newSetOfData?.file) {
        const formdata = new FormData()
        formdata.append("file", newSetOfData?.file, newSetOfData?.file?.name)
        const coverApiRes = await uploadCoverPhoto(formdata, data?.id)
        if (coverApiRes?.status === "OK") {
          setApiCallSave(false)
          navigate("/event")
        } else {
          setApiCallSave(false)
        }
      } else {
        setApiCallSave(false)
        navigate("/event")
      }
      // sessionStorage.setItem("eventUpdated", true)
      setApiCallSave(false)
      navigate("/event")
    } else {
      setApiCallSave(false)
    }
  }

  useEffect(() => {
    const setupData = () => {
      // setPageData(data?.vendors)
      if (data?.vendors?.length === 0) {
        setMainDetails({
          category: "Photography Company (Default)",
        })
        setExtraDetails([{ key: 1, category: "Other Vendor Details" }])
      } else if (data?.vendors?.length === 1) {
        setMainDetails(data?.vendors[0])
        setExtraDetails([{ key: 1, category: "Other Vendor Details" }])
      } else {
        setMainDetails(data?.vendors[0])
        const remainingData = Array.from(data?.vendors).slice(1)
        setExtraDetails(
          remainingData?.map((rd, idx) => {
            return {
              key: idx + 1,
              ...rd,
            }
          })
        )
      }
    }
    setupData()
  }, [data])

  return (
    <div id="album-vendor-settings">
      <div className="details-main">
        <div className="details">
          <div className="title">
            {allowMainTitleChange ? (
              <input
                type="text"
                className="main-title-input"
                value={mainDetails?.category || ""}
                onKeyDown={(e) => {
                  if (e?.key === "Enter") {
                    setAllowMainTitleChange(!allowMainTitleChange)
                  }
                }}
                onChange={(e) => {
                  setMainDetails((prev) => ({
                    ...prev,
                    category: e?.target?.value,
                  }))
                }}
              />
            ) : (
              <p>{mainDetails?.category}</p>
            )}
            <img
              src={edit}
              alt=""
              onClick={() => setAllowMainTitleChange(!allowMainTitleChange)}
            />
          </div>
          <div className="main">
            <label htmlFor="name" className="name">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={mainDetails?.name || ""}
              onChange={handleInput}
              placeholder="Photo Studio"
            />
          </div>
          <div className="main">
            <label htmlFor="phoneNumber" className="name">
              Mobile No.
            </label>
            <input
              type="number"
              id="phoneNumber"
              value={mainDetails?.phoneNumber || ""}
              onChange={(e) => {
                if (e.nativeEvent.data === "e") return
                handleInput(e)
              }}
              placeholder="982373101"
            />
          </div>
          <div className="main">
            <label htmlFor="website" className="name">
              URL
            </label>
            <input
              type="text"
              id="website"
              value={mainDetails?.website || ""}
              onChange={handleInput}
              placeholder="www.photostudio.com"
            />
          </div>
          <div className="main">
            <label htmlFor="social" className="name">
              Instagram
            </label>
            <input
              type="text"
              id="instagram"
              value={mainDetails?.instagram || ""}
              onChange={handleInput}
              placeholder="instragram.com"
            />
          </div>
        </div>

        <div className="other-details">
          {extraDetails?.map((ed) => (
            <div key={ed?.key} style={{ marginTop: "70px" }}>
              <div className="title">
                {allowMainTitleChange ? (
                  <input
                    type="text"
                    id="category"
                    autoComplete="false"
                    onKeyDown={(e) => {
                      if (e?.key === "Enter") {
                        setAllowMainTitleChange(!allowMainTitleChange)
                      }
                    }}
                    className="main-title-input"
                    value={ed?.category || ""}
                    onChange={(e) => handleExtraInput(ed, e)}
                  />
                ) : (
                  <p>{ed?.category}</p>
                )}
                <img
                  src={edit}
                  alt=""
                  onClick={() => setAllowMainTitleChange(!allowMainTitleChange)}
                />
              </div>
              <div className="main">
                <label htmlFor="name" className="name">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  value={ed?.name || ""}
                  onChange={(e) => handleExtraInput(ed, e)}
                  placeholder="Photo Studio"
                />
              </div>
              <div className="main">
                <label htmlFor="phoneNumber" className="name">
                  Mobile No.
                </label>
                <input
                  type="number"
                  id="phoneNumber"
                  value={ed?.phoneNumber || ""}
                  onChange={(e) => {
                    if (e?.nativeEvent?.data === "e") return
                    handleExtraInput(ed, e)
                  }}
                  placeholder="982373101"
                />
              </div>
              <div className="main">
                <label htmlFor="website" className="name">
                  URL
                </label>
                <input
                  type="text"
                  id="website"
                  value={ed?.website || ""}
                  onChange={(e) => handleExtraInput(ed, e)}
                  placeholder="www.domainname.com"
                />
              </div>
              <div className="main">
                <label htmlFor="instagram" className="name">
                  Instagram
                </label>
                <input
                  type="text"
                  id="instagram"
                  value={ed?.instagram || ""}
                  onChange={(e) => handleExtraInput(ed, e)}
                  placeholder="instragram.com"
                />
              </div>
            </div>
          ))}

          <div
            className="add"
            style={{ cursor: "pointer" }}
            onClick={() => setAddExtraDetails(true)}
          >
            + Add More
          </div>

          {addExtraDetails && (
            <ExtraTab3
              extraDetails={extraDetails}
              setAddExtraDetails={setAddExtraDetails}
              setExtraDetails={setExtraDetails}
            />
          )}
        </div>
        <div className="btns-box" style={{ marginTop: "60px" }}>
          <button onClick={cancelEdit} className="save">
            Discard
          </button>
          <button onClick={handleSave} className="continue">
            {apiCallSave ? <Loader /> : "Save Changes"}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Tab3
