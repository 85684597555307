import React, { useEffect, useState } from "react"
import "./Finish.scss"
import { useNavigate } from "react-router-dom"
import checkCircle from "../../../assets/Icons/CheckCircle.svg"
import x from "../../../assets/Icons/X.svg"

const Finish = ({ setIsEventCreated }) => {
  const navigate = useNavigate()
  const [eventType, setEventType] = useState("")

  useEffect(() => {
    const typeSetter = () => {
      setEventType(localStorage.getItem("eventType"))
      localStorage.removeItem("eventType")
    }
    typeSetter()
  }, [])

  return (
    <div id="finish-creating-event">
      <div className="card-div-content-finish">
        <div className="finish-images">
          <img src={checkCircle} alt="" />
          <img
            onClick={() => setIsEventCreated(false)}
            src={x}
            alt=""
            className="finish-image-x"
          />
        </div>
        <p className="title-text">Congratulations!</p>
        <p className="title-desc">Your event has been created successfully</p>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            gap: "30px",
          }}
        >
          <button
            onClick={() => {
              setIsEventCreated(false)
              navigate("/event/album-settings")
            }}
            className="btn-common-class btn-al-ac"
          >
            Event Settings
          </button>

          {eventType === "WEBAPP_INDEPENDENT_EVENT" && (
            <button
              onClick={() => {
                setIsEventCreated(false)
                navigate("/event/album-access")
              }}
              className="btn-common-class btn-ev-se"
            >
              Album Access
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default Finish
