import styled from "@emotion/styled"
import { Slider } from "@mui/material"
// import { dynamicPricingLabels } from "../../lib/constants"

const marks = [
  {
    value: 500,
    label: "",
  },
  {
    value: 1000,
    label: "1000",
  },
  {
    value: 1500,
    label: "",
  },
  {
    value: 2000,
    label: "2000",
  },
  {
    value: 2500,
    label: "",
  },
  {
    value: 3000,
    label: "3000",
  },
  {
    value: 3500,
    label: "",
  },
  {
    value: 4000,
    label: "4000",
  },
  {
    value: 4500,
    label: "",
  },
]

const SliderDesign = styled((props) => (
  <Slider
    {...props}
    valueLabelDisplay="on"
    valueLabelFormat={(value) => {
      return `Total = ${props.prev}GB + ${value - props?.prev}GB`
    }}
    marks={marks}
  />
))({
  color: "#FBCA7F",
  height: 8,
  "& .MuiSlider-rail": {
    opacity: "0.2",
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: "16px",
    fontWeight: 600,
    fontFamily: "Inter",
    color: "#000",
    background: "unset",
    padding: "0 8px",
    // width: 225,
    height: 40,
    borderRadius: "47.89px",
    backgroundColor: "#FBCA7F",
    transformOrigin: "bottom left",
    "&::before": { display: "none" },
  },
  "& .MuiSlider-mark": {
    // backgroundColor: "#000",
    transform: "translateY(18px)",
    height: 10,
    width: 3,
  },
  "& .MuiSlider-markLabel": {
    transform: "translate(-49%, 20px)",
    color: "#fff",
    fontSize: "20px",
    fontWeight: 400,
    fontFamily: "Inter",
  },
  "@media (max-width: 700px)": {
    "& .MuiSlider-thumb": {
      height: 16,
      width: 16,
    },
    "& .MuiSlider-valueLabel": {
      fontSize: 10,
      width: 48,
      height: 48,
    },
    "& .MuiSlider-markLabel": {
      fontSize: 10,
    },
  },
})

export default SliderDesign
