import Modal from "@mui/material/Modal";
import React, {useEffect, useState} from "react";
import "./QRCodeModel.scss"
import {getQRCodeOfEvent} from "../../../api/api";
import Box from "@mui/material/Box";
import Loader from "../../Loader/Loader";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    // height: '80%',
    bgcolor: '#1A1C21',
    border: '2px solid #000',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    color: '#fff',
    overflowY: 'scroll'
};

const QRCodeModal = (props) => {
    const { modalOpen, handleClose } = props;
    const [qrCode, setQrCode] = useState()

    const downloadQRCode = () => {
        const link = document.createElement('a');
        link.href = qrCode;
        link.download = 'QRCode.png'; // Set the default file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const getQRCode = async () => {
            const eventId = localStorage.getItem("eventId")
            if (eventId) {
                const res = await getQRCodeOfEvent(eventId)
                if (!res.error) {
                    setQrCode("data:image/png;base64, " + res.body)
                }
            }
        }
        getQRCode()
    }, [])
    return (
        <div className="box d-flex">
            <Modal
                open={modalOpen}
                onClose={() => { handleClose();}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                {qrCode ? <div className="card-div">
                        <div className="img-box card-div-content">
                          <img
                                src={qrCode}
                                alt=""
                                style={{ objectFit: "scale-down", width: "457px", height: "316px"}}
                            /> :
                        </div>
                        <div className="card-div-content">
                            <p className="title-text"></p>
                            <p className="title-desc">Download the QR Code and share it with your guests for easy access to pictures</p>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    alignItems: "center",
                                }}
                            >
                                <button className="btn-common-class btn-ev-se" onClick={downloadQRCode}>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div> : <div style={{display:'flex', justifyContent:'center', alignItems:'center', padding: '80px 0', scale: '2'}}><Loader /></div>}
                </Box>
            </Modal>
        </div>
    );
}

export default QRCodeModal;