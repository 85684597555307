import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import React from "react";
import {handleCopyClick} from "../../../utils/clipboard";
import "./ShareModel.scss"
import { Toaster, toast } from "sonner";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '37%',
    // height: '80%',
    bgcolor: '#1A1C21',
    border: '2px solid #000',
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
    color: '#fff',
    overflowY: 'scroll'
};

const ShareModal = (props) => {
    const { modalOpen, handleClose, eventLink, setPincodeModalOpen, setShareModalOpen, pincode, eventType} = props;
    return (
        <div className="box d-flex">
            <Toaster  richColors/>
            <Modal
                open={modalOpen}
                onClose={() => { handleClose();}}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div className='d-flex justify-content-center'>
                        <span className='title'>Share Link</span>
                    </div>
                    {eventType === "WEBAPP_SUBSCRIPTION_EVENT" && <>
                        <div style={{margin: '20px 0 6px 0', display:'flex', justifyContent:'space-between'}}>
                        <p style={{margin:0, fontSize: '18px', fontWeight:'600'}}>Current Pin Code</p>
                        <p onClick={() => {
                            setShareModalOpen(false)
                            setPincodeModalOpen(true)
                        }} style={{margin:0,fontSize: '18px', fontWeight:'600', cursor:'pointer'}} className="change">Change</p>
                    </div>
                    <div className='d-flex flex-column'>
                        <div className="link-container">
                            {/* <div className="link-box" style={{overflowX:'scroll'}}> */}
                            <span id="modalLink" style={{overflowX:'scroll', transform:'translateY(7.5px)'}}>{pincode}</span>
                            {/* </div> */}
                        </div>
                    </div>
                    </>}
                    <p style={{marginTop: '20px', marginBottom: '6px', fontSize: '18px', fontWeight:'600'}}>Copy Link</p>
                    <div className='d-flex flex-column'>
                        <div className="link-container">
                            {/* <div className="link-box" style={{overflowX:'scroll'}}> */}
                            <a title={eventLink} href={eventLink} id="modalLink" target="_blank" rel="noreferrer" style={{overflowX:'scroll', transform:'translateY(7.5px)'}}>{eventLink}</a>
                            {/* </div> */}
                        </div>
                        <div style={{width:'100%', display:'flex', justifyContent:'center', marginTop: '20px'}}>
                            <button
                                type="button"
                                className="btn copy-btn"
                                onClick={() => {
                                    handleCopyClick(eventLink)
                                    toast.success("Code Copied");
                                }}
                            >
                                Copy Link
                            </button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default ShareModal;