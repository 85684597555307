import React from 'react';
import { Skeleton } from '@mui/material';

const SkeletonTableHeader = () => {
  return (
    <div className='row table-header m-0'>
      <span className='col-1 align-items-center d-flex justify-content-center'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-3 align-items-center d-flex'>
        <Skeleton variant="text" width={150} />
      </span>
      <span className='col-2 align-items-center d-flex'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-1 align-items-center d-flex'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-1 align-items-center d-flex'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-1 align-items-center d-flex'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-1 align-items-center d-flex'>
        <Skeleton variant="text" width={100} />
      </span>
      <span className='col-2 align-items-center d-flex justify-content-center'>
        <Skeleton variant="rectangular" width={100} height={36} />
      </span>
    </div>
  );
};

export default SkeletonTableHeader;
