import React, { useEffect, useState } from "react";
import "./Storage.scss";
import events from "../../assets/storage/Calendar.svg";
import photos from "../../assets/storage/photos.svg";
import video from "../../assets/storage/video.svg";
import { getSubscriptionDetails } from "../../api/api";
import { useNavigate } from "react-router-dom";

const Storage = () => {
  const navigate = useNavigate();

  const [storageLimitError, setStorageLimitError] = useState(false);
  const [storageData, setStorageData] = useState(null);
  const [usageData, setUsageData] = useState(null);
  const [fromTop, setFromTop] = useState(null);
  const [daysLeft, setDaysLeft] = useState(null);

  useEffect(() => {
    const calculateDaysLeft = () => {
      if (!storageData) return;
      if (!storageData?.endDate) return setDaysLeft(0);

      const endDate = new Date(storageData?.endDate);
      const currentDate = new Date();

      const timeDifference = endDate - currentDate;
      const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

      setDaysLeft(daysLeft);
    };
    calculateDaysLeft();
  }, [storageData]);

  useEffect(() => {
    const checkStorageLimit = () => {
      if (!storageData) return;
      if (storageData?.storageUsed > storageData?.storageBought) {
        setStorageLimitError(true);
      }
    };
    checkStorageLimit();
  }, [storageData]);

  useEffect(() => {
    const setUsage = () => {
      if (!storageData) return;

      const usedTotalPercentage =
        storageData?.storageBought === 0
          ? 0
          : (storageData?.storageUsed / storageData?.storageBought) * 100;

      const remainingValue =
        storageData?.storageBought - storageData?.storageUsed;

      const usedPhotoPercentage =
        storageData?.usage?.length > 1 &&
        storageData?.usage[1]?.count !== undefined &&
        storageData?.usage[2]?.count !== undefined
          ? (parseInt(storageData?.usage[1]?.count) /
              (parseInt(storageData?.usage[1]?.count) +
                parseInt(storageData?.usage[2]?.count))) *
            100
          : 0;

      const usedVideoPercentage =
        storageData?.usage?.length > 1 &&
        storageData?.usage[1]?.count !== undefined &&
        storageData?.usage[2]?.count !== undefined
          ? (parseInt(storageData?.usage[2]?.count) /
              (parseInt(storageData?.usage[1]?.count) +
                parseInt(storageData?.usage[2]?.count))) *
            100
          : 0;

      const svgCommons = 200 / 2;

      const strokeDashoffset =
        85 * Math.PI * 2 - (85 * Math.PI * 2 * usedTotalPercentage) / 100;

      setUsageData({
        usedTotalPercentage,
        remainingValue,
        usedPhotoPercentage,
        usedVideoPercentage,
        svgCommons,
        strokeDashoffset,
      });
    };
    setUsage();
  }, [storageData]);

  useEffect(() => {
    const getDetails = async () => {
      const storedObjectString = localStorage.getItem("key");
      const storedObject = JSON.parse(storedObjectString);
      let requestBody = {
        id: storedObject?.body?.id,
      };

      const response = await getSubscriptionDetails(requestBody);

      setStorageData({
        endDate: response?.endDate,
        storageUsed: response?.storageUsed,
        storageBought: response?.storageBought,
        usage: [
          {
            count: parseInt(response?.numberOfEvents),
            image: events,
            title: "Total Events",
          },
          {
            count: parseInt(response?.numberOfPhotos),
            image: photos,
            title: "Total Photos",
          },
          {
            count: parseInt(response?.numberOfVideos),
            image: video,
            title: "Total Videos",
          },
        ],
      });
    };
    getDetails();
  }, []);

  useEffect(() => {
    const storageElement = document.getElementById("storage-page");
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top);
    } else {
      console.error("Element with ID 'storage-page' not found.");
    }
  }, []);

  return (
      <div id="storage-page" style={{ margin: "10px" }}>
        <div className="table-box" style={{ borderRadius: "20px" }}>
        {storageData && (
          <>
            {!storageLimitError ? (
              <div
                className="d-flex flex-column align-items-center storage-page-data"
                style={{
                  background: "#000",
                  height: `calc(100vh - ${fromTop}px - 10px)`,
                  borderRadius: "20px",
                }}
              >
                <p className="storage-page-title">
                  Your current plan expires in{" "}
                  <span style={{ color: "#FBCA7F" }}>{daysLeft}</span> days
                </p>

                <div className="usage-data">
                  <div id="circular-stats">
                    <svg width={"250"} height={"250"} viewBox={`0 0 200 200`}>
                      <defs>
                        <linearGradient id="GradientColor">
                          <stop offset="0%" stop-color="#8540B6" />
                          <stop offset="100%" stop-color="#FBCA7F" />
                        </linearGradient>
                      </defs>
                      <circle
                        cx={usageData?.svgCommons}
                        cy={usageData?.svgCommons}
                        strokeWidth="12px"
                        r="85"
                        className="circle-background"
                      />
                      <circle
                        cx={usageData?.svgCommons}
                        cy={usageData?.svgCommons}
                        strokeWidth="12px"
                        r="85"
                        className="circle-progress"
                        style={{
                          strokeDasharray: 85 * Math.PI * 2,
                          strokeDashoffset: usageData?.strokeDashoffset,
                        }}
                        transform={`rotate(-275 ${usageData?.svgCommons} ${usageData?.svgCommons})`}
                      />
                      <text
                        x={"50%"}
                        y={"46%"}
                        dy={"0.3rem"}
                        textAnchor="middle"
                        className="gb"
                        fill="white"
                      >
                        {(storageData?.storageUsed ?? 0).toFixed(1)} GB
                      </text>
                      <text
                        x={"50%"}
                        y={"59%"}
                        dy={"0.3rem"}
                        textAnchor="middle"
                        className="out-of"
                        fill="#e1e1e1"
                      >
                        Used out of {storageData?.storageBought} GB
                      </text>
                    </svg>
                  </div>
                  <div id="plan">
                    <p className="title">Current Storage Plan</p>
                    <p className="available">
                      <span className="gigs">
                        {String(usageData?.remainingValue?.toFixed(2))?.split(
                          "."
                        )[1] === "00"
                          ? parseInt(usageData?.remainingValue)
                          : usageData?.remainingValue?.toFixed(2)}
                        GB
                      </span>{" "}
                      Available
                    </p>
                    <div className="slab">
                      <div
                        className="out-of-used"
                        style={{
                          width: `${usageData?.usedTotalPercentage + 0.5}%`,
                        }}
                      >
                        <div
                          style={{
                            width: `${usageData?.usedPhotoPercentage}%`,
                          }}
                          className="photo-slab"
                        />
                        <div
                          style={{
                            width: `${usageData?.usedVideoPercentage}%`,
                            marginLeft:
                              usageData?.usedTotalPercentage === 2
                                ? "-16%"
                                : usageData?.usedTotalPercentage < 10
                                ? "-10%"
                                : usageData?.usedTotalPercentage < 50
                                ? "-3%"
                                : usageData?.usedTotalPercentage < 70
                                ? "-2%"
                                : usageData?.usedTotalPercentage < 80
                                ? "-1%"
                                : "-0.6%",
                          }}
                          className="video-slab"
                        />
                      </div>
                    </div>
                    <div className="stat">
                      {storageData?.usage[1]?.count > 0 && (
                        <div className="single-stat">
                          <div
                            style={{ backgroundColor: "#F7C581" }}
                            className="circle"
                          ></div>
                          <p className="stat-title">Photos</p>
                        </div>
                      )}
                      {storageData?.usage[2]?.count > 0 && (
                        <div className="single-stat">
                          <div
                            style={{ backgroundColor: "#B871FF" }}
                            className="circle"
                          ></div>
                          <p className="stat-title">Videos</p>
                        </div>
                      )}
                    </div>
                    <button
                      onClick={() => navigate("/buy-storage")}
                      className="buy-storage-btn"
                    >
                      Buy Storage
                    </button>
                  </div>
                </div>

                <div className="stats">
                  {storageData?.usage?.map((sdu, index) => (
                    <div className="single-stat" key={index}>
                      <img src={sdu?.image} alt={sdu?.title} />
                      <p className="title">{sdu?.title}</p>
                      <p className="count">
                        {sdu?.count === null ? 0 : sdu?.count}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="storage-error">
                <p>Error: Used Data is more than Available Data</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Storage;
