import React, { useState, useEffect, useRef } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./Login.scss"
import itsForever from "../../assets/Logos/ife.png"
import loginWedding from "../../assets/login-wedding.svg"
import itsForeverLogo from "../../assets/Logos/itsForeverLogo.svg"
import rings from "../../assets/rings.jpg"
import { useNavigate } from "react-router-dom"
import { loginSendOTP } from "../../api/api"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Privacy from "../Home/Privacy/Privacy"
import Loader from "../Loader/Loader"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: "90%",
  bgcolor: "#26272C",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  overflowY: "scroll",
}

const Login = ({ setIsOtp }) => {
  const navigate = useNavigate()

  const [phoneNumber, setPhoneNumber] = useState("")
  // const [consent, setConsent] = useState(false)
  const [consentError, setConsentError] = useState(false)
  const [open, setOpen] = useState(false)
  const [apiCall, setAPiCall] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const sendCode = async () => {
    setAPiCall(true)
    let phone = "+" + phoneNumber
    let reqBody = {
      phoneNumber: phone,
    }
    // if (consent === true) {
    loginSendOTP(reqBody).then((response) => {
      if (response) {
        localStorage.setItem("otp", "true")
        setIsOtp(true)
        setAPiCall(false)
        navigate("/otp", { state: { phone } })
      }
    })
    // } else {
    //   setConsentError(true)
    // }
  }

  useEffect(() => {
    const alreadyLoggedIn = () => {
      const data = JSON.parse(localStorage.getItem("key"))
      if (data?.status === "OK") navigate("/home")
    }
    alreadyLoggedIn()
  }, [])

  return (
    <div
      id="hidden"
      className="wrapper d-flex"
      style={{ position: "relative" }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Privacy />
        </Box>
      </Modal>
      <img
        src={loginWedding}
        alt="wedding"
        style={{ width: "50vw", objectFit: "cover" }}
      />
      <div className="data">
        <div className="d-flex flex-column align-items-center">
          <div style={{ width: "400px" }}>
            <img
              src={itsForeverLogo}
              alt="itsForeverLogo"
              className="logo"
              style={{ position: "absolute", top: "50px" }}
            />
            <div className="">
              <span className="text-info-white">Enter your Mobile Number</span>
            </div>
            <div className="mt-4">
              <div className="loginpage-input-box">
                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") sendCode()
                  }}
                  onChange={(e) => setPhoneNumber(e)}
                  placeholder="Enter your Mobile Number"
                />
              </div>
            </div>
            <div className="mt-4">
              <button
                type="button"
                className="btn code-button w-100"
                onClick={sendCode}
                style={{
                  background:
                    phoneNumber?.length >= 7
                      ? "linear-gradient(90deg, #F6C67C 0%, #A58447 100%)"
                      : "#26272C",
                  color: phoneNumber?.length >= 7 ? "#08090B" : "#6F6F6F",
                  borderRadius: "27.91px",
                  border: "none",
                  outline: "none",
                }}
              >
                {apiCall ? <Loader /> : "Send Code"}
              </button>
            </div>
            <div className="mt-4">
              <div>
                <p style={{ color: "#706F6C", lineHeight: "20px" }}>
                  By clicking on “Send Code”, you agree to the{" "}
                  <span
                    onClick={handleOpen}
                    className="conditions-text-gold"
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    Terms and Condition
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
