import React, { useEffect, useRef, useState } from "react"
import "./ContactUs.scss"
import nextBtn from "../../../assets/Icons/Backbtn(event).svg"
import insta from "../../../assets/New/contact/instagram.svg"
import fb from "../../../assets/New/contact/facebook.svg"
import mail from "../../../assets/New/contact/gmail.svg"
import whsup from "../../../assets/New/contact/whatsapp.svg"
import phone from "../../../assets/New/contact/phone.svg"
import CompanyPolicy from "./CompanyPolicy"
import {
  contactUs,
  getCancellationPolicy,
  getPrivacyPolicy,
  getRefundPolicy,
  getTermsAndConditions,
} from "../../../api/api"
import { Link } from "@mui/material"

const ContactUs = () => {
  const pageRef = useRef(null)
  const [rederPageHeight, setRenderPageHeight] = useState(1)
  const [showCompany, setShowCompnay] = useState(null)
  const [contactMessage, setContactMessage] = useState("")
  const [data, setData] = useState({
    name: "",
    number: "",
    email: "",
    message: "",
    city: "",
  })
  const [allowContact, setAllowContact] = useState(false)
  const [companyData, setCompanyData] = useState([
    { title: "Privacy Policy" },
    { title: "Refund Policy" },
    { title: "Cancellation Policy" },
    { title: "Terms and Condition" },
  ])

  const sendMail = async () => {
    const { name, number, email, message, city, com_name } = data

    if (
      name === "" ||
      number === "" ||
      email === "" ||
      message === "" ||
      city === ""
    )
      return

    let requestBody = {
      name,
      phoneNumber: number,
      emailid: email,
      message,
      city,
      companyName: com_name ? com_name : null,
    }

    contactUs(requestBody)
      .then((response) => {
        if (response) {
          if (response?.status === "OK") setContactMessage(response?.body)
          setData({
            name: "",
            number: "",
            email: "",
            message: "",
            city: "",
          })
        }
      })
      .catch((err) => {
        setContactMessage(err)
      })
  }

  // handle max height of page
  const updateMaxHeight_CONTACTUS = () => {
    if (pageRef.current) {
      const distanceFromTop = pageRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 10
      pageRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  useEffect(() => {
    if (contactMessage === "") return
    setTimeout(() => {
      setContactMessage("")
    }, 3500)
  }, [contactMessage])

  useEffect(() => {
    if (!data) return
    const { name, number, email, message, city } = data

    if (
      name === "" ||
      number === "" ||
      email === "" ||
      message === "" ||
      city === ""
    )
      setAllowContact(false)
    else setAllowContact(true)
  }, [data])

  useEffect(() => {
    updateMaxHeight_CONTACTUS()
    window.addEventListener("resize", updateMaxHeight_CONTACTUS)

    return () => {
      window.removeEventListener("resize", updateMaxHeight_CONTACTUS)
    }
  }, [rederPageHeight])

  useEffect(() => {
    const getPrivPol = async () => {
      try {
        const res = await getPrivacyPolicy()
        if (!res?.error) {
          setCompanyData((prev) =>
            prev?.map((p) =>
              p?.title === "Privacy Policy" ? { ...p, desc: res?.body } : p
            )
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    getPrivPol()
  }, [])

  useEffect(() => {
    const getTnC = async () => {
      try {
        const res = await getTermsAndConditions()
        if (!res?.error) {
          setCompanyData((prev) =>
            prev?.map((p) =>
              p?.title === "Terms and Condition" ? { ...p, desc: res?.body } : p
            )
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    getTnC()
  }, [])

  useEffect(() => {
    const getRefund = async () => {
      try {
        const res = await getRefundPolicy()
        if (!res?.error) {
          setCompanyData((prev) =>
            prev?.map((p) =>
              p?.title === "Refund Policy" ? { ...p, desc: res?.body } : p
            )
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    getRefund()
  }, [])

  useEffect(() => {
    const getCancellation = async () => {
      try {
        const res = await getCancellationPolicy()
        if (!res?.error) {
          setCompanyData((prev) =>
            prev?.map((p) =>
              p?.title === "Cancellation Policy" ? { ...p, desc: res?.body } : p
            )
          )
        }
      } catch (error) {
        console.log(error)
      }
    }
    getCancellation()
  }, [])

  return (
    <div>
      {showCompany !== null && (
        <div
          style={{
            margin: "10px 0 0 10px",
            color: "#e1e1e1",
            cursor: "pointer",
            fontSize: "15px",
            display: "flex",
            alignItems: "center",
            gap: "0.5px",
          }}
          onClick={() => {
            setShowCompnay(null)
            setRenderPageHeight((prev) => prev + 1)
          }}
        >
          <img src={nextBtn} alt="" style={{ height: "20px", width: "20px" }} />
          <span>Back to Contact Us</span>
        </div>
      )}
      {showCompany !== null ? (
        <CompanyPolicy
          title={companyData[showCompany]?.title}
          desc={companyData[showCompany]?.desc}
        />
      ) : (
        <div style={{ margin: "10px" }}>
          <div
            ref={pageRef}
            id="home-dashboard-contact-us"
            style={{
              background: "#000",
              borderRadius: "20px",
              overflow: "scroll",
              position: "relative",
              padding: "16px 0",
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                fontFamily: "Roboto",
                fontSize: "40px",
                fontWeight: "600",
                lineHeight: "56.25px",
                letterSpacing: "1.2px",
                color: "#E1E1E1",
              }}
            >
              Contact Us
            </p>
            <p
              style={{
                fontFamily: "Inter",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "29px",
                letterSpacing: "0.3px",
                color: "#706F6C",
                marginTop: "4px",
              }}
            >
              We love to hear from you. Our team is always here to help you.
            </p>

            <div className="contact-us-contact-box">
              <div className="entries">
                <label htmlFor="name">Name</label>
                <input
                  placeholder="John Doe"
                  id="name"
                  value={data?.name}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, name: e.target.value }))
                  }
                />
              </div>
              <div
                className="entries"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: "16px",
                }}
              >
                <div>
                  <label htmlFor="phone">Phone No.</label>
                  <input
                    placeholder="04585 57852"
                    type="number"
                    id="phone"
                    value={data?.number}
                    onChange={(e) =>
                      setData((prev) => ({ ...prev, number: e.target.value }))
                    }
                  />
                </div>
                <div>
                  <label htmlFor="city">Select City</label>
                  <input
                    style={{ width: "100%" }}
                    placeholder="DC"
                    id="city"
                    value={data?.city}
                    onChange={(e) =>
                      setData((prev) => ({ ...prev, city: e.target.value }))
                    }
                  />
                </div>
              </div>
              <div className="entries">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  placeholder="contact@itsforever.in"
                  id="email"
                  value={data?.email}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, email: e.target.value }))
                  }
                />
              </div>
              <div className="entries">
                <label htmlFor="com_name">Company Name (optional)</label>
                <input
                  placeholder="Your company name"
                  id="com_name"
                  value={data?.com_name}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, com_name: e.target.value }))
                  }
                />
              </div>
              <div className="entries">
                <label htmlFor="message">Message</label>
                <textarea
                  id="message"
                  placeholder="Write your message here"
                  rows={5}
                  style={{ resize: "none" }}
                  value={data?.message}
                  onChange={(e) =>
                    setData((prev) => ({ ...prev, message: e.target.value }))
                  }
                />
              </div>
              <button
                disabled={!allowContact}
                style={{ cursor: allowContact ? "pointer" : "not-allowed" }}
                onClick={sendMail}
                className="contact-us-submit-button"
              >
                Submit
              </button>
              {contactMessage !== "" && (
                <p style={{ marginTop: "20px", color: "green", text: "18px" }}>
                  {contactMessage}
                </p>
              )}
            </div>

            <div style={{ marginTop: "60px", width: "40vw" }}>
              <p
                style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "600",
                  lineHeight: "24.2px",
                  color: "#706F6C",
                  marginBottom: "24px",
                }}
              >
                Company
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                }}
              >
                {companyData?.map((cd, index) => (
                  <div
                    key={cd?.title}
                    onClick={() => setShowCompnay(index)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="contact-us-single-company-data"
                  >
                    <p>{cd?.title}</p>
                    <img src={nextBtn} alt="" style={{ rotate: "180deg" }} />
                  </div>
                ))}
              </div>
            </div>

            <div
              style={{
                position: "relative",
                width: "100%",
                marginTop: "60px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  background:
                    "linear-gradient(90deg, #26272C 0%, #1E1E1E 49.94%, #26272C 100%)",
                  height: "1px",
                  width: "100%",
                  position: "absolute",
                  top: "10px",
                }}
              />
              <p
                style={{
                  background: "#08090B",
                  width: "fit-content",
                  position: "relative",
                  zIndex: 5,
                  fontFamily: "Roboto",
                  fontSize: "16px",
                  fontWeight: "700",
                  lineHeight: "20px",
                  color: "#4B4A4A",
                  letterSpacing: "0.7px",
                }}
              >
                Or contact us with
              </p>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "30px",
                margin: "40px 0 30px 0",
              }}
            >
              <Link
                href="https://www.instagram.com/itsforever.in/"
                target="_blank"
              >
                <img
                  style={{ height: "39px", width: "39px" }}
                  src={insta}
                  alt="Instagram"
                />
              </Link>

              <Link
                href="https://www.facebook.com/itsforever.in"
                target="_blank"
              >
                <img
                  style={{ height: "35px", width: "35px" }}
                  src={fb}
                  alt="Facebook"
                />
              </Link>

              <Link href="mailto:itsforevertech@gmail.com">
                <img
                  style={{ height: "37px", width: "37px" }}
                  src={mail}
                  alt="Email"
                />
              </Link>

              <Link
                href="https://api.whatsapp.com/send/?phone=919945050535&text&type=phone_number&app_absent=0"
                target="_blank"
              >
                <img
                  style={{ height: "34px", width: "34px" }}
                  src={whsup}
                  alt="WhatsApp"
                />
              </Link>

              <Link href="tel:+91 99450 50535">
                <img
                  style={{ height: "39px", width: "39px" }}
                  src={phone}
                  alt="Phone"
                />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ContactUs
