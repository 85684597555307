import React, { useEffect, useRef, useState } from "react"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "./AlbumAccess.scss"
import {
  editUserRoleForEvent,
  getEventGuests,
  getUserByPhoneNumber,
  joinEvent,
  removeUserFromEvent,
} from "../../../api/api"
import Loader from "../../Loader/Loader"
import { useNavigate } from "react-router-dom"
import ShareModal from "../ShareModal/ShareModal"

const AlbumAccess = () => {
  const navigate = useNavigate()
  const pageRef = useRef(null)
  const [fromTop, setFromTop] = useState(null)
  const [activeRole, setActiveRole] = useState("photographer")
  const [inviteUser, setInviteUser] = useState("")
  const [inviteMessage, setInviteMessage] = useState("")
  const [apiCall, setApiCall] = useState(false)
  const [data, setData] = useState([])
  const [shareModalOpen, setShareModalOpen] = useState(false)

  // has members with access
  const hasMembersWithAccess = () => {
    return data?.find((user) => user?.role !== "guest") ? true : false
  }

  // has members without access
  const hasMembersWithoutAccess = () => {
    return data?.find((user) => user?.role === "guest") ? true : false
  }

  // add user
  const addUser = async () => {
    setApiCall(true)
    setInviteUser("")
    const userRes = await getUserByPhoneNumber(`+${inviteUser}`)
    if (userRes?.status === "OK") {
      const joinEventRes = await joinEvent(
        userRes?.body?.id,
        localStorage?.getItem("eventId")
      )

      if (joinEventRes?.status === "OK") {
        const roleChangeRes = await editUserRoleForEvent(
          userRes?.body?.id,
          localStorage?.getItem("eventId"),
          activeRole
        )

        if (roleChangeRes?.status === "OK") {
          populateUserData()
          setApiCall(false)
          // setInviteMessage(roleChangeRes?.body)
          setShareModalOpen(true)
        } else {
          setApiCall(false)
          setInviteMessage(roleChangeRes?.error)
        }
      } else {
        setApiCall(false)
        setInviteMessage(joinEventRes?.error)
      }
    } else {
      setApiCall(false)
      setInviteMessage(userRes?.error)
    }
  }

  // delete user
  const deleteUser = async (id) => {
    const res = await removeUserFromEvent(id, localStorage?.getItem("eventId"))

    if (res?.status === "OK") {
      populateUserData()
      setInviteMessage(res?.body)
    } else {
      setInviteMessage(res?.error)
    }
  }

  // get users Data
  const populateUserData = async () => {
    const eventId = localStorage.getItem("eventId")
    const res = await getEventGuests(eventId)

    if (res?.status === "OK") {
      const body = res?.body
      setData(body)
    }
  }

  const updateMaxHeight_ALBUMACCESS = () => {
    if (pageRef.current) {
      const distanceFromTop = pageRef.current.getBoundingClientRect().top
      const screenHeight = window.innerHeight
      const maxHeight = screenHeight - distanceFromTop - 16
      pageRef.current.style.maxHeight = `${maxHeight}px`
    }
  }

  useEffect(() => {
    updateMaxHeight_ALBUMACCESS()
    window.addEventListener("resize", updateMaxHeight_ALBUMACCESS)

    return () => {
      window.removeEventListener("resize", updateMaxHeight_ALBUMACCESS)
    }
  }, [])

  useEffect(() => {
    populateUserData()
  }, [])

  useEffect(() => {
    const storedType = JSON.parse(localStorage.getItem("eventType"))
    if (storedType === "WEBAPP_SUBSCRIPTION_EVENT") {
      navigate("/event/about")
    }
  }, [])

  // clear invite message
  useEffect(() => {
    const clearInviteMessage = () => {
      if (inviteMessage === "") return
      setTimeout(() => {
        setInviteMessage("")
      }, 3000)
    }
    clearInviteMessage()
  }, [inviteMessage])

  // handle height from top
  useEffect(() => {
    const storageElement = document.getElementById("album-access")
    if (storageElement) {
      setFromTop(storageElement.getBoundingClientRect().top)
    } else {
      console.error("Element with ID 'album-access' not found.")
    }
  }, [])

  return (
    <div
      id="album-access"
      className="table-box"
      ref={pageRef}
      style={{
        minHeight: `calc(100vh - ${fromTop}px - 10px)`,
        background: "#08090B",
        overflow: "scroll",
        position: "relative",
        margin: "10px",
      }}
    >
      <div className="rolebox">
        <p className="rolebox-title">
          Select role and give access to members to manage this event
        </p>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            marginBottom: "20px",
            marginTop: "30px",
          }}
          onClick={() => setActiveRole("photographer")}
        >
          <p className="rolebox-rolename">Photographer</p>
          <div className="selected-role-container">
            {activeRole === "photographer" && (
              <div className="active-role-indicator" />
            )}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            cursor: "pointer",
            marginBottom: "30px",
          }}
          onClick={() => setActiveRole("host")}
        >
          <p className="rolebox-rolename">Admin</p>
          <div className="selected-role-container">
            {activeRole !== "photographer" && (
              <div className="active-role-indicator" />
            )}
          </div>
        </div>
        <div className="rolebox-input-box">
          <PhoneInput
            country={"in"}
            value={inviteUser}
            onChange={(e) => setInviteUser(e)}
            placeholder="Search by Contact Number"
          />

          <button
            style={{
              padding: apiCall ? "0 70px" : "12px 40px",
              width: apiCall ? "140px" : "",
            }}
            onClick={addUser}
          >
            {apiCall ? <Loader /> : "Submit"}
          </button>
        </div>
        <p className="inviteMessage">{inviteMessage}</p>
      </div>

      <div className="roledata">
        <p className="roledata-title">{data?.length} Members</p>
        {hasMembersWithAccess() && (
          <div>
            <p className="roledata-title">Members with special access</p>
            <div className="roledata-mapper">
              {data?.map((d) => {
                return (
                  d?.role !== "guest" && (
                    <div className="single-roledata-data" key={d?.id}>
                      <div className="image-data-box">
                        <img
                          className="roledata-img"
                          src={d?.profilePhoto}
                          alt="dataprofile"
                        />
                        <div className="mid-spacer-roledata">
                          <p className="mid-spacer-title">
                            {d?.firstName} {d?.lastName}
                          </p>
                          <p className="mid-spacer-role">{d?.role}</p>
                        </div>
                      </div>
                      <button
                        onClick={() => deleteUser(d?.id)}
                        className="roledata-remove-btn"
                      >
                        Remove
                      </button>
                    </div>
                  )
                )
              })}
            </div>
          </div>
        )}
        {hasMembersWithoutAccess() && (
          <div style={{ marginTop: "65px" }}>
            <p className="roledata-title">Other guests</p>
            <div className="roledata-mapper">
              {data?.map((d) => {
                return (
                  d?.role === "guest" && (
                    <div className="single-roledata-data" key={d?.id}>
                      <div className="image-data-box">
                        <img
                          className="roledata-img"
                          src={d?.profilePhoto}
                          alt="dataprofile"
                        />
                        <div className="mid-spacer-roledata">
                          <p className="mid-spacer-title">
                            {d?.firstName} {d?.lastName}
                          </p>
                          <p className="mid-spacer-role">{d?.role}</p>
                        </div>
                      </div>
                      <button
                        onClick={() => deleteUser(d?.id)}
                        className="roledata-remove-btn"
                      >
                        Remove
                      </button>
                    </div>
                  )
                )
              })}
            </div>
          </div>
        )}
      </div>

      {shareModalOpen && (
        <div>
          <ShareModal
            modalOpen={shareModalOpen}
            handleClose={() => setShareModalOpen(false)}
            eventLink={localStorage.getItem("eventDeeplink")}
          />
        </div>
      )}
    </div>
  )
}

export default AlbumAccess
