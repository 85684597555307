import React, { useState, useEffect, useRef } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import "./Header.scss"
import backBtn from "../../assets/Icons/Backbtn(event).svg"
// import { uploadFiles } from '../../api/api';
import { apiURL } from "../../api/apiConstants"
import CircularProgress from "@mui/material/CircularProgress"
import searchBtn from "../../assets/New/SearchBtn.svg"
import qrCode from "../../assets/Gallery/Vector.svg"
import shareBtn from "../../assets/Gallery/mdi_share.svg"
import { getEventsAbout, getSubscriptionEventConfig } from "../../api/api"
import PincodeModal from "../Event/PincodeModal/PincodeModal"
import ShareModal from "../Event/ShareModal/ShareModal"
import QRCodeModal from "../Event/QRCodeModal/QRCodeModal"
import { Toaster } from "sonner"

const Header = ({
  page = "",
  userName = "",
  profilePhotoUrl = "",
  event = "",
  backFromGalleryFolder,
  isInsideGalleryFolder,
  insideGalleryFolder,
  setInsideGalleryFolder,
}) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [uploadMessage, setUploadMessage] = useState("")
  const [eventName, setEventName] = useState("")
  const fileInputRef = useRef(null)
  const [progress, setProgress] = useState(0)
  const [showProgress, setShowProgress] = useState(false)
  const [loading, setLoading] = useState(false)
  const [shareModalOpen, setShareModalOpen] = useState(false)
  const [pincodeModalOpen, setPincodeModalOpen] = useState(false)
  const [eventType, setEventType] = useState("")
  const [configSubscription, setConfigSubscription] = useState(null)
  const [qrCodeModalOpen, setQRCodeModalOpen] = useState(false)

  const uploadFiles = async (params, body) => {
    let url =
      apiURL.uploadFiles +
      "userId=" +
      params.userId +
      "&eventId=" +
      params.eventId
    const folderId = localStorage.getItem("folderId")
    if (folderId) {
      url = url + "&folderId=" + folderId
    }
    const encodedCredentials = btoa(`${apiURL.username}:${apiURL.password}`)
    setLoading(true)

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest()

      xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
          const data = JSON.parse(xhr.responseText)
          resolve(data)
        } else {
          reject(new Error("Network response was not ok"))
        }
      }

      xhr.onerror = () => {
        console.error("Error fetching data:", event)
        reject(new Error("Error fetching data"))
      }

      xhr.open("POST", url, true)
      xhr.setRequestHeader("Authorization", `Basic ${encodedCredentials}`)
      xhr.send(body)
    })
      .then((responseData) => {
        if (responseData.status === "OK") {
        }
        return responseData
      })
      .catch((error) => {
        setLoading(false)
        console.error("Error:", error)
        throw error
      })
  }

  const handleFileChange = (event) => {
    let selectedFiles = event.target.files
    let selectedFilesLength = selectedFiles.length
    let uploadCount = 0
    let uploadPercent = 0
    if (!selectedFiles) {
      setUploadMessage("Please select files to upload.")
      return
    }
    const formData = new FormData()
    const storedObjectString = localStorage.getItem("key")
    const storedObject = JSON.parse(storedObjectString)
    const storedEventId = localStorage.getItem("eventId")
    let params = {
      userId: storedObject.body.id,
      eventId: storedEventId,
    }
    for (let i = 0; i < selectedFiles.length; i++) {
      formData.delete("files")
      formData.append("files", selectedFiles[i])
      setShowProgress(true)

      uploadFiles(params, formData).then((response) => {
        if (!response.error) {
          setUploadMessage("")
          uploadCount++
          uploadPercent = Math.round((uploadCount * 100) / selectedFilesLength)
          setProgress(uploadPercent)
          if (uploadPercent === 100) {
            setTimeout(() => {
              window.location.reload()
            }, 10000)
          }
        }
      })
    }
  }

  const handleUpload = async () => {
    fileInputRef.current.click()
  }

  useEffect(() => {
    const hasEventName = () => {
      const name = localStorage.getItem("eventName")
      if (name) setEventName(name)
      else setEventName("")
    }
    hasEventName()
  }, [])

  // useeffect for setting event type
  useEffect(() => {
    const eventId = localStorage.getItem("eventId")
    if (eventId) {
      getEventsAbout(eventId).then((response) => {
        if (!response.error) {
          // setData(response.body) 
          setEventType(response?.body?.type || "")
        }
      })
      getSubscriptionEventConfig(eventId).then((res) => {
        if (res?.status === "OK") {
          setConfigSubscription(res?.body)
        }
      })
    }
  }, [])

  // helper from menu
  useEffect(() => {
    const shareHelper = () => {
      const helperType = JSON.parse(
        sessionStorage.getItem("shareThisEventForMe")
      )
      if (helperType === true) {
        setQRCodeModalOpen(false)
        setPincodeModalOpen(false)
        setShareModalOpen(true)
        sessionStorage.removeItem("shareThisEventForMe")
      }
    }

    shareHelper()
  }, [])

  // // event config
  // useEffect(() => {
  //   const fetchEventConfig = async () => {
  //     const eventId = localStorage.getItem("eventId")
  //     if (eventId) {
  //       const res = await getSubscriptionEventConfig(eventId)
  //       if (res?.status === "OK") {
  //         setConfigSubscription(res?.body)
  //       }
  //     }
  //   }
  //   fetchEventConfig()
  // }, [])

  return (
    <>
      <div
        className="header d-flex justify-content-between align-items-center p-3"
        style={{
          borderRadius: 0,
          background: "#000",
          position: "sticky",
          top: 0,
          backgroundColor: "black",
          zIndex: "1000",
        }}
      >
        <span className="page-name" style={{ display: "flex" }}>
          {eventName !== "" && page === "Gallery" && !isInsideGalleryFolder && (
            <img
              onClick={() => {
                backFromGalleryFolder(true)
                setInsideGalleryFolder("")
              }}
              src={backBtn}
              alt=""
              style={{ marginRight: "8px", cursor: "pointer" }}
            />
          )}
          {eventName !== "" && `${eventName} / `} {page}{" "}
          <span>{insideGalleryFolder && ` / ${insideGalleryFolder}`}</span>
        </span>
        {showProgress && (
          <div className="d-flex justify-content-center align-items-center">
            <div className="upload-percent-box me-3">
              {progress > 0 && (
                <span
                  className="upload-percent d-flex align-items-center justify-content-center"
                  style={{ width: `${progress}%` }}
                >
                  {progress}%
                </span>
              )}
            </div>
            {loading && <CircularProgress className="ms-3" color="inherit" />}
          </div>
        )}

        {/*{event === "" && (*/}
        {/*  <div*/}
        {/*    style={{*/}
        {/*      border: "1px solid #343a40",*/}
        {/*      borderRadius: "24px",*/}
        {/*      display: "flex",*/}
        {/*      alignItems: "center",*/}
        {/*      gap: "15px",*/}
        {/*      padding: "2px 18px",*/}
        {/*      flex: 1,*/}
        {/*      margin: "0 90px",*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    <img src={searchBtn} alt="" />*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      style={{*/}
        {/*        background: "#000",*/}
        {/*        border: "none",*/}
        {/*        width: "100%",*/}
        {/*        outline: "none",*/}
        {/*        color: "white",*/}
        {/*        padding: "8px 0 8px 0px",*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </div>*/}
        {/*)}*/}

        <div style={{ display: "flex", alignItems: "center", gap: "16px" }}>
          {/* {event === "" && location.pathname !== "/home/newref" && (
        <div>
          <button
            className="create-event-btn-fill"
            onClick={() => navigate("/home/newref")}
          >
            Create Referral Id
          </button>
        </div>
      )} */}
          {event === "" ? (
            <>
              <div>
                <button
                  onClick={() => navigate("/create-event")}
                  className="create-event-btn"
                  style={{
                    padding: "10px 22px",
                  }}
                >
                  Create Event
                </button>
              </div>
            </>
          ) : (
            <>
              <div>
                {eventType === "WEBAPP_SUBSCRIPTION_EVENT" ? (
                  <button
                    className={"create-album d-flex justify-content-center"}
                    style={{ background: "transparent" }}
                    onClick={() => {
                      setQRCodeModalOpen(false)
                      setShareModalOpen(false)
                      setPincodeModalOpen(true)
                    }}
                  >
                    Pin Code
                  </button>
                ) : (
                  <button
                    className="create-event-btn"
                    style={{ padding: "10px 22px" }}
                    onClick={() => {
                      setPincodeModalOpen(false)
                      setShareModalOpen(false)
                      setQRCodeModalOpen(true)
                    }}
                  >
                    <img src={qrCode} alt="" style={{ marginRight: "16px" }} />
                    QR Code
                  </button>
                )}
              </div>
              <div>
                <button
                  className="create-event-btn"
                  style={{ padding: "10px 35px" }}
                  onClick={() => {
                    setQRCodeModalOpen(false)
                    setPincodeModalOpen(false)
                    setShareModalOpen(true)
                  }}
                >
                  <img
                    src={shareBtn}
                    alt=""
                    style={{
                      marginRight: "16px",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                  Share
                </button>
              </div>
            </>
          )}
        </div>
      </div>
      {shareModalOpen && (
        <div>
          <ShareModal
            modalOpen={shareModalOpen}
            handleClose={() => setShareModalOpen(false)}
            eventLink={localStorage.getItem("eventDeeplink")}
            setPincodeModalOpen={setPincodeModalOpen}
            setShareModalOpen={setShareModalOpen}
            pincode={configSubscription && configSubscription?.pincode}
            eventType={eventType}
          />
        </div>
      )}
      {pincodeModalOpen && (
        <div>
          <PincodeModal
            modalOpen={pincodeModalOpen}
            handleClose={() => setPincodeModalOpen(false)}
            pincode={configSubscription && configSubscription?.pincode}
            setConfigSubscription={setConfigSubscription}
          />
        </div>
      )}
      {qrCodeModalOpen && (
        <div>
          <QRCodeModal
            modalOpen={qrCodeModalOpen}
            handleClose={() => setQRCodeModalOpen(false)}
          />
        </div>
      )}
      <Toaster />
    </>
  )
}

export default Header
