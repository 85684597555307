import React, { useState, useEffect, useRef } from 'react';
import "./CreateAlbumModal.scss";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useNavigate, useLocation } from 'react-router-dom';
import Radio from '@mui/material/Radio';
import { createFolder, editFolder, getFoldersOfEvent, updateFolderCoverPhoto } from '../../../api/api';
import { apiURL } from '../../../api/apiConstants';
import Loader from "../../Loader/Loader";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  // height: '80%',
  bgcolor: '#1A1C21',
  border: '2px solid #000',
  borderRadius: 10,
  boxShadow: 24,
  p: 4,
  color: '#fff',
  overflowY: 'scroll',
  zIndex: '45455'
};

const CreateAlbumModal = ({ modalOpen, handleClose, handleCloseOption, albumTitle, name = "", openToPublic, folderId, eventId, coverPhoto,setFolderData }) => {
  const [albumName, setAlbumName] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState('private');
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleDrop = (e) => {
    e.preventDefault();
    const droppedFile = e.dataTransfer.files[0];
    setFile(droppedFile);
  };

  const handleBrowse = (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
  };


  const handleBrowseClick = () => {
    fileInputRef.current.click();
  };

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  useEffect(() => {
    if (openToPublic === false) {
      setSelectedValue('private');
    } else {
      setSelectedValue('public')
    }
    setAlbumName(name);
  }, [name, openToPublic]);

  const CallEdit = async () => {
    let openToPublic;
    setLoading(true);

    const storedKey = localStorage.getItem('key');
    const userId = JSON.parse(storedKey).body.id;
    const eventId = localStorage.getItem("eventId");

    // Set the value of openToPublic based on selectedValue
    openToPublic = (selectedValue === 'private') ? false : true;

    // Prepare the request body for editing the folder
    let requestBody = {
      "name": albumName,
      "id": folderId,
      "userId": userId,
      "eventId": eventId,
      "openToPublic": openToPublic
    };

    try {
      // Await the editFolder function (assuming it returns a Promise)
      const res = await editFolder(requestBody);

      // If there's no error and the file exists, update the cover photo
      if (res.error == null && file != null) {
        const formData = new FormData();
        formData.append('file', file);

        // Await the updateFolderCoverPhoto function
        await updateFolderCoverPhoto(formData, folderId);
      }

      // Call the close functions after all async operations are done
      handleClose();
      handleCloseOption();

      // Reload the page after a small delay
      setTimeout(() => {
        window.location.reload(true);
      }, 4000);

    } catch (error) {
      console.error("Error occurred during the edit process:", error);
    }

    setLoading(false);
  };


  const callUpload = async () => {
    let openToPublic;
    setLoading(true);

    // Determine the value of openToPublic based on selectedValue
    openToPublic = (selectedValue === 'private') ? false : true;

    // Proceed if albumName is not empty
    if (albumName !== "") {
      let reqBody = {};
      const storedObjectString = localStorage.getItem('key');
      const storedObject = JSON.parse(storedObjectString);
      const storedEventId = localStorage.getItem('eventId');

      // Create the request body for creating the folder
      reqBody = {
        "name": albumName,
        "userId": storedObject.body.id,
        "eventId": storedEventId,
        "openToPublic": openToPublic
      };

      try {
        // Await the createFolder function (assuming it returns a Promise)
        const response = await createFolder(reqBody);

        // Check if the file and folder id exist
        if (file != null && response.body.id != null) {
          const formData = new FormData();
          formData.append('file', file);

          // Await the updateFolderCoverPhoto function
          await updateFolderCoverPhoto(formData, response.body.id);
        }

        
        const storedId = localStorage.getItem("eventId");
        getFoldersOfEvent(storedId).then((response) => {
          if (!response.error) {
            setFolderData(response.body);
          }
          setLoading(false);
        });

        // Call the close functions
        handleClose();
        handleCloseOption();

        // // Reload the page after a small delay
        // setTimeout(() => {
        //   window.location.reload(true);
        // }, 4000);


      } catch (error) {
        console.error("Error occurred during the upload process:", error);
      }
    }

    setLoading(false);
  };



  return (
    <div className="box d-flex" style={{ zIndex: '454555' }}>
      <Modal
        open={modalOpen}
        onClose={() => { handleClose(); handleCloseOption(); setFile(null); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ zIndex: '454555' }}
      >
        <Box sx={style}>
          <div className='d-flex justify-content-center'>
            <span className='title'>{albumTitle}&nbsp;Album</span>
          </div>
          <div className='d-flex flex-column align-items-start'>
            <span className='labels mt-4'>Name of the Album</span>
            <input
              className="mt-3 input-text"
              type="text"
              value={albumName}
              onChange={(event) => setAlbumName(event.target.value)}
            />

            <span className='labels mt-4'>Set Visibility to:</span>

            <div className='d-flex justify-content-between mt-3 w-100'>
              <div className='d-flex flex-column'>
                <span className='visibility-text'>Private</span>
                <span className='visibility-text-dec'>Only host and photographer have access to this album</span>
              </div>
              <Radio
                checked={selectedValue === 'private'}
                onChange={handleRadioChange}
                value="private"
                name="radio-buttons"
                sx={{
                  color: '#FBCA7F',
                  '&.Mui-checked': {
                    color: '#FBCA7F',
                  },
                }}
              />
            </div>

            <div className='d-flex justify-content-between mt-3 w-100'>
              <div className='d-flex flex-column'>
                <span className='visibility-text'>Public</span>
                <span className='visibility-text-dec'>Everyone can see this album</span>
              </div>
              <Radio
                checked={selectedValue === 'public'}
                onChange={handleRadioChange}
                value="public"
                name="radio-buttons"
                sx={{
                  color: '#FBCA7F',
                  '&.Mui-checked': {
                    color: '#FBCA7F',
                  },
                }}
              />
            </div>

            <div className='mt-4 w-100'>
              <span className='labels' >Upload Cover Image</span>
              <div className='mt-3'>
                <span className='drop-text'>Drag & Drop File Here or&nbsp;</span>
                <input
                  type="file"
                  accept='image/*'
                  onChange={handleBrowse}
                  style={{ display: 'none' }}
                  ref={fileInputRef}
                />
                <span className='browse-button' onClick={handleBrowseClick}>
                  Browse
                </span>
                <div
                  className="d-flex align-items-center justify-content-center drop-area"
                  onDragOver={(e) => e.preventDefault()}
                  onDrop={handleDrop}
                >
                  {
                    file == null && albumTitle === 'Create' ? (
                      <>
                        <span className='drop-text'>Drag & Drop File Here</span>
                      </>
                    ) : (
                      <img src={
                        albumTitle === 'Edit' && file != null ? URL.createObjectURL(file)
                          : albumTitle === 'Edit' && file == null ? coverPhoto
                            : albumTitle === 'Create' && file != null ? URL.createObjectURL(file)
                              : null}
                        alt="Selected File" className='cover-img' />
                    )
                  }
                </div>
              </div>
            </div>

            <div className='mt-4 w-100 d-flex justify-content-around'>
              <div type="button" className={'cancel-upload me-3 d-flex justify-content-center align-items-center'}
                onClick={() => {
                  handleClose();
                  handleCloseOption();
                  setFile(null);
                }}>
                Cancel
              </div>
              <div type="button" className={'create-alb me-3 d-flex justify-content-center align-items-center'}
                onClick={() => {
                  if (albumTitle === 'Create') {
                    callUpload();
                  } else {
                    CallEdit();
                  }
                }}>
                {loading ? <Loader /> : albumTitle}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </div >
  );
}

export default CreateAlbumModal;